import dayjs from 'dayjs'

import { decimalNumber } from '../../../../../../helpers/mask.helper'
import { TabRow, TextRow, TextValueRow, TitleRow } from '../../../../../UI/Box/styles'

export function ShipmentNF ({ nfs }) {
  return (
    <>
      <TabRow $columns={'1fr'} $padding={'20px 0px 0px 20px'}>
        <TitleRow>Notas Fiscais ({nfs?.length})</TitleRow>
      </TabRow>

      {nfs.map((nf) => {
        return (
          <TabRow key={nf.id} $columns={'1fr 1fr 1fr 1fr 1fr 1fr'}>
            <div>
              <TextRow>Número</TextRow>
              <TextValueRow>{nf?.serie != null ? nf.serie : '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Série</TextRow>
              <TextValueRow>{nf?.serie != null ? nf.serie : '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Data Emissão</TextRow>
              <TextValueRow>{nf?.issueDate
                ? dayjs(nf?.issueDate).format('DD/MM/YYYY HH:mm')
                : '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Valor</TextRow>
              <TextValueRow>{nf?.value ? `R$ ${decimalNumber(nf?.value)}` : '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Peso (kg)</TextRow>
              <TextValueRow>{decimalNumber(nf?.weight)}</TextValueRow>
            </div>
          </TabRow>
        )
      })}

    </>
  )
}