import dayjs from 'dayjs'
import { useContext } from 'react'
import { CiFilter } from 'react-icons/ci'

import { StatusFilter } from './components/StatusFilter'
import { Box } from '../../../../components/UI/Box'
import { BtnCustom } from '../../../../components/UI/Btn'
import { ContentInputs } from '../../../../components/UI/ContentInputs'
import { InputText } from '../../../../components/UI/Inputs/InputText'
import { FilterContext } from '../../../../providers/Filter'
import { Col, Row } from '../../../../styled-components/style'

export function Filters () {
  const { filters, setFilters } = useContext(FilterContext)

  function onChangeFilter (key) {
    return (event) => {
      setFilters(state => {
        let aux = { ...state }

        if (!aux?.filter) {
          aux.filter = {}
        }

        if (Array.isArray(aux?.filter[key])) {
          const exists = aux.filter[key].includes(event.target.value)

          if (!exists)
            aux.filter[key].push(event.target.value)
          else
            aux.filter[key] = aux.filter[key].filter(e => e !== event.target.value)
        } else {

          if (['createdAtStart', 'createdAtEnd'].includes(key)) {
            if (key === 'createdAtStart')
              aux.filter[key] = dayjs(event.target.value).startOf('day').toISOString()

            if (key === 'createdAtEnd')
              aux.filter[key] = dayjs(event.target.value).endOf('day').toISOString()
          } else {
            aux.filter[key] = event.target.value
          }
        }

        return aux
      })
    }
  }


  return (
    <div style={{ paddingBottom: '2rem' }}>
      <Col>
        <Row>
          {Object.keys(filters).length > 0 && (
            <BtnCustom
              text={'Limpar filtros'}
              color={'#1976D2'}
              icon={<CiFilter />}
              backgroundColor={'transparent'}
              hoverColor={'rgba(96, 103, 113, 0.04)'}
              align={'start'}
              shadowNone
              click={() => setFilters({})}
              width={'130px'}
            />
          )}
        </Row>
      </Col>
      <Col $gap={'0.875rem'}>
        <Row $gap={'0.5rem'}>
          <Box title={'Inicio'} padding='10px'>
            <ContentInputs>
              <InputText
                type="date"
                value={filters?.filter?.createdAtStart ? dayjs(filters?.filter?.createdAtStart).format('YYYY-MM-DD') : ''}
                onChange={onChangeFilter('createdAtStart')}
              />
            </ContentInputs>
          </Box>

          <Box title={'Fim'} padding='10px'>
            <ContentInputs>
              <InputText
                type="date"
                value={filters?.filter?.createdAtEnd ? dayjs(filters?.filter?.createdAtEnd).format('YYYY-MM-DD') : ''}
                onChange={onChangeFilter('createdAtEnd')}
              />
            </ContentInputs>
          </Box>
        </Row>

        <Row>
          <Box title={'Código'}>
            <InputText
              type="text"
              placeholder="Código do Envio"
              value={filters?.filter?.shipmentCode || ''}
              onChange={onChangeFilter('shipmentCode')}
            />
          </Box>
        </Row>

        <Row>
          <Box title={'Documento'} description={'Nota Fiscal ou CTE'}>
            <InputText
              type="text"
              placeholder="Numero ou Chave do Documento"
              value={filters?.filter?.documentIdentifier || ''}
              onChange={onChangeFilter('documentIdentifier')}
            />
          </Box>
        </Row>

        <Row>
          <Col style={{ width: '100%' }}>
            <Box title={'Situação'}>
              <ContentInputs>
                <StatusFilter />
              </ContentInputs>
            </Box>
          </Col>
        </Row>

        <Row>
          <Box title={'Origem'}>
            <InputText
              type="text"
              placeholder="Cidade de origem"
              value={filters?.filter?.originEntityLocation || ''}
              onChange={onChangeFilter('originEntityLocation')}
            />
          </Box>
        </Row>

        <Row>
          <Box title={'Destino'}>
            <InputText
              type="text"
              placeholder="Cidade de destino"
              value={filters?.filter?.destinationEntityLocation || ''}
              onChange={onChangeFilter('destinationEntityLocation')}
            />
          </Box>
        </Row>

        <Row>
          <Col style={{ width: '100%' }}>
            <Box
              title={'Remetente'}
              gap={'1.5rem'}
              enableInputSearch
            >
              <InputText
                type="text"
                placeholder="Remetente"
                value={filters?.filter?.senderName || ''}
                onChange={onChangeFilter('senderName')}
              />
            </Box>
          </Col>
        </Row>

        <Row>
          <Col style={{ width: '100%' }}>
            <Box
              title={'Destinatário'}
              gap={'1.5rem'}
              enableInputSearch
            >

              <InputText
                type="text"
                placeholder="Destinatário"
                value={filters?.filter?.recipientName || ''}
                onChange={onChangeFilter('recipientName')}
              />
              
            </Box>
          </Col>
        </Row>
      </Col>
    </div>
  )
}
