 
import { ArrowButton, Dots, PageNumber, PaginationContainer } from './styled'

export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const maxPagesToShow = 5

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page)
    }
  }

  const renderPageNumbers = () => {
    let pages = []
    const startPage = Math.max(1, currentPage - 2)
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1)

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PageNumber key={i} $active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </PageNumber>
      )
    }

    return pages
  }

  return (
    <PaginationContainer>
      <ArrowButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
        {'<'}
      </ArrowButton>
      {renderPageNumbers()}
      {currentPage < totalPages - maxPagesToShow && <Dots>...</Dots>}
      <PageNumber $active={false} onClick={() => handlePageChange(totalPages)}>
        {totalPages}
      </PageNumber>
      <ArrowButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
        {'>'}
      </ArrowButton>
    </PaginationContainer>
  )
}

