import { useEffect } from 'react'

import { AppRoutes } from './routes'
import { deleteLocalStorage } from './Utils'

function App () {
  useEffect(() => {
    const handleUnload = () => {
      console.log('Página está sendo recarregada ou fechada')
      localStorage.removeItem('apollo-cache-persist')
    }

    window.addEventListener('unload', handleUnload)

    return () => {
      window.removeEventListener('unload', handleUnload)
    }
  }, [])


  useEffect(() => {
    const token = localStorage.getItem('EBB_DASHBOARD_USER_SAURON')
    if (!token && window.location.pathname !== '/') {
      deleteLocalStorage()
      window.location.href = '/'
    }
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      <AppRoutes />
    </div>
  )
}

export default App
