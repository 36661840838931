import './style.css'
import { useContext, useEffect, useState } from 'react'
import { SiMicrosoftexcel } from 'react-icons/si'
import { useNavigate } from 'react-router-dom'

import { ExportExcel } from './components/ExportExcel'
import { Filters } from './components/Filters'
import { SearchShipments } from './components/SearchShipments'
import { useShipments } from '../../hooks/Shipment'
import { AppContext } from '../../providers/AppProvider'
import { ContainerPage } from '../../styled-components/style'

export function HomePage () {
  const navigate = useNavigate()

  const { enabledButtonNewShipment } = useContext(AppContext)

  const { queryListShipments: { data: listShipments } } = useShipments()

  const [modalExportExecel, setModalExportExecel] = useState(false)

  useEffect(() => {
    document.title = 'Dashboard | Envios'
  }, [])


  return (
    <>
      <ExportExcel
        open={modalExportExecel}
        onClose={(e) => {
          e.stopPropagation()
          setModalExportExecel(false)
        }}
      />

      <ContainerPage>
        <div
          className="container"
          style={{
            margin: 'auto',
          }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="color-primary" style={{ fontSize: 34 }}>
                Envios
              </h4>
              <p className="quantity-registers">
                {listShipments?.shipments?.total ?? 0} Resultados
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <button className="btn-export-excel" onClick={() => setModalExportExecel(true)}>
                  <SiMicrosoftexcel style={{ width: 20, height: 20 }} />{' '}
                  EXPORTAR EXCEL
                </button>
              </div>
              {enabledButtonNewShipment && (
                <div>
                  <button
                    className="btn-new"
                    onClick={() => navigate('/shipments/new')}
                  >
                    + NOVO ENVIO
                  </button>
                </div>
              )}
            </div>
          </div>

          <section style={{ width: '100%', display: 'flex', gap: '32px' }}>
            <Filters textLabel="Data" />

            <div style={{ flexGrow: '0', maxWidth: '85%', flexBasis: '85%', }}>
              <SearchShipments />
            </div>
          </section>
        </div>
      </ContainerPage>
    </>
  )
}
