
import { Box } from '../../../../components/UI/Box'
import { ContentInputs } from '../../../../components/UI/ContentInputs'
import { InputText } from '../../../../components/UI/Inputs/InputText'
import { usePickup } from '../../../../hooks/Pickup'
import { Col, Row } from '../../../../styled-components/style'

export function FiltersPickup () {
  const { filters, setFilters } = usePickup()

  return (
    <div style={{ paddingBottom: '2rem' }}>
      <Col>
        <Row>
          {/* {Object.keys(filters).length > 0 && (
            <BtnCustom
              text={'Limpar filtros'}
              color={'#1976D2'}
              icon={<CiFilter />}
              backgroundColor={'transparent'}
              hoverColor={'rgba(96, 103, 113, 0.04)'}
              align={'start'}
              shadowNone
              click={() => {
                setFilters({})
              }}
              width={'130px'}
            />
          )} */}
        </Row>
      </Col>
      <Col $gap={'0.875rem'}>
        <Row $gap={'0.5rem'}>
          <Box title={'Inicio'} padding='10px'>
            <ContentInputs>
              <InputText
                type="date"
                value={filters.createdAtGreaterOrEqualThan}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    filter: {
                      ...filters.filter,
                      createdAtGreaterOrEqualThan: e.target.value || null
                    }
                  })
                }}
              />
            </ContentInputs>
          </Box>

          <Box title={'Fim'} padding='10px'>
            <ContentInputs>
              <InputText
                type="date"
                value={filters.createdAtLessOrEqualThan}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    filter: {
                      ...filters.filter,
                      createdAtLessOrEqualThan: e.target.value || null
                    }
                  })
                }}
              />
            </ContentInputs>
          </Box>
        </Row>
      </Col>
    </div>
  )
}
