import { v4 } from 'uuid'

import { EBB_CARRIER_PARENT_ENTITY_ID } from '../../../env'

export const INITIAL_STATE_SHIPMENT = {
  carrierParentEntityId: EBB_CARRIER_PARENT_ENTITY_ID,
  consigneeId: null,
  declaredValue: 0,
  deliveryModality: 'counter',
  destinationShipperEntityId: null,
  dispatcherId: null,
  dispatchType: 'intermediateRedispatch', // TODO - verificar esse campo
  fiscalDocuments: [],
  originShipperEntityId: null,
  previousDocuments: [],
  receiptModality: 'counter',
  receiverId: null,
  recipientId: null,
  senderId: null,
  shipperCode: '',
  status: 'ready',
  totalWeight: 0,
  volumes: [
    {
      id: v4(),
      quantity: 1,
      length: '',
      width: '',
      height: '',
    }
  ]
}