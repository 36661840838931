import { FaMinusCircle } from 'react-icons/fa'
import styled from 'styled-components'

export const ContainerPickUp = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 6rem;

  .row-container-volumes {
    align-items: flex-start;
  }

  @media screen and (max-width: 800px){
    padding: 0 0.875rem 6rem 0.875rem;
  }

  @media screen and (max-width: 600px){
    .row-content-volumes {
      flex-wrap: wrap!important;
    } 
  }
`

export const ContentIconMinusPickUp = styled.div`
  padding-top: 18px;
`

export const IconMinusPickUp = styled(FaMinusCircle)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  transition: ease 0.1s;
  background-color: white;

  &:hover {
    color: red;
    filter: drop-shadow(0 0 4px #ff00008c);
    transition: ease 0.1s;
    border-radius: 50%;
  }
`

export const HeaderPickUp = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  width: 100%;
  max-width: 50rem;
`

export const TitlePickUp = styled.h6`
  font-size: ${props => props.$fontSize || '1.25rem'};
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bolder;
`

export const ContentPickUp = styled.section`
   display: flex;
   width: 800px;
   flex-direction: column;
   gap: 22px;
`

export const FooterPickUp = styled.div`
  width: 100%;
  position: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 0;
  left: 0;
  border-top: 1px solid #C2C3C9;
  background-color: #f2f2f4;
`

export const FooterItemPickUp = styled.div`
  width: 50rem;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: flex-end;

  @media screen and (max-width: 800px) {
    padding-right: 0.875rem;
  }
`