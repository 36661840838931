import { useLazyQuery } from '@apollo/client'

import { LIST_SHIPPING_EVENTS } from '../graphql/ShippingEvents'

export function useShippingEvents () {
  const queryListShippingEvents = useLazyQuery(LIST_SHIPPING_EVENTS)

  return {
    queryListShippingEvents
  }
}
