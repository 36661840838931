import { useEffect } from 'react'

import { ShipmentCTE } from './components/ShipmentCTE'
import { ShipmentNF } from './components/ShipmentNF'
import { ShipmentPreviousDocuments } from './components/ShipmentPreviousDocuments'
import { useShipments } from '../../../../../hooks/Shipment'
import { TabContent } from '../../../../UI/Box/styles'
import { Spinner } from '../../../../UI/Spiner'
import { NotFound } from '../../../styles'

export function TabDocuments ({ id, isVisible }) {
  const { queryDocumentsShipment: [getDocuments, { data, loading }] } = useShipments()

  useEffect(() => {
    if (isVisible && id) {
      getDocuments({ variables: { id } })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isVisible])

  const shipment = data?.shipment

  return (
    <>
      <Spinner loading={loading} width={'100%'} height={'calc(400px - 50px)'} />

      <TabContent $isVisible={isVisible} style={{ fontSize: 12 }}>
        {shipment?.cte ? (
          <ShipmentCTE cte={shipment.cte} />
        ) : <></>}

        {shipment?.fiscalDocuments?.length ? (
          <ShipmentNF nfs={shipment.fiscalDocuments} />
        ) : <></>}

        {shipment?.previousDocuments?.length > 0 ? (
          <ShipmentPreviousDocuments
            previousDocuments={shipment.previousDocuments}
          />
        ) : <></>}


        {(!shipment?.cte && !shipment?.fiscalDocuments?.length && !shipment?.previousDocuments?.length) && (
          <NotFound>
            Nenhum documento adicionado no envio
          </NotFound>
        )}
      </TabContent>
    </>
  )
}
