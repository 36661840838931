import { useState } from 'react'

import { Modal } from '../../../../../../components/Modal'
import { downloadMinuta } from '../../../../../../services/ebbBrudamSyncerService'

export function PrintMinuta ({
  open,
  onClose,
  id,
}) {
  const [isSending, setIsSending] = useState(false)

  return (
    <Modal
      open={open}
      onClose={onClose}
      loading={isSending}
      title={'Deseja fazer download da minuta?'}
      textClose='CANCELAR'
      textConfirm='DOWNLOAD'
      height={150}
      onConfirm={async (e) => {
        try {
          e.stopPropagation()
          setIsSending(true)
          await downloadMinuta(id)
          document.body.style.overflow = 'auto'
          setIsSending(false)
          onClose(e)
        } catch {
          setIsSending(false)
          onClose(e)
        }
      }}
    >

      <p style={{ color: '#666', fontSize: '1rem' }}>
        {!isSending && `Para baixar a minuta "${id}" clique no botão de Download.`}
        {isSending && 'Aguarde um momento, o download está sendo efetuado.'}
      </p>
    </Modal>
  )
}