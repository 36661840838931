import { PersonType } from '../../../../../enums/person-type.enum'
import { maskStringByPattern } from '../../../../../helpers/mask.helper'
import { TabRow, TextRow, TextValueRow, TitleRow } from '../../../../UI/Box/styles'

export function EntityDetailsPickup ({ entity, title }) {
  return (
    <>
      <TabRow $columns={'1fr'} $padding={'20px 0px 0px 20px'}>
        <TitleRow>{title}</TitleRow>
      </TabRow>
      <TabRow $columns={'1fr 1fr'}>
        <div>
          <TextRow>Nome</TextRow>
          <TextValueRow>{entity?.displayName || entity?.name}</TextValueRow>
        </div>
        <div>
          <TextRow>{entity?.type === PersonType.Individual ? 'CPF' : 'CNPJ'}</TextRow>
          <TextValueRow>{entity?.documentNumber
            ? maskStringByPattern(
              entity?.documentNumber,
              entity?.type === PersonType.Individual
                ? '###.###.###-##'
                : '##.###.###/####-##'
            )
            : '---'}</TextValueRow>
        </div>
      </TabRow>
      {entity?.type === PersonType.Corporation && (
        <TabRow>
          <div>
            <TextRow>Inscrição Estadual</TextRow>
            <TextValueRow>{entity?.stateRegistration || '---'}</TextValueRow>
          </div>
        </TabRow>
      )}
      <TabRow $columns={'1fr'} $padding={'20px 0px 0px 20px'}>
        <div>
          <TextRow>Endereço</TextRow>
          <TextValueRow> {entity?.address
            ? `${entity?.address?.street} ${entity?.address?.number}${entity?.address?.complement
              ? ` ${entity?.address?.complement}`
              : ''
            }, ${entity?.address?.neighborhood}, ${entity?.address?.location
            } - ${entity?.address?.state} - ${maskStringByPattern(
              entity?.address?.cep || '',
              '#####-###'
            )} `
            : '---'}</TextValueRow>
        </div>
      </TabRow>
      <TabRow>
        {entity?.code && (
          <div>
            <TextRow>Código do cliente</TextRow>
            <TextValueRow>{entity?.code}</TextValueRow>
          </div>
        )}

        {entity?.phone && (
          <div>
            <TextRow>Telefone</TextRow>
            <TextValueRow>{entity?.phone}</TextValueRow>
          </div>
        )}

        {entity?.email && (
          <div>
            <TextRow>Email</TextRow>
            <TextValueRow>{entity?.email}</TextValueRow>
          </div>
        )}
      </TabRow>
    </>
  )
}