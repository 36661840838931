import { useContext, useState } from 'react'
import { IoIosPaper } from 'react-icons/io'

import { ContainerOption } from './style'
import { OrganizationType } from '../../../../../../enums/organization-type.enum'
import { AppContext } from '../../../../../../providers/AppProvider'
import { PrintEtiqueta } from '../PrintEtiqueta'
import { PrintMinuta } from '../PrintMinuta'
import { RecendShipment } from '../Recend'

export function ItemOptions ({ shipment, open, clear, shipmentCarrierCode }) {
  const { organizationType } = useContext(AppContext)
  
  const [optionsOpen, setOptionsOpen] = useState(open)
  const [modalPrintMinuta, setModalPrintMinuta] = useState(false)
  const [modalPrintEtiqueta, setModalPrintEtiqueta] = useState(false)
  const [modalRecendShipment, setModalRecendShipment] = useState(false)

  return (
    <>
      <div style={{
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 999,
        position: 'fixed',
        cursor: 'default',
      }} onClick={(e) => {
        e.stopPropagation()

        if (optionsOpen !== 'none') {
          setOptionsOpen('none')
          clear()
        }
      }}>
      </div>

      {modalPrintMinuta && (
        <PrintMinuta
          open={modalPrintMinuta}
          id={shipmentCarrierCode}
          onClose={(e) => {
            e.stopPropagation()
            setModalPrintMinuta(false)
          }}
        />
      )}

      {modalPrintEtiqueta && (
        <PrintEtiqueta
          open={modalPrintEtiqueta}
          id={shipmentCarrierCode}
          onClose={(e) => {
            e.stopPropagation()
            setModalPrintEtiqueta(false)
          }}
        />
      )}

      {modalRecendShipment && (
        <RecendShipment
          open={modalRecendShipment}
          id={shipmentCarrierCode}
          onClose={(e) => {
            e.stopPropagation()
            setModalRecendShipment(false)
          }}
        />
      )}

      <ContainerOption $display={optionsOpen} onClick={(e) => e.stopPropagation()}>
        <ul>
          {shipment.syncedAt && (
            <>
              <li onClick={(e) => {
                e.stopPropagation()
                setModalPrintEtiqueta(true)
              }}>
                <IoIosPaper /> IMPRIMIR ETIQUETAS
              </li>
              <li onClick={(e) => {
                e.stopPropagation()
                setModalPrintMinuta(true)
              }}>
                <IoIosPaper /> IMPRIMIR MINUTA
              </li>
            </>
          )}

          {(!shipment.syncedAt && organizationType === OrganizationType.Admin) && (
            <li onClick={(e) => {
              e.stopPropagation()
              setModalRecendShipment(true)
            }}>
              <IoIosPaper /> FORÇAR REENVIO
            </li>
          )}

        </ul>
      </ContainerOption>
    </>
  )
}