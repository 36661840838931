import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { TimeContent, Time, TimelineContainer, TimelineItem, TimeContentResponsible, TimeComments } from './style'
import { findShipmentByPickup } from '../../../../../services/shippingEvents'
import GeocodingButton from '../../../../GeocodingButton'
import { TabContent, TabRow } from '../../../../UI/Box/styles'
import { Spinner } from '../../../../UI/Spiner'
import { NotFoundOccurencesPickup } from '../../../styles'


export function TabTimelinePickup ({ isVisible, shpperCode }) {
  const [shippingEvents, setShippingEvents] = useState([])
  const [loading, setLoading] = useState(true)

  async function searchShipmentByPickup (code) {
    try {
      const result = await findShipmentByPickup(code)

      setShippingEvents(result)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isVisible && shpperCode)
      searchShipmentByPickup(shpperCode)
  }, [shpperCode, isVisible])

// só colocar o loading pra funcionar

  const shipmentShippingEvents = _(shippingEvents?.shipmentShippingEvents || [])
    .uniqWith((arrVal, othVal) =>
      arrVal.description === othVal.description && arrVal.registeredAt === othVal.registeredAt
    )
    .value()?.map((shippingEvent) => {
      return {
        time: dayjs(shippingEvent.registeredAt).format('DD/MM HH:mm'),
        title: shippingEvent.description,
        accountablePerson: shippingEvent?.accountablePerson?.name,
        accountablePersonDocument: shippingEvent?.accountablePerson?.documentNumber,
        comments: shippingEvent.comments,
        geocoding: shippingEvent.geocoding
      }
    })

  if (shipmentShippingEvents?.length) {
    shipmentShippingEvents.push({
      time: 'Inicio',
      title: ''
    })
  }

  return (
    <>
      {(loading && !shipmentShippingEvents) && <Spinner loading={loading} width={'100%'} height={'calc(400px - 50px)'} />}

      {(!loading || shipmentShippingEvents) && (
        <TabContent $isVisible={isVisible} >
          <TabRow $columns="1fr" $padding="20px">
            <TimelineContainer>
              {shipmentShippingEvents?.map((shippingEvent, index) => (
                <TimelineItem key={index}>
                  <Time>{shippingEvent.time}</Time>
                  <TimeContent>
                    <h4>{shippingEvent.title}</h4>
                    {shippingEvent.accountablePerson && (
                      <>
                        <TimeContentResponsible>
                          <div>
                            <strong>Reponsável: </strong>
                            <p>{shippingEvent.accountablePerson}</p>
                          </div>
                          <div>
                            <strong>Documento: </strong>
                            <p>{shippingEvent.accountablePersonDocument}</p>
                          </div>
                        </TimeContentResponsible>
                      </>
                    )}

                    {shippingEvent.geocoding && (
                      <GeocodingButton geocoding={shippingEvent.geocoding} shouldRedirect={true} />
                    )}

                    {shippingEvent?.comments && (
                      <>
                        <TimeComments $padding={'10px 0px 0px 0px'}><strong>Ressalva:</strong> {shippingEvent?.comments}</TimeComments>
                      </>
                    )}
                  </TimeContent>
                </TimelineItem>
              ))}
            </TimelineContainer>
          </TabRow>

          {(!shipmentShippingEvents?.length) && (
            <NotFoundOccurencesPickup>
              Nenhuma ocorrência adicionada no envio
            </NotFoundOccurencesPickup>
          )}
        </TabContent>
      )}

    </>

  )
}

