import { useContext, useState } from 'react'
import { TbAlertTriangle } from 'react-icons/tb'

import { Modal } from '../../../../components/Modal'
import { FilterContext } from '../../../../providers/Filter'
import { donwloadExcel } from '../../../../services/shipmentService'

export function ExportExcel ({
  open,
  onClose,
}) {
  const { filters } = useContext(FilterContext)

  const [downloading, setDownloading] = useState(false)

  return (
    <Modal
      open={open}
      onClose={(e) => {
        if (!downloading)
          onClose(e)
      }}
      title={'Download de envios e volumes'}
      textClose='CANCELAR'
      textConfirm='DOWNLOAD'
      height={150}
      loading={downloading}
      onConfirm={async (e) => {
        e.stopPropagation()
        setDownloading(true)
        await donwloadExcel(filters.filter)
        setDownloading(false)
      }}
    >
      <div style={{ backgroundColor: '#fff4e5', color: '#663c00', padding: 20, borderRadius: 5 }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'flex-start' }}>
          <TbAlertTriangle color='#ffa116' size={20} />
          <strong>Atenção</strong>
        </div>
        <p style={{ marginLeft: 28 }}>
          Somente os <strong>100 primeiros</strong> envios serão exportados.
        </p>
      </div>
    </Modal>
  )
}