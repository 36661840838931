import styled from 'styled-components'

export const Label = styled.label`
  width: 100%;
  display: flex;
  gap: 0.875rem;
  align-items: center;
  color: #606771;
  cursor: pointer;
  height: 42px!important;
`

export const CustomInput = styled.input`
  height: 20px;
  width: 20px;
  padding: 0 0.2rem  0 0;
  color: rgb(216 216 216);
  cursor: pointer;

  /* &:hover {
    height: 40px;
    width: 40px;
  } */
  
  &:focus {
    outline: none!important;
  }

  &::-webkit-input-placeholder { 
    color: rgb(216 216 216);
  }

  &:-moz-placeholder { 
    color: rgb(216 216 216);
    opacity:  1;
  }

  &::-moz-placeholder { 
     color: rgb(216 216 216);
     opacity:  1;
  }

  &:-ms-input-placeholder { 
     color: rgb(216 216 216);
  }

  &::-ms-input-placeholder {
     color: rgb(216 216 216);
  }

  &::placeholder {
     color: rgb(216 216 216);
  }
`