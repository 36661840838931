import { useQuery } from '@apollo/client'

import { GET_PACKAGE, LIST_PACKAGES } from '../graphql/packages'

export function usePackage () {
  const listPackages = useQuery(LIST_PACKAGES, {
    fetchPolicy: 'cache-and-network'
  })

  const getPackage = useQuery(GET_PACKAGE, {
    skip: true,
  })

  return {
    listPackages,
    getPackage
  }
}

