import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import {
  BsBoxSeamFill,
  BsFillPersonPlusFill,
} from 'react-icons/bs'
import { FaLocationDot, FaPlus } from 'react-icons/fa6'
import { IoMdArrowBack } from 'react-icons/io'
import { PiNavigationArrowFill, PiNoteFill } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

import { UploadFileCTe } from './components/UploadFileCTe'
import { UploadFileNotaAndDeclaration } from './components/UploadFileNotaAndDeclaration'
import { ListVolumes } from './components/Volumes'
import { useNewShipment } from './hooks'
import {
  Container,
  Content,
  Header,
  Title,
  Footer,
  FooterItem,
} from './styles'
import { FormEntity } from '../../components/Form/Entity'
import { ModalError } from '../../components/ModalError'
import { Box } from '../../components/UI/Box'
import { ContentBox } from '../../components/UI/Box/styles'
import { BtnCustom } from '../../components/UI/Btn'
import { InputText } from '../../components/UI/Inputs/InputText'
import { OffCanvas } from '../../components/UI/OffCanvas'
import { useOffCanvas } from '../../components/UI/OffCanvas/hooks'
import { SelectBox } from '../../components/UI/SelectBox'
import { Spinner } from '../../components/UI/Spiner'
import { useEntites } from '../../hooks/Entities'
import { AppContext } from '../../providers/AppProvider'
import { OffCanvasEntityContext } from '../../providers/OffCanvasProvider'
import { Row } from '../../styled-components/style'
import { mascaraMoeda, maskCurrency } from '../../Utils'

export function NewShipmentPage () {
  const {
    allowNfeAndContentStatement,
    isCarryingCompany,
    organizationId,
    requiredShipperCode,
  } = useContext(AppContext)
  const { createEntity, setErros, formData } = useContext(OffCanvasEntityContext)

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [messageError, setMessage] = useState(null)

  const { shipment, setShipment, addMoreVolumes, saveNewShipment } = useNewShipment()

  const { isOffCanvasOpen, closeOffCanvas, toggleOffCanvas } = useOffCanvas()

  const {
    queryListEntitesAndAssociations: [getListEntitesAndAssociations, { data }],
  } = useEntites()

  useEffect(() => {
    if (organizationId)
      getListEntitesAndAssociations({
        variables: {
          filter: {
            disposable: false,
            organizationIds: [organizationId],
          },
        },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  const entities = data?.entities?.data

  useEffect(() => {
    document.title = 'Dashboard | Novo Envio'
  }, [])

  return (
    <>
      <ModalError
        message={messageError}
        open={!!messageError}
        title={'Validação'}
        onClose={() => {
          setMessage(null)
        }}
      />
      <Container>
        <Header>
          <div style={{ width: '64px' }} onClick={() => navigate('/shipments')}>
            <BtnCustom
              text={<IoMdArrowBack size={35} color="#1976D2" />}
              backgroundColor={'transparent'}
              color={'#606771'}
              hoverColor={'rgba(96, 103, 113, 0.04)'}
              shadowNone
            />
          </div>

          <h2 style={{ fontSize: '25px', fontWeight: '700', color: '#363487' }}>
            Novo Envio
          </h2>
        </Header>

        <Content>
          <ContentBox $direction={'column'}>
            <Box
              padding={'30px'}
              gap={'1.5rem'}
              minWidth={'300px'}
            >
              <Row $gap="8px">
                <PiNavigationArrowFill
                  size={22}
                  color="rgba(0, 0, 0, 0.54)"
                  style={{ transform: 'rotate(90deg)' }}
                />
                <Title>Origem do Transporte</Title>
              </Row>

              <Row $gap="8px" style={{ width: '100%' }}>
                <SelectBox
                  selectItem={(id) => {
                    setShipment({
                      ...shipment,
                      dispatcherId: id,
                      originShipperEntityId: id,
                      senderId: id
                    })
                  }}
                  textOptionSubItemKey="documentNumber"
                  placeholder={'Remetente *'}
                  clickButton={toggleOffCanvas}
                  enableButton
                  showSubItem
                  items={_.sortBy(
                    entities?.map((e) => ({
                      ...e, nameOrDisplayName: e?.displayName || e?.name,
                    })),
                    'nameOrDisplayName'
                  )}
                  principalKey={'id'}
                  textOption={'nameOrDisplayName'}
                  textButton="Criar Novo"
                  colorTextButton={'#1976D2'}
                  icon={<BsFillPersonPlusFill size={22} />}
                />
              </Row>
            </Box>

            <Box
              padding={'30px'}
              gap={'1.5rem'}
              minWidth={'300px'}
            >
              <Row $gap="8px">
                <FaLocationDot size={22} color="rgba(0, 0, 0, 0.54)" />
                <Title>Destino do Transporte</Title>
              </Row>

              <Row $gap="8px" style={{ width: '100%' }}>
                <SelectBox
                  selectItem={(id) => {
                    setShipment({
                      ...shipment,
                      receiverId: id,
                      recipientId: id,
                      destinationShipperEntityId: id
                    })
                  }}
                  textOptionSubItemKey="documentNumber"
                  placeholder={'Destinatário *'}
                  clickButton={toggleOffCanvas}
                  enableButton
                  showSubItem
                  items={_.sortBy(
                    entities?.map((e) => {
                      return {
                        ...e,
                        nameOrDisplayName: e?.displayName || e?.name,
                      }
                    }),
                    'nameOrDisplayName'
                  )}
                  principalKey={'id'}
                  textOption={'nameOrDisplayName'}
                  textButton="Criar Novo"
                  colorTextButton={'#1976D2'}
                  icon={<BsFillPersonPlusFill size={22} />}
                />
              </Row>
            </Box>
          </ContentBox>

          {isCarryingCompany && (
            <UploadFileCTe />
          )}

          {allowNfeAndContentStatement && (
            <UploadFileNotaAndDeclaration />
          )}

          <ContentBox $direction={'column'}>
            <Box
              padding={'30px'}
              gap={'1.5rem'}
              minWidth={'300px'}
            >
              <Row $gap="8px">
                <BsBoxSeamFill size={20} color="rgba(0, 0, 0, 0.54)" />
                <Title>Volumes</Title>
              </Row>

              <Row $gap="56px">
                <div style={{ minWidth: '148px', maxWidth: '20%' }}>
                  <InputText
                    type="text"
                    height="33px"
                    label="Peso total da carga *"
                    leftItem={'Kg'}
                    value={maskCurrency(shipment.totalWeight).replace('R$', '')}
                    onChange={(e) => {
                      setShipment({
                        ...shipment,
                        totalWeight: mascaraMoeda(e.target.value)
                      })
                    }}
                  />
                </div>

                <div style={{ minWidth: '148px', maxWidth: '20%' }}>
                  <InputText
                    type="text"
                    height="33px"
                    label="Valor a ser transportado *"
                    value={maskCurrency(shipment.declaredValue)}
                    onChange={(e) => setShipment({
                      ...shipment,
                      declaredValue: mascaraMoeda(e.target.value)
                    })}
                  />
                </div>
              </Row>

              {shipment.volumes?.map((volume, id) => (
                <ListVolumes volume={volume} key={id} />
              ))}

              <Row style={{ marginTop: '2rem' }}>
                <div>
                  <BtnCustom
                    text={'ADICIONAR VOLUME'}
                    color={'#1976D2'}
                    backgroundColor={'transparent'}
                    hoverColor={'rgba(96, 103, 113, 0.04)'}
                    padding={'8px 9px'}
                    fontSize={'13px'}
                    border={'solid 1px rgb(140, 186, 232)'}
                    textWeight={'500'}
                    shadowNone
                    icon={<FaPlus size={15} />}
                    click={addMoreVolumes}
                  />
                </div>
              </Row>
            </Box>

            <Box
              padding={'30px'}
              gap={'1.5rem'}
              minWidth={'300px'}
            >
              <Row $gap="8px">
                <PiNoteFill size={22} color="rgba(0, 0, 0, 0.54)" />
                <Title>Outros</Title>
              </Row>

              <Row $gap="8px">
                <InputText
                  type="text"
                  placeholder={`Código de Referência${requiredShipperCode ? ' (Este campo é de responsabilidade do embarcador)' : ''}`}
                  limit={255}
                  required={requiredShipperCode}
                  value={shipment?.shipperCode}
                  onChange={(e) => {
                    setShipment({
                      ...shipment,
                      shipperCode: e.target.value
                    })
                  }}
                />
              </Row>
            </Box>
          </ContentBox>
        </Content>

        <Footer>
          <FooterItem>
            <div style={{ display: 'flex' }}>
              {!loading && (
                <BtnCustom
                  text={'CADASTRAR'}
                  color={'white'}
                  backgroundColor={'#1976D2'}
                  hoverColor={'rgb(20, 94, 168)'}
                  padding={'0.6rem 0.875rem'}
                  click={async () => {
                    setLoading(true)
                    await saveNewShipment()
                      .catch((err) => {
                        console.log(err)
                        setMessage(err?.message || 'Confere os dados por favor')
                      })
                      .finally(() => setLoading(false))
                  }}
                />
              )}

              {loading && (
                <Spinner
                  loading={true}
                  height={'100%'}
                  width={'100%'}
                  size={30}
                />
              )}
            </div>
          </FooterItem>

        </Footer>

        <OffCanvas
          expanded={isOffCanvasOpen}
          onClose={() => {
            setErros({})
            closeOffCanvas()
          }}
          title={'Novo Participante'}
          clearValues={() => console.log('limpar infos')}
          width={'850px'}
          clickSave={async () => {
            await createEntity(formData)
            setErros({})
            closeOffCanvas()
          }}
        >
          <FormEntity />
        </OffCanvas>
      </Container>
    </>

  )
}
