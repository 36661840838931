import { gandalfAPI } from '../api/Axios'

/**
 * @param {string} email 
 * @param {string} password 
 */
export async function auth (email, password) {
  const response = await gandalfAPI.post('/v2/users/sessions', {
    email,
    password
  })

  return response.data
}

/**
 * @param {string} email 
 */
export async function forgotPassword (email) {
  await gandalfAPI.post('/v2/users/forgot-password', {
    email,
  })
}

