import { gql } from '@apollo/client'

export const LIST_PRODUCTS_TYPES = gql`
  query productTypes {
    productTypes {
      data {
        id
        name
      }
    }
  }
`