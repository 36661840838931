import styled from 'styled-components'

export const ContainerPage = styled.div`
  margin: auto;
  max-width: ${props => props.$maxWidth || '1366px'} ;
  min-width: ${props => props.$minWidth || '1280px'} ;
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: ${props => props.$gap || '0.875rem'};
  padding: ${props => props.$padding || '0'};
  ${props => props.$height ? `height: ${props.$height}` : ''};
  ${props => props.$width ? `width: ${props.$width}` : ''};
 
`
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${props => props.$gap || '1.5rem'};
  ${props => props.$width ? `width: ${props.$width}` : ''};
`
