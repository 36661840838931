import { createContext, useState } from 'react'

import { INITIAL_STATE_SHIPMENT } from '../pages/NewShipment/InitialState'

export const NewShipmentContext = createContext({})

export const NewShipmentProvider = ({ children }) => {
  const [shipment, setShipment] = useState(INITIAL_STATE_SHIPMENT)

  return (
    <NewShipmentContext.Provider value={{ shipment, setShipment }}>
      {children}
    </NewShipmentContext.Provider>
  )
}
