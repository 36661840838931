import { BarLoader, ClipLoader } from 'react-spinners'

export function Spinner ({ loading, width, height, size = 60, color = '#2f159e', typeLoader = 'BarLoader' }) {
  if (!loading) {
    return <></>
  }

  return (
    <div style={{
      width,
      height,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>

      {typeLoader === 'ClipLoader' && (
        <ClipLoader
          color={color}
          loading={loading}
          size={size}
        />
      )}

      {typeLoader === 'BarLoader' && (
        <BarLoader
          color={color}
          loading={loading}
          size={size} />
      )}
    </div>
  )
}