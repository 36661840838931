import { orderBy } from 'lodash'
import { useContext, useState } from 'react'
import { GoOrganization } from 'react-icons/go'

import { AppContext } from '../../../../providers/AppProvider'
import { Modal } from '../../../Modal'


export function SelectOrganization ({
  open,
  onClose,
}) {
  const { user } = useContext(AppContext)

  const [filter, setFilter] = useState(null)
  const [organizationId, setOrganizationId] = useState(null)

  const organizations = orderBy(user?.organizations, 'name', 'asc')?.filter(e => filter ? String(e.name).toLowerCase().includes(filter?.toLowerCase()) : true)

  return (
    <Modal
      open={open}
      onClose={(e) => {
        onClose(e)
        setFilter('')
      }}
      title={'Selecione a Organização'}
      textClose='CANCELAR'
      textConfirm='CONFIRMAR'
      height={100}
      titleIcon={<GoOrganization size={20} />}
      onConfirm={async (e) => {
        e.stopPropagation()
        
        if(!organizationId) {
          alert('Selecione uma organização')
          return
        }

        localStorage.setItem('EBB_DASHBOARD_ACTIVE_ORGANIZATION_SAURON', organizationId)
        window.location.reload()
      }}
    >
      <div>
        <div>
          <input
            value={filter || ''}
            onChange={(e) => setFilter(e.target.value)}
            style={{
              width: '100%',
              height: 20,
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              outline: 'none',
              color: '#666',
              fontSize: '1rem'
            }}
            type="text"
          />
        </div>
        <br />
        <div style={{ maxHeight: 150, minHeight: 150, overflow: 'auto', marginLeft: 20 }}>
          {organizations?.map((organization) => {
            const key = organization.id
            return (
              <div key={key} style={{ display: 'flex', alignItems: 'center', gap: 20, marginBottom: 20 }}>
                <input onChange={() => setOrganizationId(key)} checked={key === organizationId} style={{ height: 25, width: 25, cursor: 'pointer', color: '#666' }} type="radio" id={key} value={key} />
                <label style={{ cursor: 'pointer', fontSize: '1rem', width: '100%', color: '#666' }} htmlFor={key}>{organization.name}</label> <br />
              </div>
            )
          })}

          {!organizations?.length && (
            <>
              <h3 style={{ fontStyle: 'italic', textAlign: 'center', color: '#666' }}>Nenhuma organização encontrada.</h3>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}