import styled from 'styled-components'

export const ContainerOption = styled.div`
  width: 200px;
  border-radius: 5px;
  position: absolute;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: ${props => props.$display};
  top: 10px;
  right: -40px;
  z-index: 999999;

  ul {
    list-style: none;
  }

  ul li {
    height: 30px;
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: 1rem;
    font-weight: 400;
    color: #666;
    border-radius: 5px;

    &:hover {
      transition: 0.5s;
      background-color: #e1e1e5;
    }

    svg {
      margin-right: 10px;
    }
  }
`