import { gql } from '@apollo/client'

export const LIST_ENTITIES = gql`
  query ListEntities($filter: EntitiesFilter, $first: Int, $offset: Int!) {
    entities(filter: $filter, first: $first, offset: $offset) {
      data {
        address {
          cep
          complement
          location
          neighborhood
          number
          state
          street
        }
        code
        displayName
        documentNumber
        email
        id
        name
        organizationId
        phone
        shippingSite
        stateRegistration
        type
      }
      total
    }
  }
`

export const LIST_ENTITES_AND_ASSOCIATIONS = gql`
  query ListEntities($filter: EntitiesFilter) {
    entities: entities(filter: $filter) {
        data {
          address {
            cep
            complement
            location
            neighborhood
            number
            state
            street
          }
          code
          displayName
          documentNumber
          email
          id
          name
          organizationId
          phone
          shippingSite
          stateRegistration
          type
      }
    }
  }
`
