import _ from 'lodash'
import { useContext, useState, useRef } from 'react'
import { FaFile } from 'react-icons/fa6'
import { IoMdCloseCircle } from 'react-icons/io'
import styled from 'styled-components'

import { Box } from '../../../components/UI/Box'
import { BtnCustom } from '../../../components/UI/Btn'
import { Grid } from '../../../components/UI/Grid'
import { Spinner } from '../../../components/UI/Spiner'
import { Text } from '../../../components/UI/Text'
import { NewShipmentContext } from '../../../providers/NewShipment'
import { fiscalDocuments } from '../../../services/shipmentService'
import { maskCurrency } from '../../../Utils'

const DropArea = styled.div`
  border: 1px dashed #ccc;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

export const UploadFileCTe = () => {
  const { shipment, setShipment } = useContext(NewShipmentContext)

  const [fileError, setFileError] = useState(false)
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const fileInputRef = useRef(null)

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handleFiles = async (newFiles) => {
    const filesArray = Array.from(newFiles)
    const filesInBase64 = await Promise.all(
      filesArray.map(async (file) => {
        const base64 = await fileToBase64(file)
        return {
          name: file.name,
          xmlBase64: base64,
        }
      })
    )

    try {
      const result = await fiscalDocuments(filesInBase64.map(e => ({ xmlBase64: e.xmlBase64.replace('data:text/xml;base64,', '') })), true)

      const listPreviousDocuments = [...result, ...(shipment?.previousDocuments || [])]

      const listDocuments = _.uniqBy(listPreviousDocuments, 'key')

      setShipment({
        ...shipment,
        previousDocuments: listDocuments,
        declaredValue: listDocuments?.reduce((prev, current) => prev + current?.value, 0)
      })

      setFiles((prevFiles) => [...prevFiles, ...filesInBase64])
    } catch {
      setFileError(true)
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  const handleDrop = async (event) => {
    try {
      event.preventDefault()
      setLoading(true)
      const droppedFiles = event.dataTransfer.files
      await handleFiles(droppedFiles)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      setFileError(false)
    }
  }

  let title = 'Insira os CTes'

  const inputName = 'fileInput-cte'

  return (
    <Box style={{ marginTop: 10, paddingTop: 10 }}>
      <Grid style={{ width: '98%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 10px 0px 10px' }}>
        <Text as='h2' fontSize='1.2rem' padding={'0px 0px 15px 0px'} fontWeight='500'>
          {title}
        </Text>
      </Grid>
      <DropArea
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onClick={() => {
          setFileError(false)
          document.getElementById(inputName).click()
        }}
      >

        {fileError ?
          <>
            <IoMdCloseCircle size={40} color='red' />
            <Text as='p' color='red' fontWeight='500' style={{ width: '90%', textAlign: 'center' }}>
              Arquivo inválido
            </Text>
          </>
          : <>
            {loading ? (
              <Spinner loading={loading} width={'100%'} height={'100%'} />
            ) : (
              <>
                <FaFile size={40} color='#66666687' />
                <Text as='p' color='#666' style={{ width: '90%', textAlign: 'center' }}>
                  Arraste os arquivos XML aqui
                </Text>
              </>
            )}
          </>
        }

        <input
          type="file"
          accept={'.xml'}
          multiple
          onChange={async (e) => {
            setLoading(true)
            await handleFiles(e.target.files)
              .finally(() => {
                setLoading(false)
              })
          }}
          style={{ display: 'none' }}
          id={inputName}
          ref={fileInputRef} // Adicione a referência aqui
        />

        <div>
          <BtnCustom
            heigth={'50px!important'}
            text={' OU ESCOLHA DE SEU COMPUTADOR'}
            hoverColor={'#1e6ab6'}
          />
        </div>
      </DropArea>

      {
        (files.length > 0) && (
          <Grid style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
            <Text as='p'>{shipment?.previousDocuments?.length ?? 0} CTes</Text>
            <Text as='p'>Valor total: {maskCurrency(shipment?.['previousDocuments']?.reduce((prev, current) => prev + current?.value, 0))}</Text>
          </Grid>
        )
      }

    </Box >
  )
}