import styled from 'styled-components'

export const ButtonItemShipment = styled.div`
  height: 70%;
  padding: 5px;
  margin-right: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  &:hover{
    background-color: #f1f1f152;
    padding: 5px;
    height: 70%;
  }
`