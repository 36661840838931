import dayjs from 'dayjs'

import { EntityDetails } from './EntityDetails'
import { SHIPMENT_STATUS_LABEL_MAP } from '../../../../../enums/shipment-status.enum'
import { decimalNumber } from '../../../../../helpers/mask.helper'
import { convertGramsToKilograms } from '../../../../../Utils'
import { TabContent, TabRow, TextRow, TextValueRow } from '../../../../UI/Box/styles'

export function TabDetails ({ shipment, isVisible }) {
  const shipmentIdentifier = shipment?.shipperCode || shipment?.carrierCode || shipment?.trackingCode

  return (
    <TabContent $isVisible={isVisible} >
      <TabRow $columns={'200px 1fr 1fr 1fr'}>
        <div>
          <TextRow>Código de Rastreio</TextRow>
          <TextValueRow>{shipmentIdentifier}</TextValueRow>
        </div>
        <div>
          <TextRow>Situação</TextRow>
          <TextValueRow>{SHIPMENT_STATUS_LABEL_MAP.get(shipment?.status) || '---'}</TextValueRow>
        </div>
        <div>
          <TextRow>Data de criação</TextRow>
          <TextValueRow>
            {shipment?.createdAt
              ? dayjs(shipment?.createdAt).format('DD/MM/YYYY HH:mm')
              : '---'}
          </TextValueRow>
        </div>
        <div>
          <TextRow>Data de atualização</TextRow>
          <TextValueRow>
            {shipment?.updatedAt
              ? dayjs(shipment?.updatedAt).format('DD/MM/YYYY HH:mm')
              : '---'}
          </TextValueRow>
        </div>
      </TabRow>
      <TabRow>
        <div>
          <TextRow>Quantidade</TextRow>
          <TextValueRow >
            {shipment?.volumesQuantity
              ? `${shipment?.volumesQuantity} volume(s)`
              : '---'}
          </TextValueRow>
        </div>
        <div>
          <TextRow>Peso</TextRow>
          <TextValueRow>
            {shipment?.totalWeight
              ? `${decimalNumber(convertGramsToKilograms(shipment?.totalWeight))} Kg`
              : '---'}
          </TextValueRow>
        </div>
        <div>
          <TextRow>Peso Cubado</TextRow>
          <TextValueRow>
            {shipment?.cubageWeight
              ? `${decimalNumber(convertGramsToKilograms(shipment?.cubageWeight))} Kg`
              : '---'}
          </TextValueRow>
        </div>
        <div>
          <TextRow>Valor declarado</TextRow>
          <TextValueRow>
            {shipment?.declaredValue
              ? `R$ ${decimalNumber(shipment?.declaredValue)}`
              : '---'}
          </TextValueRow>
        </div>
      </TabRow>
      <TabRow>
        <div>
          <TextRow>Previsão de saída</TextRow>
          <TextValueRow>
            {shipment?.etd
              ? dayjs(shipment?.etd).format('DD/MM/YYYY HH:mm')
              : '---'}
          </TextValueRow>
        </div>
        <div>
          <TextRow>Previsão de entrega</TextRow>
          <TextValueRow>
            {shipment?.eta
              ? dayjs(shipment?.eta).format('DD/MM/YYYY HH:mm')
              : '---'}
          </TextValueRow>
        </div>
      </TabRow>
      {(shipment?.sender || shipment?.dispatcher) && (
        <EntityDetails
          entity={shipment?.sender || shipment?.dispatcher}
          title="Remetente"
        />
      )}

      {(shipment?.recipient || shipment?.receiver) && (
        <EntityDetails
          entity={shipment?.recipient || shipment?.receiver}
          title="Destinatário"
        />
      )}

      {shipment?.consignee && (
        <EntityDetails entity={shipment?.consignee} title="Tomador" />
      )}

      {shipment?.authorizedReceiver && (
        <EntityDetails
          entity={shipment?.authorizedReceiver}
          title="Responsável pelo recebimento"
        />
      )}
    </TabContent>
  )
}
