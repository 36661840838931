import { createContext } from 'react'

import { useShipments } from '../hooks/Shipment'

export const ShipmentContext = createContext({})

export const ShipmentProvider = ({ children }) => {
  const { list } = useShipments()

  return (
    <ShipmentContext.Provider value={{ list }}>
      {children}
    </ShipmentContext.Provider>
  )
}
