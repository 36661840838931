import { gql } from '@apollo/client'

export const LIST_PACKAGES = gql`
  query ListPackages($organizationId: Int) {
    packages(organizationId: $organizationId) {
      height
      id
      length
      name
      organizationId
      width
    }
  }

`

export const GET_PACKAGE = gql`
  query FindPackage($id: Int!) {
    package(id: $id) {
      height
      id
      length
      name
      organizationId
      width
    }
  }
`