import { useEffect, useState } from 'react'
import { IoMdArrowBack } from 'react-icons/io'
import { PiPackageFill } from 'react-icons/pi'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBoxPackage } from './style'
import { ModalError } from '../../components/ModalError'
import { Box } from '../../components/UI/Box'
import { BtnCustom } from '../../components/UI/Btn'
import { Grid } from '../../components/UI/Grid'
import { InputText } from '../../components/UI/Inputs/InputText'
import { Spinner } from '../../components/UI/Spiner'
import { Text } from '../../components/UI/Text'
import { usePackage } from '../../hooks/Packages'
import { createPackage, updatePackage } from '../../services/packageService'
import { ContainerPage, Row } from '../../styled-components/style'

export function NewPackages () {
  const navigate = useNavigate()
  let { packageId } = useParams()

  const [erros, setErros] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const [modalError, setModalError] = useState(false)

  const {
    getPackage: { refetch }
  } = usePackage()

  const [packageItem, setPackageItem] = useState({
    'id': '',
    'height': '',
    'length': '',
    'name': '',
    'width': ''
  })


  function float (e) {
    let inputValue = e.target.value.replace(/[^0-9.,]/g, '')
    inputValue = inputValue.replace(',', '.')
    const [integer, decimal] = inputValue.split('.')

    let cleanedValue = integer.substring(0, 6)

    if (decimal) {
      cleanedValue += '.' + decimal.substring(0, 3)
    }

    return cleanedValue
  }

  async function edit () {
    setLoading(true)
    const data = await refetch({
      id: +packageId
    })

    const item = data.data.package
    setPackageItem({
      height: item.height,
      length: item.length,
      name: item.name,
      width: item.width
    })

    setLoading(false)
  }

  useEffect(() => {
    if (packageId)
      edit()
    else
      setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageId])

  return (
    <>
      <ModalError
        open={modalError}
        onClose={() => setModalError(false)}
      />

      <ContainerPage $maxWidth={'800px'} $minWidth={'800px'}>
        <Box shadow={false} backgroundColor='transparent' flexDirection='row' alignItems='center' justifyContent='flex-start'>
          <Box width='70px' justifyContent='flex-start' alignItems='flex-start' padding='0px' backgroundColor='transparent' containerWidth={'70px'} shadow={false} click={() => navigate('/packages')}>
            <BtnCustom
              text={''}
              icon={<IoMdArrowBack size={35} color="#1976D2" />}
              backgroundColor={'transparent'}
              color={'#606771'}
              hoverColor={'rgba(96, 103, 113, 0.04)'}
              shadowNone
              width={100}
            />
          </Box>

          <Box shadow={false} backgroundColor='transparent'>
            <Text as='h1' fontSize='1.5rem' fontWeight='700' color='#363487'>
              {packageId ? 'Editar' : 'Nova'} Embalagem
            </Text>
          </Box>
        </Box>

        <Box padding='15px'>
          <Row>
            <PiPackageFill size={30} color='#666' />
            <Text as='h3' color='#666'>Embalagem</Text>
          </Row>

          {!loading && (
            <>
              <ContentBoxPackage>
                <Box shadow={false}>
                  <InputText
                    type="text"
                    height="33px"
                    label="Descrição"
                    width='200px'
                    value={packageItem.name}
                    onChange={(e) => {
                      setPackageItem({ ...packageItem, name: e.target.value })
                      erros?.delete('name')
                    }}
                    isValid={!erros?.has('name')}
                    color={erros?.has('name') ? 'red' : undefined}
                  />
                  <Text fontSize='0.7rem' color='#b3b1b1'>
                    Como será exibida essa embalagem no sistema
                  </Text>
                </Box>
                <Box
                  flexDirection='row'
                  gap={'20px'}
                  shadow={false}
                  alignItems='flex-end'
                >
                  <Grid>
                    <InputText
                      type="number"
                      height="33px"
                      label="Comprimento"
                      leftItem={'cm'}
                      value={packageItem.length}
                      onChange={(e) => {
                        setPackageItem({ ...packageItem, length: float(e) })
                        erros?.delete('length')
                      }}
                      isValid={!erros?.has('length')}
                      color={erros?.has('length') ? 'red' : undefined}
                    />
                    <Text fontSize='0.7rem' color='#b3b1b1'>
                      Deve ser um número
                    </Text>
                  </Grid>
                  <Grid width='10px'>
                    <Text color='#666' as="span">X</Text>
                  </Grid>
                  <Grid>
                    <InputText
                      type="number"
                      height="33px"
                      label="Largura"
                      leftItem={'cm'}
                      value={packageItem.width}
                      onChange={(e) => {
                        setPackageItem({ ...packageItem, width: float(e) })
                        erros?.delete('width')
                      }}
                      isValid={!erros?.has('width')}
                      color={erros?.has('width') ? 'red' : undefined}
                    />
                    <Text fontSize='0.7rem' color='#b3b1b1'>
                      Deve ser um número
                    </Text>
                  </Grid>
                  <Grid width='10px'>
                    <Text color='#666'>X</Text>
                  </Grid>
                  <Grid>
                    <InputText
                      type="number"
                      height="33px"
                      label="Altura"
                      leftItem={'cm'}
                      value={packageItem.height}
                      onChange={(e) => {
                        setPackageItem({ ...packageItem, height: float(e) })
                        erros?.delete('height')
                      }}
                      isValid={!erros?.has('height')}
                      color={erros?.has('height') ? 'red' : undefined}
                    />
                    <Text fontSize='0.7rem' color='#b3b1b1'>
                      Deve ser um número
                    </Text>
                  </Grid>
                </Box>
              </ContentBoxPackage>

              <Box width='100%' flexDirection='row' alignItems='right' justifyContent='flex-end' margin='10px 0px 0px 0px' shadow={false}>
                <BtnCustom
                  text={packageId ? 'Atualizar' : 'Cadastrar'}
                  hoverColor={'#1d6cbb'}
                  width={100}
                  icon={null}
                  loading={loadingBtn}
                  click={async () => {
                    setLoadingBtn(true)

                    try {
                      let error = null
                      if (packageId) {
                        error = await updatePackage(packageId, packageItem)
                      } else {
                        error = await createPackage(packageItem)
                      }

                      if (!error) {
                        return navigate('/packages')
                      }

                      const aux = error?.map(obj => [Object.keys(obj)[0], Object.values(obj)[0]]) ?? []
                      const map = new Map(aux)

                      setErros(map)

                    } catch (e) {
                      console.log(e)
                      setModalError(true)
                    } finally {
                      setLoadingBtn(false)
                    }
                  }}
                />
              </Box>
            </>
          )}

          {loading && (
            <Spinner
              loading={true}
            />
          )}

        </Box>
      </ContainerPage>
    </>
  )
}