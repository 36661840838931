import styled from 'styled-components'

/**
 * @param {{
 * title: string,
 * gap: string,
 * children: import('react').Component,
 * padding: string,
 * margin: string,
 * minHeight: string,
 * height: string,
 * flexDirection: 'column' | 'row',
 * cursor: 'default' | 'pointer',
 * minWidth: string,
 * width: string,
 * containerWidth: string,
 * click: Function,
 * backgroundColor: string,
 * shadow: boolean,
 * justifyContent: 'center' | 'flex-start' | 'flex-end' | 'left' | 'right' | 'baseline',
 * alignItems: 'center' | 'flex-start' | 'flex-end' | 'left' | 'right' | 'baseline',
 * style: React.CSSProperties
 * }} param0 
 * @returns 
 */
export function Box ({
  alignItems,
  backgroundColor,
  children,
  click,
  containerWidth,
  cursor = 'default',
  flexDirection = 'column',
  gap,
  height = '100%',
  justifyContent,
  margin,
  minHeight,
  minWidth,
  padding,
  shadow = true,
  title,
  width = '100%',
  hover = null,
  hoverColor = null,
  style
}) {
  return (
    <div style={{ width: containerWidth || '100%' }}>
      {title && <Title>{title}</Title>}

      <Content
        onClick={click}
        $flexDirection={flexDirection}
        $gap={gap}
        $padding={padding}
        $minHeight={minHeight}
        $minWidth={minWidth}
        $cursor={cursor}
        $backgroundColor={backgroundColor}
        $shadow={shadow}
        $justifyContent={justifyContent}
        $alignItems={alignItems}
        $margin={margin}
        $width={width}
        $height={height}
        $hover={hover}
        $hoverColor={hoverColor}
        style={style}
      >
        {children}
      </Content>
    </div>
  )
}

const Title = styled.p`
  color: #606771;
  font-weight: 500;
  padding: 0.5rem 0;
  font-size: 20px;
`

const Content = styled.div`
  display: flex;
  flex-direction: ${props => props.$flexDirection};
  gap: ${props => props.$gap || 0};
  padding: ${props => props.$padding || '10px'};
  min-height: ${props => props.$minHeight || 'auto'};
  cursor: ${props => props.$cursor || 'default'};
  box-shadow: ${props => (props.$shadow ? 'rgb(194, 195, 201) 0px 0px 0px 1px' : '')};
  min-width: ${props => props.$minWidth};
  background-color: ${props => props.$backgroundColor || 'white'};
  align-items: ${props => props.$alignItems || 'normal'};
  justify-content: ${props => props.$justifyContent || 'normal'};
  margin: ${props => props.$margin || '0px'};

  ${props => props.$hover ?
    `&:hover {
      transition: 0.3s;
      background-color: ${props.$hoverColor}
    }
    `: ''
  };
  
`