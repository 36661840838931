import { useState } from 'react'
import { BsFillPersonPlusFill } from 'react-icons/bs'

import { InputText } from '../../../components/UI/Inputs/InputText'
import { SelectBox } from '../../../components/UI/SelectBox'
import { usePackage } from '../../../hooks/Packages'
import { Col, Row } from '../../../styled-components/style'
import { useNewShipment } from '../hooks'
import { ContentIconMinus, IconMinus } from '../styles'

export function ListVolumes ({
  volume
}) {
  const {
    listPackages: { data: listPackages }
  } = usePackage()

  const [isSelectPackage, setIsSelectPackage] = useState(false)

  const { removeVolume, updateVolume, } = useNewShipment()

  return (
    <Row $gap="24px" className="row-container-volumes">
      <Col style={{ width: '6.5%', minWidth: '40px' }}>
        <Row>
          <InputText
            type="text"
            height="37px"
            label="Qtde."
            onChange={(e) => updateVolume({ ...volume, quantity: e.target.value })}
            value={volume.quantity}
          />
        </Row>
      </Col>

      <Col $gap="0">
        {!isSelectPackage && (
          <Row $gap="28px" className="row-content-volumes">
            <div style={{ minWidth: '80px', maxWidth: '10%' }}>
              <InputText
                type="text"
                height="37px"
                leftItem={'cm'}
                label={
                  'Dimensões - Comprimento x Largura x Altura (cm) *'
                }
                onChange={(e) => updateVolume({ ...volume, height: e.target.value })}
                value={volume.height}
              />
            </div>

            <p style={{ fontSize: '13px', margin: '12px 0 0 0' }}>X</p>

            <div style={{ minWidth: '80px', maxWidth: '10%' }}>
              <InputText
                type="text"
                height="37px"
                leftItem={'cm'}
                onChange={(e) => updateVolume({ ...volume, length: e.target.value })}
                value={volume.length}
              />
            </div>

            <p style={{ fontSize: '13px', margin: '12px 0 0 0' }}>X</p>

            <div style={{ minWidth: '80px', maxWidth: '10%' }}>
              <InputText
                type="text"
                height="37px"
                leftItem={'cm'}
                onChange={(e) => updateVolume({ ...volume, width: e.target.value })}
                value={volume.width}
              />
            </div>

            <ContentIconMinus>
              <IconMinus size={22} onClick={() => removeVolume(volume.id)} />
            </ContentIconMinus>
          </Row>
        )}

        {isSelectPackage && (
          <Row $gap="28px" style={{ marginTop: 11 }} className="row-content-volumes">
            <SelectBox
              selectItem={(id) => {
                const result = listPackages?.packages?.find(e => e.id === id)
                updateVolume({
                  ...result,
                  id: volume.id,
                  quantity: volume.quantity,
                })
              }}
              placeholder={'Embalagens'}
              items={listPackages?.packages ?? []}
              principalKey={'id'}
              textOption={'name'}
              icon={<BsFillPersonPlusFill size={22} />}
              width='361px'
            />

            <ContentIconMinus>
              <IconMinus size={22} onClick={() => removeVolume(volume.id)} />
            </ContentIconMinus>
          </Row>
        )}

        <Row>
          <p
            style={{
              marginTop: '6px',
              color: '#1976D2',
              fontSize: '12px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setIsSelectPackage(state => !state)
            }}
          >
            {isSelectPackage ? 'Informar as dimensões' : 'Escolha uma embalagem cadastrada'}
          </p>
        </Row>
      </Col>
    </Row>
  )
}