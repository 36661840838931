import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ContainerLogin, FormLogin, HeaderLogin } from './style'
import { ModalError } from '../../components/ModalError'
import { BtnCustom } from '../../components/UI/Btn'
import { Grid } from '../../components/UI/Grid'
import { InputText } from '../../components/UI/Inputs/InputText'
import { Text } from '../../components/UI/Text'
import { auth } from '../../services/gandalfService'

export function LoginPage () {
  const navigate = useNavigate()
  const [modalError, setModalError] = useState({
    open: false,
    message: ''
  })

  const [state, setState] = useState({
    email: '',
    password: ''
  })

  async function login () {
    try {
      const response = await auth(state.email, state.password)

      const organizationSelect = response?.user?.organization?.id || response.user.organizations[0].id

      const tokens = response.user.organizations.reduce((acc, org, index) => {
        acc[org.id] = response.tokens[index]
        return acc
      }, {})

      localStorage.setItem('EBB_DASHBOARD_ACTIVE_ORGANIZATION_SAURON', organizationSelect)
      localStorage.setItem('EBB_DASHBOARD_SESSION_TOKEN_SAURON', JSON.stringify(tokens))
      localStorage.setItem('EBB_DASHBOARD_USER_SAURON', JSON.stringify(response.user))

      navigate('/shipments')
    } catch {
      setModalError({
        open: true,
        message: 'E-mail ou senha inválido'
      })
    }
  }

  useEffect(() => {
    document.title = 'Dashboard'
  }, [])

  return (
    <>
      <ModalError
        open={modalError.open}
        message={modalError.message}
        onClose={() => setModalError({
          open: false,
          message: '',
        })}
      />
      <ContainerLogin>
        <HeaderLogin>
          <div style={{ position: 'fixed', top: 10, left: 10 }}>
            <img src="/logo.svg" alt="" />
          </div>
          <FormLogin>
            <Grid style={{ padding: 30, width: 350, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: 20 }}>
              <Text as='h1' fontSize='1.5rem'>Acesse sua conta</Text>
              <InputText
                type='text'
                label={'E-mail'}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                value={state.email}
              />
              <InputText
                type='password'
                label={'Senha'}
                onChange={(e) => setState({ ...state, password: e.target.value })}
                value={state.password}
              />

              <BtnCustom
                text={'Entrar'}
                backgroundColor={'#363487'}
                hoverColor={'#3b3a7a'}
                click={() => login()}
              />

              <BtnCustom
                text={'Esqueci minha senha'}
                backgroundColor={'transparent'}
                click={() => navigate('/forgot-password')}
                color={'#3936c7'}
                hoverColor={'transparent'}

              />

            </Grid>
          </FormLogin>
        </HeaderLogin>
      </ContainerLogin>
    </>
  )
}