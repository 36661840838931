import { useEffect, useState } from 'react'
import { FaBox } from 'react-icons/fa'
import { PiCheckFatFill } from 'react-icons/pi'
import { TbListDetails } from 'react-icons/tb'

import { TabDetailsPickup } from './components/Tabs/TabDetailsPickup'
import { usePickup } from '../../hooks/Pickup'
import { DropContent, TabHeader, TabShipment, TabText } from '../UI/Box/styles'
import { Spinner } from '../UI/Spiner'
import { TabTimelinePickup } from './components/Tabs/TabOccurrencesPickup'
import { TabVolumesPickup } from './components/Tabs/TabVolumesPickup'

export function PickupDetails ({ id }) {
  const { getPickup: [getPickup, { data, loading }] } = usePickup()

  const [tabSelect, setTabSelect] = useState('DETALHES')

  useEffect(() => {
    if (id) {
      getPickup({ variables: { id } })
    }
  }, [getPickup, id])

  const pickup = data?.pickup

  return (
    <DropContent $id={id} >
      <TabShipment>
        <TabHeader>
          <TabText
            $isSelect={tabSelect === 'DETALHES'}
            onClick={() => setTabSelect('DETALHES')}
          >
            <TbListDetails /> DETALHES
          </TabText>
          <TabText
            $isSelect={tabSelect === 'VOLUMES'}
            onClick={() => setTabSelect('VOLUMES')}
          >
            <FaBox /> VOLUMES
          </TabText>
          <TabText
            $isSelect={tabSelect === 'OCORRÊNCIAS'}
            onClick={() => setTabSelect('OCORRÊNCIAS')}
          >
            <PiCheckFatFill /> OCORRÊNCIAS
          </TabText>
        </TabHeader>

        <Spinner loading={loading} width={'100%'} height={'calc(400px - 50px)'} />

        {!loading && (
          <>
            <TabDetailsPickup pickup={pickup} isVisible={tabSelect === 'DETALHES'} />
            <TabVolumesPickup pickup={pickup} isVisible={tabSelect === 'VOLUMES'} />
            <TabTimelinePickup shpperCode={pickup?.shipperCode} isVisible={tabSelect === 'OCORRÊNCIAS'} />
          </>
        )}
      </TabShipment>
    </DropContent>
  )
}
