import { FaRegFileLines } from 'react-icons/fa6'

import { ButtonGeocoding } from './style'

const ButtonFileDelivery = ({
  url
}) => {
  const handleOpenLocation = () => {
    window.open(url, '_blank')
  }

  return (
    <ButtonGeocoding onClick={handleOpenLocation}>
      <FaRegFileLines size={20} />
      <p>Ver Comprovante</p>
    </ButtonGeocoding>
  )
}

export default ButtonFileDelivery
