import { useState, useEffect } from 'react'

import { BtnCustom } from '../Btn'
import * as Off from './styles'
import { Row } from '../../../styled-components/style'

export function OffCanvas ({
  title = 'Default Title',
  expanded = false,
  onClose,
  backgroundColor,
  width,
  children,
  colorTitle,
  fontSizeTitle,
  clearValues,
  clickSave
}) {
  const [isExpanded, setIsExpanded] = useState(expanded)

  const handleClose = () => {
    if (onClose) onClose()
  }

  const getScrollWidth = () => {
    const scrollDiv = document.createElement('div')
    scrollDiv.style.visibility = 'hidden'
    scrollDiv.style.overflow = 'scroll'
    scrollDiv.style.position = 'absolute'
    scrollDiv.style.top = '-9999px'
    scrollDiv.style.width = '50px'
    document.body.appendChild(scrollDiv)

    const scrollWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
    document.body.removeChild(scrollDiv)
    return scrollWidth
  }

  const closeIfClickEmpty = (event) => {
    if (event.target.closest('.offCanvas')) {
      return
    }

    handleClose()
  }

  useEffect(() => {
    setIsExpanded(expanded)
    if (expanded) {
      const scrollWidth = getScrollWidth()
      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = `${scrollWidth}px`
    } else {
      document.body.style.overflow = 'auto'
      document.body.style.paddingRight = ''
    }

    if (expanded && clearValues) clearValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded])

  return (
    <Off.Container $isOpen={isExpanded} onMouseDown={closeIfClickEmpty} >
      <Off.Content
        $isOpen={isExpanded}
        $backgroundColor={backgroundColor}
        $width={width}
        className="offCanvas"
      >
        <Off.ContainerTitle>
          <Off.Title $colorTitle={colorTitle} $fontSizeTitle={fontSizeTitle}>
            {title}
          </Off.Title>
        </Off.ContainerTitle>

        <section
          style={{
            padding: '0 0.875rem 0.875rem 0.875rem',
            overflow: 'auto',
            height: 'calc(100vh - 124px)',
          }}
        >
          <div style={{ padding: '0.875rem' }}>
            {children}
          </div>
        </section>

        <Off.Footer>
          <Row style={{ padding: '0.875rem' }}>
            <BtnCustom
              text={'CADASTRAR'}
              click={clickSave}
            />
            <BtnCustom
              click={handleClose}
              text={'CANCELAR'}
              backgroundColor={'transparent'}
              color={'#606771'}
              hoverColor={'rgba(96, 103, 113, 0.04)'}
              shadowNone
            />
          </Row>
        </Off.Footer>
      </Off.Content>
    </Off.Container>
  )
}
