import styled from 'styled-components'

export const HeaderShipments = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Pickups = styled.div`
  display: flex;
  flex-direction: column;
`

export const CopyCodePickup = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: ${props => props.$gap || '0.875rem'};
  padding: ${props => props.$padding || '0'};
  ${props => props.$height ? `height: ${props.$height}` : ''};
  ${props => props.$width ? `width: ${props.$width}` : ''};
  
  .copy-icon {
    display: none;
    position: absolute;
    left: 80px;
    padding: 5px;
    border-radius: 50%;
  }  

  &:hover{
    .copy-icon {
      display: block;
    }  
  }

  .copy-icon:hover {
    transition: 0.4s;
    background-color: #dcdcdc;
    border-radius: 50%;
    padding: 5px;
  }
`