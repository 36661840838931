import { useQuery } from '@apollo/client'

import { LIST_PRODUCTS_TYPES } from '../graphql/ProductsTypes'

export function useProductsTypes () {
  const listProductsTypes = useQuery(LIST_PRODUCTS_TYPES)

  return {
    listProductsTypes
  }
}

