import axios from 'axios'
import Joi from 'joi'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'

import { zordonAPI } from '../../../api/Axios'
import { useShipments } from '../../../hooks/Shipment'
import { AppContext } from '../../../providers/AppProvider'
import { NewShipmentContext } from '../../../providers/NewShipment'
import { listEntities } from '../../../services/entities'
import { INITIAL_STATE_SHIPMENT } from '../InitialState'

export function useNewShipment () {
  const navigate = useNavigate()

  const { organizationId, requiredShipperCode } = useContext(AppContext)
  const { shipment, setShipment } = useContext(NewShipmentContext)
  const {
    queryListShipments: {
      refetch: listShipmentsRefetch,
    }
  } = useShipments()

  const addMoreVolumes = () => {
    setShipment((state) => ({
      ...state,
      volumes: [
        ...state.volumes,
        {
          ...INITIAL_STATE_SHIPMENT.volumes[0],
          id: v4(),
          quantity: 1,
        }
      ],
    }))
  }

  const updateVolume = (volume) => {
    setShipment((state) => {
      return ({
        ...state,
        volumes: [
          ...(state.volumes.map(e => {
            if (e.id != volume.id) {
              return e
            }
            return volume
          })),
        ],
      })
    })
  }

  const removeVolume = (id) => {
    setShipment((state) => ({
      ...state,
      volumes:
        state.volumes.length > 1
          ? state.volumes.filter((vol) => vol.id !== id)
          : state.volumes,
    }))
  }

  function validationShipment (body) {
    const schema = Joi.object({
      consigneeId: Joi.number(),
      originShipperEntityId: Joi.number().messages({
        '*': 'Remetente obrigatório.'
      }),
      destinationShipperEntityId: Joi.number().messages({
        '*': 'Destinatário obrigatório.'
      }),
      totalWeight: Joi.number().greater(1).messages({
        'number.base': 'O campo peso deve ser um número válido.',
        'number.greater': 'O campo peso deve ser maior que 0.',
      }),
      shipperCode: requiredShipperCode ?
        Joi.string().messages({
          '*': 'O campo de código de frete deve ser preenchido.'
        })
        :
        Joi.string().allow('', null),
      volumes: Joi.array().items(Joi.object({
        quantity: Joi.number().messages({
          '*': 'O campo quantidade deve ser um número válido.'
        }),
        length: Joi.number().messages({
          '*': 'O campo comprimento deve ser um número válido.'
        }),
        width: Joi.number().messages({
          '*': 'O campo largura deve ser um número válido.'
        }),
        height: Joi.number().messages({
          '*': 'O campo altura deve ser um número válido.'
        }),
      })),
    })

    const { error } = schema.validate(body, {
      allowUnknown: true
    })

    if (error?.details?.length > 0) {
      throw new Error(error?.details[0]?.message)
    }
  }

  async function saveNewShipment () {
    let body

    try {
      const entityConsignee = await listEntities(organizationId)
      const consignee = entityConsignee?.entities?.data[0]

      if (!consignee) {
        throw new Error('Organização não encontrada.')
      }

      if (shipment.fiscalDocuments.length > 1) {
        shipment.dispatchType = 'normal'
      }

      if (shipment.previousDocuments.length > 1) {
        shipment.dispatchType = 'intermediateRedispatch'
      }

      body = {
        ...shipment,
        consigneeId: consignee.id,
        totalWeight: +(shipment?.totalWeight * 1000).toFixed(),
        volumes: shipment.volumes.map(volume => ({
          quantity: +volume?.quantity || 1,
          length: +(+volume.length / 100).toFixed(2),
          width: +(+volume.width / 100).toFixed(2),
          height: +(+volume.height / 100).toFixed(2),
        })),
        previousDocuments: shipment.previousDocuments.map(document => ({
          cfop: document.cfop,
          goodsValue: document.goodsValue,
          issueDate: document.issueDate,
          issuerDocumentNumber: document.senderDocumentNumber,
          issuerName: document.senderName,
          key: document.key,
          value: document.value,
          volumesQuantity: document.volumesQuantity
        }))
      }

      validationShipment(body)

      if (!requiredShipperCode) {
        Reflect.deleteProperty(body, 'shipperCode')
      }

      // TEMP
      const tokens = JSON.parse(localStorage.getItem('EBB_DASHBOARD_SESSION_TOKEN_SAURON'))
      const ebbDashboardActiveOrganization = JSON.parse(localStorage.getItem('EBB_DASHBOARD_ACTIVE_ORGANIZATION_SAURON'))

      await zordonAPI.post('/shipments', body, {
        headers: {
          Authorization: `Bearer ${tokens[ebbDashboardActiveOrganization]}`,
        }
      })
      
      await listShipmentsRefetch({})
      navigate('/shipments')
    } catch (error) {
      logErrorToServer(error, body)
      throw new Error(error?.message || 'Criação de remessa falhou!')
    }
  }

  async function logErrorToServer (error, shipment) {
    try {
      await axios.post('https://correios.enviabybus.com.br/items/logs', {
        payload: JSON.stringify({
          body: shipment,
          error: error?.response?.data?.message || error.message,
          organizationId,
          storage: [
            localStorage.getItem('EBB_DASHBOARD_ACTIVE_ORGANIZATION_SAURON'),
            localStorage.getItem('EBB_DASHBOARD_SESSION_TOKEN_SAURON'),
            localStorage.getItem('EBB_DASHBOARD_USER_SAURON'),
          ]
        }),
      })
    } catch (logError) {
      console.error('Erro ao enviar log para o servidor:', logError)
    }
  }

  return {
    shipment,
    setShipment,
    addMoreVolumes,
    removeVolume,
    updateVolume,
    saveNewShipment
  }
}
