import { useLazyQuery } from '@apollo/client'

import { LIST_ENTITES_AND_ASSOCIATIONS } from '../graphql/entities'

export function useEntites () {
  const queryListEntitesAndAssociations = useLazyQuery(LIST_ENTITES_AND_ASSOCIATIONS)

  return {
    queryListEntitesAndAssociations
  }
}
