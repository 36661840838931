import dayjs from 'dayjs'

import { decimalNumber } from '../../../../../../helpers/mask.helper'
import { TabRow, TextRow, TextValueRow, TitleRow } from '../../../../../UI/Box/styles'

export function ShipmentPreviousDocuments ({ previousDocuments }) {
  return (
    <>
      <TabRow $columns={'1fr'} $padding={'20px 0px 0px 20px'}>
        <TitleRow> Documentos Anteriores ({previousDocuments?.length})</TitleRow>
      </TabRow>

      {previousDocuments?.map((previousDocument) => {
        return (
          <TabRow key={previousDocument.id}>
            <div>
              <TextRow>Número</TextRow>
              <TextValueRow>{previousDocument?.number || '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Série</TextRow>
              <TextValueRow>{previousDocument?.serie != null ? previousDocument.serie : '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Data Emissão</TextRow>
              <TextValueRow>{previousDocument?.issueDate
                ? dayjs(previousDocument?.issueDate).format('DD/MM/YYYY HH:mm')
                : '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Valor</TextRow>
              <TextValueRow>{previousDocument?.value
                ? `R$ ${decimalNumber(previousDocument?.value)}`
                : '--- '}</TextValueRow>
            </div>
          </TabRow>
        )
      })}
    </>
  )
}