import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import { NavBar } from '../components/Navbar'
import { ForgotPasswordPage } from '../pages/ForgotPassword'
import { HomePage } from '../pages/Home'
import { Packages } from '../pages/ListPackages'
import { ListPickupPage } from '../pages/ListPickup'
import { LoginPage } from '../pages/Login'
import { NewPackages } from '../pages/NewPackages'
import { NewShipmentPage } from '../pages/NewShipment'
import { NewPickupPage } from '../pages/Pickup'
import { AppProvider } from '../providers/AppProvider'
import { FilterProvider } from '../providers/Filter'
import { NewPickupProvider } from '../providers/NewPickupProvider'
import { NewShipmentProvider } from '../providers/NewShipment'
import { OffCanvasEntityProvider } from '../providers/OffCanvasProvider'

export function AppRoutes () {

  useEffect(() => {
    const ebbDashboardSessionTokens = localStorage.getItem('EBB_DASHBOARD_SESSION_TOKEN_SAURON')

    if (window.location.pathname === '/' && ebbDashboardSessionTokens) {
      window.location.pathname = '/shipments'
    }
  }, [])
  
  return (
    <Routes>
      <Route path="*" element={(
        <>
          <AppProvider>
            <FilterProvider>
              <NavBar />
              <HomePage />
            </FilterProvider>
          </AppProvider>
        </>
      )}></Route>
      <Route path="/" element={(
        <>
          <LoginPage />
        </>
      )}></Route>
      <Route path="/forgot-password" element={(
        <>
          <ForgotPasswordPage />
        </>
      )}></Route>

      <Route path="/shipments" element={(
        <>
          <AppProvider>
            <FilterProvider>
              <NavBar />
              <HomePage />
            </FilterProvider>
          </AppProvider>
        </>
      )}></Route>

      <Route path="/pickup/new" element={(
        <>
          <AppProvider>
            <NewPickupProvider>
              <OffCanvasEntityProvider>
                <NavBar />
                <NewPickupPage />
              </OffCanvasEntityProvider>
            </NewPickupProvider>
          </AppProvider>
        </>
      )}></Route>

      <Route path="/pickup" element={(
        <>
          <AppProvider>
            <NewPickupProvider>
              <NavBar />
              <ListPickupPage />
            </NewPickupProvider>
          </AppProvider>
        </>
      )}></Route>

      <Route path="/packages" element={(
        <>
          <AppProvider>
            <NavBar />
            <Packages />
          </AppProvider>
        </>
      )}></Route>
      <Route path="/packages/new" element={(
        <>
          <AppProvider>
            <NavBar />
            <NewPackages />
          </AppProvider>
        </>
      )}></Route>

      <Route path="/packages/:packageId/edit" element={(
        <>
          <AppProvider>
            <NavBar />
            <NewPackages />
          </AppProvider>
        </>
      )}></Route>

      <Route path="/shipments/new" element={(
        <>
          <AppProvider>
            <NewShipmentProvider>
              <OffCanvasEntityProvider>
                <NavBar />
                <NewShipmentPage />
              </OffCanvasEntityProvider>
            </NewShipmentProvider>
          </AppProvider>
        </>
      )}></Route>
    </Routes>
  )
}
