import styled from 'styled-components'

export const HeaderPackage = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  
  h3{
    font-size: 2rem;
    color: #363487;
  }

  span {
    color: #666;
  }
`

export const ContentPackage = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
`

export const NotFoundPackages = styled.div`
  width: 100%;
  min-height: 300px;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h1 {
      color: #363487;
    }

    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: #1976D2;
      font-size: 1.5rem;
      padding-top: 10px;
      font-weight: 600;
    }
  }
`

export const ListPackages = styled.div`
  width: 100%;
`
export const ContainerListPackages = styled.div`
  position: relative;
  
  &:last-child{
    border-bottom: 1px solid #C2C3C9;
  }
  
`