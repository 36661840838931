import { useLazyQuery, useQuery } from '@apollo/client'
import { createContext, useState } from 'react'
import { v4 } from 'uuid'

import { EBB_CARRIER_PARENT_ENTITY_ID } from '../env'
import { GET_PICKUP, LIST_PICKUP } from '../graphql/pickups'

const INITIAL_STATE_FILTER = {
  filter: {},
  first: 20,
  offset: 0,
  order: 'DESC'
}

export const NewPickupContext = createContext({})

export const NewPickupProvider = ({ children }) => {
  const [pickup, setPickup] = useState({
    carrierParentEntityId: EBB_CARRIER_PARENT_ENTITY_ID,
    destinationShipperEntityId: null,
    dispatcherId: null,
    originShipperEntityId: null,
    receiverId: null,
    recipientId: null,
    senderId: null,
    shipperCode: '',
    volumes: [
      {
        id: v4(),
        weight: '',
        serialNumber: '',
        value: '',
        productTypeId: '',
      }
    ],
  })

  const [filters, setFilters] = useState(INITIAL_STATE_FILTER)

  const listPickup = useQuery(LIST_PICKUP, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
    variables: filters,
    pollInterval: 30000,
  })

  const getPickup = useLazyQuery(GET_PICKUP)

  return (
    <NewPickupContext.Provider
      value={{
        pickup, setPickup,
        filters, setFilters,
        listPickup,
        getPickup
      }}
    >
      {children}
    </NewPickupContext.Provider>
  )
}
