import _ from 'lodash'

import { zordonAPI } from '../api/Axios'

/**
 * @param {number} id 
 * @param {'a4' | 'raw' } format 
 */
export async function printEtiqueta (id, format) {
  const url = `/shipments/${id}/labels/download?format=${format}&template=brudam-11-14-qrcode`

  const response = await zordonAPI.get(url, {
    responseType: 'blob'
  })

  const fileURL = window.URL.createObjectURL(new Blob([response.data], {
    type: 'application/pdf',
  }))

  const win = window.open(fileURL, '_blank')
  win.print()
}

/**
 * @param {number} id 
 * @param {number} organizationId 
 */
export async function forceResendShipment (id, organizationId) {
  try {
    const url = '/shipments/resend'

    await zordonAPI.post(url, { id, organizationId })

    return true
  } catch {
    console.log('Error when force shipment')
    return false
  }
}

/**
 * @param {Record<string,any>} filter 
 */
export async function donwloadExcel (filter) {
  const url = '/shipment/export/excel'

  try {
    const body = {
      ...filter,
      // não pode muda de ordem
      'labels': {
        'createdAt': 'Data de Criação',
        'carrierCode': 'Cod Transportador',
        'shipperCode': 'Cod Embarcador',
        'origin': 'Origem',
        'volumesQty': 'Qtd Volume',
        'totalWeight': 'Peso Real Total',
        'totalCubageWeight': 'Peso Cubado Total',
        'totalValue': 'Valor Mercadoria NF',
        'volumeNumber': 'Num. Volume',
        'height': 'Altura',
        'width': 'Largura',
        'length': 'Comprimento',
      }
    }

    Reflect.deleteProperty(body, 'synced')
    Reflect.deleteProperty(body, 'delayed')

    const response = await zordonAPI.post(url, body, {
      responseType: 'blob'
    })

    const fileURL = window.URL.createObjectURL(new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }))

    const link = document.createElement('a')
    link.href = fileURL
    link.setAttribute('download', 'arquivo.xlsx')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch {
    console.log('Error when download excel')
  }
}

/**
 * 
 * @param {{xmlBase64:string}[]} fiscalDocuments 
 */
export async function fiscalDocuments (fiscalDocuments, isCte) {
  const url = `/fiscal-documents/${isCte ? 'cte' : 'nfe'}`
  const chucks = _.chunk(fiscalDocuments, 500)

  const list = []

  for (const chuck of chucks) {
    const response = await zordonAPI.post(url, { fiscalDocuments: chuck })

    list.push(...response.data)
  }

  return list
}
