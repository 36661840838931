import styled from 'styled-components'


export const ContentBoxPackage = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

