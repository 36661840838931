import { useContext } from 'react'

import { NewPickupContext } from '../providers/NewPickupProvider'

export function usePickup () {
  const {
    listPickup,
    filters,
    setFilters,
    getPickup,
  } = useContext(NewPickupContext)

  return {
    listPickup,
    filters,
    setFilters,
    getPickup
  }
}

