import { Spinner } from '../Spiner'
import { Btn } from './styles'

export function BtnCustom ({
  click,
  text,
  icon,
  color,
  backgroundColor,
  shadowNone,
  hoverColor,
  fontSize,
  border,
  align,
  padding,
  textWeight,
  width,
  loading = false,
  heigth
}) {
  return (
    <Btn
      onClick={click}
      $color={color}
      $align={align}
      $backgroundColor={backgroundColor}
      $shadowNone={shadowNone}
      $fontSize={fontSize}
      $border={border}
      $hoverColor={hoverColor}
      $padding={padding}
      $textWeight={textWeight}
      $width={width}
      $heigth={heigth}

    >
      {!loading && (
        <>
          {icon} {text}
        </>
      )}

      {loading && (
        <Spinner
          color='#fff'
          loading={true}
          size={12}
        />
      )}
    </Btn>
  )
}
