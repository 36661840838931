import { SHIPMENT_STATUS_LABEL_MAP } from '../../../../../../enums/shipment-status.enum'
import { getColorByStatus } from '../../../../../../Utils/status'

export function Statuses ({ status, quantity }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
      <div
        style={{
          backgroundColor: getColorByStatus(status),
          height: '1.125rem',
          display: 'flex',
          fontSize: '0.75rem',
          minWidth: '1.5625rem',
          alignItems: 'center',
          fontWeight: 'bold',
          marginRight: '0.375rem',
          borderRadius: '0.25rem',
          justifyContent: 'center',
        }}
      >
        <p style={{ margin: 0, color: 'white' }}>{quantity}</p>
      </div>

      <p
        style={{
          margin: 0,
          fontSize: '13px',
          color: '#606771',
          fontWeight: '600',
          width: 70
        }}
      >
        {SHIPMENT_STATUS_LABEL_MAP.get(status)}
      </p>
    </div>
  )
}
