import styled from 'styled-components'

export const Container = styled.div`
  position: fixed!important;
  top: 0;
  height: 100%;
  z-index: 9999;
  transition: ease-in all 0.2s;

  ${(props) => props.$isOpen ?
    `
        width: 100%;
        visibility: visible;
        background-color: #0000002e;
      `
    :
    `
        visibility: hidden;
        transition: ease-in all 0.2s; 
    `
  } 
`

export const Content = styled.div`
  width: ${props => props.$width || '600px'};
  height: 100%;
  background-color: ${props => props.$backgroundColor || '#f2f2f4'};
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  box-shadow: -2px 0 4px 0 #00000042;
  transition: ease-in all 0.2s;
  /* padding: 0.875rem; */

  &::-webkit-scrollbar {
    width: 5px!important;
    height: 8px!important;
  }

  &::-webkit-scrollbar-track {
    background: white!important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #44d2db!important;
  }


  ${(props) => props.$isOpen ?
    `
      visibility: visible;
    `
    :
    `
      visibility: hidden;
      transform: translateX(600px);
    `
  } 

  @media screen and (max-width: 750px){
    width: 100%
  }
`

export const ContainerTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.875rem;
  border-bottom: 1px solid rgb(194, 195, 201);
`

export const Title = styled.h5`
  font-size: ${props => props.$fontSizeTitle || '20px'};
  font-weight: 600;
  color: ${props => props.$colorTitle || '#363487'};
`

export const Footer = styled.div`
  position: sticky;
  right: 0;
  bottom: 0;
  z-index: 99999;
  width: 100%;
  border-top: 1px solid rgb(194, 195, 201);
  background-color: #f2f2f4;
`