import styled from 'styled-components'

/**
 * 
 * @param {{
 * width:string,
 * children:any,
 * style: React.CSSProperties
 * }} param0 
 * @returns 
 */
export function Grid ({
  width = '100%',
  children,
  style
}) {
  return (
    <GridContainer  $width={width} style={style}>
      {children}
    </GridContainer>
  )
}

const GridContainer = styled.div`
  width: ${props => props.$width};
`