import { NetworkStatus } from '@apollo/client'
import dayjs from 'dayjs'
import { useContext, useState } from 'react'
import { BsBoxSeam } from 'react-icons/bs'
import { CiMenuKebab } from 'react-icons/ci'
import { FaCopy } from 'react-icons/fa'
import { HiMiniArrowLongRight } from 'react-icons/hi2'
import { TbReload } from 'react-icons/tb'

import EntityItem from './components/EntityItem'
import EstimatedTimes from './components/EstimatedTimes'
import { ItemOptions } from './components/ItemOptions'
import { Statuses } from './components/Statuses'
import { CopyCode, HeaderShipments, Shipments } from './styles'
import { Pagination } from '../../../../components/Pagination'
import { ShipmentDetails } from '../../../../components/ShipmentDetails'
import { Box } from '../../../../components/UI/Box'
import { ShipmentContent } from '../../../../components/UI/Box/styles'
import { BtnCustom } from '../../../../components/UI/Btn'
import { SelectBox } from '../../../../components/UI/SelectBox'
import { Spinner } from '../../../../components/UI/Spiner'
import { Text } from '../../../../components/UI/Text'
import { OrganizationType } from '../../../../enums/organization-type.enum'
import { useShipments } from '../../../../hooks/Shipment'
import { AppContext } from '../../../../providers/AppProvider'
import { FilterContext } from '../../../../providers/Filter'
import { Col, Row } from '../../../../styled-components/style'
import { toRelativeDate } from '../../../../Utils/date'
import { getStylesByStatus } from '../../../../Utils/status'
import { ButtonItemShipment } from '../../styles'

export function SearchShipments () {
  const { organizationType } = useContext(AppContext)
  const { filters, setFilters } = useContext(FilterContext)

  const {
    queryListShipments: {
      data: listShipments,
      loading: loadingLitShipments,
      refetch: listShipmentsRefetch,
      networkStatus,
    }
  } = useShipments()

  const [shipment, setShipment] = useState()

  const [shipmentCarrierCode, setShipmentCarrierCode] = useState(null)

  const date = dayjs().format('HH:mm')

  const isRefetching = networkStatus === NetworkStatus.refetch

  return (
    <>
      <Col $gap={'0.875rem'}>
        <HeaderShipments>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <BtnCustom
              text={'ATUALIZAR'}
              icon={<TbReload />}
              color={'#1976D2'}
              backgroundColor={'transparent'}
              hoverColor={'rgba(96, 103, 113, 0.04)'}
              align={'start'}
              shadowNone
              click={async () => {
                setFilters({})
                setShipment()
                await listShipmentsRefetch({})
              }}
              width={'120px'}
            />

            <p style={{ margin: '0', fontSize: '0.75rem', color: '#606771' }}>
              atualizado às {date}
            </p>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <p style={{ margin: '0', fontSize: '16px', color: '#606771', fontWeight: '500' }} >
              Ordenar por
            </p>

            <div style={{ margin: '0', width: 180, color: '#606771' }}>
              <SelectBox
                backgroundColor='transparent'
                fontSize={'1rem'}
                selectItem={(item) => {
                  setFilters({
                    ...filters,
                    order: item
                  })
                }}
                items={[
                  {
                    id: 'DESC',
                    text: 'Mais Recente'
                  },
                  {
                    id: 'ASC',
                    text: 'Mais Antigo'
                  }
                ]}
                principalKey={'id'}
                textOption={'text'}
                value={'DESC'}
              />
            </div>
          </div>
        </HeaderShipments>

        {((loadingLitShipments && !listShipments) || isRefetching) && (
          <Spinner loading={loadingLitShipments} width={'100%'} height={'70vh'} size={20} />
        )}

        {(!loadingLitShipments && !listShipments?.shipments?.data.length) && (
          <div style={{ backgroundColor: '#fafafa', gap: 10, height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column ' }}>
            <img width={220} src="empty-list.0910f23f.svg" alt="Sem envios para listar" srcSet="" />
            <h3 style={{ color: '#363487', fontSize: '1.8rem' }}>Nenhum envio cadastrado</h3>
          </div>
        )}

        {((!loadingLitShipments || listShipments) && !isRefetching) && (
          <Shipments>
            {listShipments?.shipments?.data?.map((item) => {
              // TODO - Verificar se vou deixar essa funcionalidade, para forçar o envio sozinho.
              // if (!item.syncedAt && dayjs().diff(item.syncedAt, 'minutes') > 10) {
              //   forceResendShipment(item.id, organizationId)
              // }

              const code = item.shipperCode ||
                item.carrierCode ||
                item.trackingCode

              return (
                <div key={item.id}>
                  <Box
                    backgroundColor={item.syncedAt ? getStylesByStatus(item?.status, item?.eta).backgroundColor : '#E6E6E6'}
                    minHeight={'4.25rem'}
                    padding={'0'}
                    flexDirection="row"
                    cursor="pointer"
                    click={(e) => {
                      e.stopPropagation()
                      if (item.syncedAt) {
                        setShipment((state) =>
                          state || state?.id === item?.id ? null : item
                        )
                      }
                    }}
                    shipment={shipment}
                    id={item.id}
                  >
                    <CopyCode>
                      <FaCopy
                        color='#ababab'
                        className='copy-icon'
                        size={20}
                        onClick={(e) => {
                          e.stopPropagation()
                          navigator.clipboard.writeText(code)
                        }} />
                      <Col $gap="0.3rem" style={{ width: '105px' }}>
                        <div style={{ padding: '0 24px 0 8px' }}>
                          <>
                            <div
                              style={{
                                margin: 0,
                                fontSize: '0.875rem',
                                color: '#2F159E',
                                fontWeight: '600',
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {!item.syncedAt && (
                                <Spinner
                                  size={20}
                                  loading={true}
                                  typeLoader='ClipLoader'
                                />
                              )}
                              {item.syncedAt && (
                                <>
                                  {code}
                                </>
                              )}


                            </div>
                            <Text as="p" style={{ margin: 0, fontSize: '11px', textAlign: 'center' }}>
                              {`${toRelativeDate(item.createdAt, 'DD/MM')} ${dayjs(
                                item.createdAt
                              ).format('HH:mm')}`}
                            </Text>
                          </>
                        </div>
                      </Col>

                      <Row $gap={'0.3rem'}>
                        <EntityItem entity={item.sender || item.dispatcher} />

                        <HiMiniArrowLongRight
                          size={30}
                          color="rgb(96, 103, 113)"
                        />

                        <EntityItem entity={item.recipient || item.receiver} />
                      </Row>

                      <Row $gap={'0.8rem'}>
                        <BsBoxSeam color="rgb(64, 65, 66)" size={20} />

                        <Statuses
                          receiptModality={item?.receiptModality}
                          status={item?.status}
                          quantity={item?.volumesQuantity}
                        />
                      </Row>


                      {item.syncedAt && (
                        <EstimatedTimes
                          eta={item?.finishedAt || item?.eta}
                          etd={item?.becameReadyAt || item?.etd}
                          status={item.status}
                        />
                      )}

                      {!item.syncedAt && (
                        <Col>
                          <h3 style={{ color: '#2F159E' }}>Processando...</h3>
                        </Col>
                      )}


                      {(item.syncedAt || organizationType === OrganizationType.Admin) && (
                        <ButtonItemShipment
                          onClick={(e) => {
                            e.stopPropagation()
                            setShipmentCarrierCode(item.id)
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <CiMenuKebab size={20} />
                        </ButtonItemShipment>
                      )}

                      {(item.id === shipmentCarrierCode) && (
                        <ItemOptions
                          open={true}
                          shipmentCarrierCode={shipmentCarrierCode}
                          clear={() => setShipmentCarrierCode(null)}
                          shipment={item}
                        />
                      )}
                    </CopyCode>
                  </Box>
                  <ShipmentContent $isOpen={shipment?.id && shipment.id === item.id}>
                    {shipment?.id && shipment.id === item.id && (
                      <ShipmentDetails id={item.id} />
                    )}
                  </ShipmentContent>

                </div>
              )
            })}

            {listShipments?.shipments?.data?.length ? (
              <Pagination
                currentPage={((filters?.offset ?? 0) / 20) < 1 ? 1 : ((filters?.offset ?? 0) / 20) + 1}
                onPageChange={(page) => {
                  const aux = {
                    ...filters,
                    offset: (page - 1) * 20
                  }

                  listShipmentsRefetch(aux)

                  setFilters(aux)
                }}
                totalPages={Math.round((listShipments?.shipments?.total ?? 0) / 2)}
              />
            ) : <></>}

          </Shipments>
        )}
      </Col>
    </>
  )
}
