import styled from 'styled-components'

export const ButtonGeocoding = styled.button`
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 5px 15px;
  color: #606771;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  &:hover{
    background-color: #fafafafa;
    transition: 0.5s;
  }
`
