import { useState } from 'react'
import { MdModeEditOutline } from 'react-icons/md'
import { RiDeleteBin7Fill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { Option } from './style'
import { ModalDelete } from '../../../../components/ModalDelete'
import { usePackage } from '../../../../hooks/Packages'
import { deletePackage } from '../../../../services/packageService'

export function Options ({ id, open, clear }) {
  const navigate = useNavigate()
  const [optionsOpen, setOptionsOpen] = useState(open ? 'flex' : 'none')
  const [modalDelete, setModalDelete] = useState(false)
  const {
    listPackages: { refetch }
  } = usePackage()

  return (
    <>
      <ModalDelete
        open={modalDelete}
        onClose={() => setModalDelete(false)}
        onConfirm={async () => {
          await deletePackage(id)
          await refetch({})
          setModalDelete(false)
        }}
      />

      <div
        onClick={(e) => {
          e.stopPropagation()
          if (optionsOpen !== 'none') {
            setOptionsOpen('none')
            clear()
          }
        }}
        style={{
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 999,
          position: 'fixed',
          cursor: 'default',
        }}>
      </div>

      <Option $display={optionsOpen} onClick={(e) => e.stopPropagation()}>
        <ul>
          <li onClick={() => navigate(`/packages/${id}/edit`)}>
            <MdModeEditOutline /> Editar
          </li>
          <li onClick={() => setModalDelete(true)}>
            <RiDeleteBin7Fill /> Excluir
          </li>
        </ul>
      </Option>
    </>
  )
}