const formatErrosJoi = (erros) => {
  const messages = erros?.details.map((v) => {
    return {
      [v.path]: v.message
    }
  })

  return messages ?? null
}

export { formatErrosJoi }
