import styled from 'styled-components'

export const NavBar = styled.div`
  background-color: #ffff;
  height: 80px;
  min-width: 80rem;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`

export const NavBarContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const NavBarContent = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 80rem;
  max-width: 1323px;
  margin: 0 auto;

  div {

    h3 {
      font-size: 1rem;
    }
  }
`