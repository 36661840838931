import { clientApollo } from '../api/Apolo'
import { LIST_SHIPPING_EVENTS } from '../graphql/ShippingEvents'

/**
 * 
 * @param {string} shipperCode 
 */
export async function findShipmentByPickup (shipperCode) {
  const { data } = await clientApollo.query({
    query: LIST_SHIPPING_EVENTS,
    variables: {
      shipperCode,
    }
  })

  return data
}