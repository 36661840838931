import styled from 'styled-components'

export const ModalBackground = styled.div`
  z-index: 99999999;
  position: fixed;
  background-color: #00000080;
  height: 100vh;
  width: 100vw;
  display: ${props => props.$display || 'flex'};
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  border-radius: 5px;
`

export const ModalView = styled.div`
  width: ${props => props.$width || '0px'};
  height: ${props => props.$height || '0px'};
  z-index: ${props => props.$zIndex || 999};
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
`

export const ModalBody = styled.div`
  background-color: #ffff;
  padding: 20px;
  height: calc(100% - 20px);
`

export const ModalHeader = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #ffff;
  
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-weight: bold;
      font-size: 1.2rem;
      color: #606771;
    }
  }

  svg:last-child {
    cursor: pointer;
    width: 30px;
    height: 30px;
    transition: 0.1s;
    padding: 10px;
    color: #606771;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      width: 30px;
      height: 30px;
      transition: 0.2s;
      border-radius: 50%;
    }
  }

  svg:first-child {
    cursor: pointer;
    width: 20px;
    height: 20px;
    transition: 0.1s;
    padding: 10px;
    color: #606771;
  }
`

export const ModalFooter = styled.div`
  height: 60px;
  position: relative;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding-right: 20px;
  background-color: #fff;
`

export const ModalFooterButton = styled.button`
  height: 40px;
  border: none;
  color: #1976D2;
  cursor: pointer;
  background-color: #fff;
  font-weight: bold;
  font-size: 1rem;
  padding: 0px 10px 0px 10px;

  &:hover{
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 5px;
  }
`