
import { useState } from 'react'

import { Modal } from '../Modal'
import { Text } from '../UI/Text'

export function ModalDelete ({ open, onClose, onConfirm }) {
  const [click, setClick] = useState(false)

  return (
    <Modal
      open={open}
      onClose={onClose}
      height={150}
      title={'Excluir esta embalagem?'}
      loading={click}
      onConfirm={async (e) => {
        setClick(true)
        await onConfirm(e)
        setClick(false)
      }}
    >
      <Text as='p' fontSize='1rem'>
        Deseja mesmo remover esta embalagem? Essa operação não pode ser revertida.
      </Text>
    </Modal>
  )
}