import styled from 'styled-components'

export const Label = styled.label`
  font-size: 12px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.54);
  height: ${props => !props.$labelHeight && '12px'};
  white-space: nowrap;
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  transition: ease-in all 0.1s;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    border-bottom: ${props => props.$isValid ? 'solid 2px rgb(171 171 171)' : 'solid 2px red'};
    position: absolute;
    bottom: 0;
    transition: ease-in 0.1s;
    cursor: text;
  }

  &:hover::before {
    border-bottom: ${props => props.$isValid ? '2px solid rgba(0, 0, 0, 0.54)!important' : 'solid 2px red'};
    transition: ease-in 0.1s;
    cursor: text;
  }

  &:focus-within > ${Label} {
    color: #363487!important;
    transition: ease-in 0.1s;
  }

  &:focus-within::before {
    border-bottom:${props => props.$isValid ? '2px solid #363487!important' : 'solid 2px red'};
    transition: ease-in 0.1s;
    cursor: text;
  }
`

export const CustomInput = styled.input`
  width: ${props => props.$width || '100%'};
  height: ${props => props.$height || 'auto'};
  border: none;
  padding: 0 0.2rem  0 0;
  color: ${props => props.$color || '#606771'};
  font-size: 16px;

  &:focus {
    outline: none!important;
  }

  &::-webkit-input-placeholder { 
    color: rgb(216 216 216);
  }

  &:-moz-placeholder { 
    color: rgb(216 216 216);
    opacity:  1;
  }

  &::-moz-placeholder { 
     color: rgb(216 216 216);
     opacity:  1;
  }

  &:-ms-input-placeholder { 
     color: rgb(216 216 216);
  }

  &::-ms-input-placeholder {
     color: rgb(216 216 216);
  }

  &::placeholder {
     color: rgb(216 216 216);
  }
`