import styled from 'styled-components'

export const NotFound = styled.div` 
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  color: #666;
  font-style: italic;
`