import { gql } from '@apollo/client'

export const LIST_SHIPPING_EVENTS = gql`
  query ListShipmentsShippingEvents($shipmentId: Int, $shipperCode: String) {
    shipmentShippingEvents(shipmentId: $shipmentId, shipperCode: $shipperCode) {
      accountablePerson {
        documentNumber
        name
      }
      comments
      description
      files {
        category
        filePath
        url
      }
      geocoding {
        latitude
        longitude
      }
      id
      registeredAt
      responsible {
        type
        name
      }
      volumeCodes {
        carrierCode
        id
        trackingCode
      }
    }
  }

`

