
import { InputText } from '../../../components/UI/Inputs/InputText'
import { SelectBox } from '../../../components/UI/SelectBox'
import { useProductsTypes } from '../../../hooks/ProductsTypes'
import { Row } from '../../../styled-components/style'
import { mascaraMoeda, maskCurrency } from '../../../Utils'
import { ContentIconMinus, IconMinus } from '../../NewShipment/styles'
import { useNewPickup } from '../hooks'

export function ListVolumesPickup ({
  volume,
  index
}) {
  const { removeVolume, updateVolume } = useNewPickup()
  const { listProductsTypes: { data } } = useProductsTypes()

  return (
    <Row $gap="10px">
      <div style={{ width: 200 }}>
        <SelectBox
          width='200px'
          textLabel={'Tipo de Produto'}
          items={data?.productTypes?.data}
          principalKey={'id'}
          textOption={'name'}
          value={volume.productTypeId}
          selectItem={(id) => updateVolume({ ...volume, productTypeId: id })}
        />
      </div>
      <div>
        <InputText
          type="text"
          width='80px'
          height="33px"
          label="Peso total"
          rightItem={'kg'}
          onChange={(e) => updateVolume({ ...volume, weight: e.target.value })}
          value={volume.weight}
        />
      </div>
      <div style={{ width: 250 }}>
        <InputText
          type="text"
          height="33px"
          label="Número de série"
          onChange={(e) => updateVolume({ ...volume, serialNumber: e.target.value })}
          value={volume.serialNumber}
        />
      </div>
      <div>
        <InputText
          type="text"
          height="33px"
          label="Valor total"
          width='100px'
          onChange={(e) => updateVolume({ ...volume, value: mascaraMoeda(e.target.value) })}
          value={maskCurrency(volume.value)}
        />
      </div>

      {index !== 0 && (
        <ContentIconMinus>
          <IconMinus size={22} onClick={() => removeVolume(volume.id)} />
        </ContentIconMinus>
      )}

    </Row>
  )
}