
import { FaCheck } from 'react-icons/fa'
import { WiTime3 } from 'react-icons/wi'

import EstimatedTimeItem from './item'
import { ShipmentStatus } from '../../../../../../enums/shipment-status.enum'
import { Col } from '../../../../../../styled-components/style'
import { getStylesByStatus } from '../../../../../../Utils/status'


const EstimatedTimes = ({
  eta,
  etd,
  status = ShipmentStatus.Unknown,
}) => (
  <Col $gap={'0.8rem'} $width={'180px'}>
    <EstimatedTimeItem
      color={getStylesByStatus(status, eta).etaColor}
      date={etd}
      icon={status === 'finished' ? <FaCheck size={15} color='rgb(40, 167, 69)' /> : <WiTime3 size={20} color='#666' />}
      label="Saída"
    />
    <EstimatedTimeItem
      color={getStylesByStatus(status, etd).etdColor}
      date={eta}
      icon={status === 'finished' ? <FaCheck size={15} color='rgb(40, 167, 69)' /> : <WiTime3 size={20} color='#666' />}
      label="Prev. Entrega"
    />
  </Col>
)

export default EstimatedTimes
