import { createContext } from 'react'

import { OrganizationType } from '../enums/organization-type.enum'

export const AppContext = createContext({})

export const AppProvider = ({ children }) => {
  const ebbDashboardActiveOrganization = localStorage.getItem('EBB_DASHBOARD_ACTIVE_ORGANIZATION_SAURON')
  const ebbDashboardSessionTokens = localStorage.getItem('EBB_DASHBOARD_SESSION_TOKEN_SAURON')
  const ebbDashboardUser = localStorage.getItem('EBB_DASHBOARD_USER_SAURON')

  if (!ebbDashboardActiveOrganization || !ebbDashboardSessionTokens || !ebbDashboardUser) {
    window.location.pathname = '/'
  }

  const user = JSON.parse(ebbDashboardUser)
  const tokens = JSON.parse(ebbDashboardSessionTokens)
  const organizationId = Number(ebbDashboardActiveOrganization)

  const organization = user?.organizations?.find(e => e.id === organizationId) ?? null

  let organizationType = OrganizationType.Shipper

  if (organization) {
    const isAdmin = organization.types.includes(OrganizationType.Admin)
    const isCarrier = organization.types.includes(OrganizationType.Carrier)
    const isCartageAgent = organization.types.includes(OrganizationType.CartageAgent)
    const isShipper = organization.types.includes(OrganizationType.Shipper)

    if (isAdmin) {
      organizationType = OrganizationType.Admin
    }

    if (isShipper) {
      organizationType = OrganizationType.Shipper
    }

    if (isCarrier) {
      organizationType = OrganizationType.Carrier
    }

    if (isCartageAgent) {
      organizationType = OrganizationType.CartageAgent
    }
  }

  const enabledButtonNewShipment =
    (organization?.types?.includes(OrganizationType.Admin)
      ||
      organization?.types?.includes(OrganizationType.Shipper))

  const {
    isCarryingCompany,
    requiredShipperCode,
    allowNfeAndContentStatement,
  } = organization?.specificities || {
    isCarryingCompany: false,
    requiredShipperCode: false,
    allowNfeAndContentStatement: false,
  }

  return (
    <AppContext.Provider value={{
      organizationType,
      organization,
      organizationId,
      user,
      tokens,
      enabledButtonNewShipment,
      isCarryingCompany,
      requiredShipperCode,
      allowNfeAndContentStatement,
    }}>
      {children}
    </AppContext.Provider>
  )
}
