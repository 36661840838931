import { GoPackage } from 'react-icons/go'

import { decimalNumber } from '../../../../../helpers/mask.helper'
import { TabContent, TabRow, TextRow, TextValueRow, TitleRow } from '../../../../UI/Box/styles'

export function TabVolumesPickup ({ pickup, isVisible }) {

  return (
    <TabContent $isVisible={isVisible}>
      <TabRow>
        <div>
          <TextRow>Quantidade</TextRow>
          <TextValueRow>{pickup?.volumes?.length || 0}</TextValueRow>
        </div>
        <div>
          <TextRow>Peso</TextRow>
          <TextValueRow>{pickup?.totalWeight ? (
            `${decimalNumber(pickup.totalWeight)} Kg`
          ) : (
            '---'
          )}</TextValueRow>
        </div>
        <div>
          <TextRow>Valor declarado</TextRow>
          <TextValueRow>
            {pickup?.declaredValue ? (
              `R$ ${decimalNumber(pickup.declaredValue)}`
            ) : (
              '---'
            )}
          </TextValueRow>
        </div>
      </TabRow>

      <TabRow $columns={'1fr'} $padding={'20px 0px 0px 20px'}>
        <TitleRow>Volumes</TitleRow>
      </TabRow>

      {pickup?.volumes?.map((volume, index) => {
        return (
          <TabRow key={volume.id} style={{ fontSize: 12 }} $columns={'50px 1fr 1fr 1fr 1fr 1fr'}>
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <GoPackage size={30} color='rgba(47, 21, 158, 0.54)' />
              <TextRow>{`${volume.sequentialNumber || index + 1}`}</TextRow>
            </div>
            <div>
              <TextRow>Descrição</TextRow>
              <TextValueRow>{volume.description || '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Código</TextRow>
              <TextValueRow>{volume.code || '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Serial</TextRow>
              <TextValueRow>{volume.seriaNumber || '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Peso (Kg)</TextRow>
              <TextValueRow>{volume.weight ? decimalNumber(volume.weight) : '---'}</TextValueRow>
            </div>
          </TabRow>
        )
      })}
    </TabContent>
  )
}
