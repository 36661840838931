import { gql } from '@apollo/client'

export const LIST_PICKUP = gql`
  query ListPickups(
    $first: Int
    $offset: Int
    $order: ListOrder
    $filter: PickupsFilter
  ) {
    pickups(first: $first, offset: $offset, order: $order, filter: $filter) {
      data {
        carrierCode
        carrierParentEntity {
            id
            name
            displayName
            documentNumber
            address {
                location
                number
                street
                cep
                state
            }
        }
        closeTime
        collectedAt
        comments
        contactName
        cutoffDate
        createdAt
        declaredValue
        id
        numberOfVolumes
        organizationId
        originShipperEntity {
           id
           name
           displayName
           documentNumber
           address {
               location
               number
               street
               cep
               state
           }
        }
        pickupAgentParentEntity{
           id
           name
           displayName
           documentNumber
           address {
               location
               number
               street
               cep
               state
           }
        }
        pickupDate
        pickupPoint
        readyTime
        reference
        requesterName
        shipments {
           id
        }
        shipperCode
        syncedAt
        totalWeight
        volumes {
           id
           code
           productType{
               id
               name
               organizationId
           }
           height
           length
           serialNumber
           shipperCode
           weight
           value
        }
      }
      total
    }
  }

`

export const GET_PICKUP = gql`
  query Pickup($id: Int!) {
    pickup(id: $id) {
      carrierCode
      carrierParentEntity {
          id
          name
          displayName
          documentNumber
          address {
              location
              number
              street
              cep
              state
          }
      }
      closeTime
      collectedAt
      comments
      contactName
      cutoffDate
      declaredValue
      id
      numberOfVolumes
      organizationId
      originShipperEntity {
         id
         name
         displayName
         documentNumber
         address {
             location
             number
             street
             cep
             state
         }
      }
      pickupAgentParentEntity{
         id
         name
         displayName
         documentNumber
         address {
             location
             number
             street
             cep
             state
         }
      }
      pickupDate
      pickupPoint
      readyTime
      reference
      requesterName
      shipments {
         id
      }
      shipperCode
      syncedAt
      totalWeight
      volumes {
         id
         code
         productType{
             id
             name
             organizationId
         }
         height
         length
         serialNumber
         shipperCode
         weight
         value
      }
    }
  }
`