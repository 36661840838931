import { ImSearch } from 'react-icons/im'

import { Content, CustomInput, Label } from './styles'

export function InputText ({
  type = 'text',
  placeholder,
  iconSearch,
  color = '#606771',
  height = '40px',
  label,
  leftItem,
  rightItem,
  value,
  onChange,
  width = '100%',
  isValid = true,
  limit = null,
  required,
  disabled
}) {
  return (
    <Content $isValid={isValid}>
      {!placeholder && <Label $labelHeight={label}>{label}</Label>}

      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        {rightItem && (
          <p style={{ fontSize: '1rem', margin: 0, color }}>{rightItem}</p>
        )}

        <CustomInput
          type={type}
          name=""
          id="input-date"
          placeholder={placeholder}
          $color={color}
          $height={height}
          value={value}
          onChange={onChange}
          $width={width}
          maxLength={limit || Infinity}
          required={required}
          disabled={disabled}
        />

        {iconSearch && !leftItem && !rightItem && (
          <ImSearch
            style={{ width: 20, height: 20, marginRight: 5 }}
            color="rgb(136 136 136)"
          />
        )}

        {leftItem && <p style={{ fontSize: '1rem', margin: 0, color }}>{leftItem}</p>}
      </div>
    </Content>
  )
}
