import Joi from 'joi'

import { zordonAPI } from '../api/Axios'
import { formatErrosJoi } from '../Utils/formatMessageJoi'

/**
 * @param {{
 *    height: number,
 *    length: number,
 *    name: string,
 *    width: number
 *  }} body 
 */
export async function createPackage (body) {
  const url = '/packages'

  const error = validate(body)

  if (error) {
    return error
  }

  Reflect.deleteProperty(body, 'id')
  await zordonAPI.post(url, body)
}

/**
 * 
 * @param {number} id 
 * @param {{
 *    height: number,
*    length: number,
*    name: string,
*    width: number
*  }} body 
 * @returns 
 */
export async function updatePackage (id, body) {
  const url = `/packages/${id}`

  validate(body)

  const response = await zordonAPI.patch(url, body)

  if (response.status === 204) {
    return true
  }

  return false
}

export async function deletePackage (id) {
  if (!id) {
    throw new Error('id not found')
  }

  const url = `/packages/${id}`
  await zordonAPI.delete(url)
}

function validate (body) {
  const validation = Joi.object({
    height: Joi.number().messages({
      '*': 'Campo altura deve ser um valor válido',
    }),
    length: Joi.number().messages({
      '*': 'Campo largura deve ser um valor válido'
    }),
    name: Joi.string().messages({
      '*': 'Campo descrição deve ser um valor válido'
    }),
    width: Joi.number().messages({
      '*': 'Campo comprimento deve ser um valor válido'
    }),
  })

  const { error } = validation.validate(body, {
    abortEarly: false,
    allowUnknown: true
  })

  if (error?.details?.length > 0) {
    const message = formatErrosJoi(error)
    return message
  }

  return null
}