import styled from 'styled-components'

export const Btn = styled.button`
  width: ${props=> props.$width || '100%'};
  height: ${props=> props.$height || '100%'};
  height: 100%;
  display: flex;
  justify-content: ${props => props.$align || 'center'};
  align-items: center;
  gap: 0.875rem;
  background-color: ${props => props.$backgroundColor || '#1976D2'};
  border: ${props => props.$border || 'none'};
  font-weight:  ${props => props.$textWeight || 'bold'};
  font-size: ${props => props.$fontSize || '0.875rem !important'}; ;
  color: ${props => props.$color || 'white'};
  border-radius: 5px;
  cursor: pointer;
  transition: ease-in all 0.1s;
  padding: ${props => props.$padding || '0.6rem 0.5rem'};
 
  &:hover {
    background-color: ${props => props.$hoverColor || 'rgb(41, 144, 91)'};
    transition: ease-in all 0.1s;
    box-shadow: ${props => props.$shadowNone ? 'none' : '0 2px 8px 0 rgba(0, 0, 0, 0.343)'} ;
  }
`