import dayjs from 'dayjs'
import { useState } from 'react'
import { FaCopy } from 'react-icons/fa'
import { HiMiniArrowLongRight } from 'react-icons/hi2'
import { TbReload } from 'react-icons/tb'

import { EntityItemPickup } from './components/EntityItemPickup'
import { CopyCodePickup, HeaderShipments, Pickups } from './styles'
import { Pagination } from '../../../../components/Pagination'
import { PickupDetails } from '../../../../components/PickupDetails'
import { Box } from '../../../../components/UI/Box'
import { PickupContent } from '../../../../components/UI/Box/styles'
import { BtnCustom } from '../../../../components/UI/Btn'
import { SelectBox } from '../../../../components/UI/SelectBox'
import { Spinner } from '../../../../components/UI/Spiner'
import { usePickup } from '../../../../hooks/Pickup'
import { Col, Row } from '../../../../styled-components/style'
import { toRelativeDate } from '../../../../Utils/date'
import { getStylesByStatus } from '../../../../Utils/status'

export function ListPickup () {
  const {
    listPickup: { data, loading, refetch },
    filters, setFilters
  } = usePickup()

  const [pickup, setPickup] = useState()

  const date = dayjs().format('HH:mm')

  return (
    <>
      <Col $gap={'0.875rem'}>
        <HeaderShipments>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <BtnCustom
              text={'ATUALIZAR'}
              icon={<TbReload />}
              color={'#1976D2'}
              backgroundColor={'transparent'}
              hoverColor={'rgba(96, 103, 113, 0.04)'}
              align={'start'}
              shadowNone
              click={async () => {
                await refetch(filters)
              }}
              width={'120px'}
            />

            <p style={{ margin: '0', fontSize: '0.75rem', color: '#606771' }}>
              atualizado às {date}
            </p>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <p style={{ margin: '0', fontSize: '16px', color: '#606771', fontWeight: '500' }} >
              Ordenar por
            </p>

            <div style={{ margin: '0', width: 180, color: '#606771' }}>
              <SelectBox
                backgroundColor='transparent'
                fontSize={'1rem'}
                items={[
                  {
                    id: 'DESC',
                    text: 'Mais Recente'
                  },
                  {
                    id: 'ASC',
                    text: 'Mais Antigo'
                  }
                ]}
                principalKey={'id'}
                textOption={'text'}
                value={'DESC'}
              />
            </div>
          </div>

        </HeaderShipments>

        {loading && !data && (
          <Spinner loading={loading} width={'100%'} height={'70vh'} />
        )}

        {(!loading && !data?.pickups?.data.length) && (
          <div style={{ backgroundColor: '#fafafa', gap: 10, height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column ' }}>
            <img width={220} src="empty-list.0910f23f.svg" alt="Sem envios para listar" srcSet="" />
            <h3 style={{ color: '#363487', fontSize: '1.8rem' }}> </h3>
          </div>
        )}

        <Pickups>
          {data?.pickups?.data?.map((item) => {
            const code = item.shipperCode || item.carrierCode || item.trackingCode

            if (!item?.carrierParentEntity || !item?.originShipperEntity) {
              return <></>
            }

            return (
              <div key={item.id}>
                <Box
                  backgroundColor={item.syncedAt ? getStylesByStatus(item?.status, item?.eta).backgroundColor : '#E6E6E6'}
                  minHeight={'4.25rem'}
                  padding={'0'}
                  flexDirection="row"
                  cursor="pointer"
                  id={item.id}
                  click={(e) => {
                    e.stopPropagation()
                    if (item.syncedAt) {
                      setPickup((state) =>
                        state || state?.id === item?.id ? null : item
                      )
                    }
                  }}
                >
                  <CopyCodePickup>
                    <FaCopy
                      color='#ababab'
                      className='copy-icon'
                      size={20}
                      onClick={(e) => {
                        e.stopPropagation()
                        navigator.clipboard.writeText(code)
                      }} />
                    <Col $gap="0.3rem">
                      <div style={{ padding: '0 24px 0 8px' }}>
                        <>
                          <div
                            style={{
                              margin: 0,
                              fontSize: '0.875rem',
                              color: '#2F159E',
                              fontWeight: '600',
                              textAlign: 'center',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {!item.syncedAt && (
                              <Spinner
                                size={20}
                                loading={true}
                              />
                            )}
                            
                            {item.syncedAt && (
                              <>
                                {code}
                              </>
                            )}


                          </div>
                          <p
                            style={{
                              margin: 0,
                              fontSize: '11px',
                              textAlign: 'center',
                            }}
                          >
                            {`${toRelativeDate(item.createdAt, 'DD/MM')} ${dayjs(
                              item.createdAt
                            ).format('HH:mm')}`}
                          </p>
                        </>
                      </div>
                    </Col>

                    <Row $gap={'0.3rem'}>
                      <EntityItemPickup entity={item?.carrierParentEntity} />

                      <HiMiniArrowLongRight
                        size={30}
                        color="rgb(96, 103, 113)"
                      />

                      <EntityItemPickup entity={item?.originShipperEntity} />
                    </Row>


                    {!item.syncedAt && (
                      <Col>
                        <h3 style={{ color: '#2F159E' }}>Processando...</h3>
                      </Col>
                    )}
                  </CopyCodePickup>
                </Box>

                <PickupContent $isOpen={pickup?.id && pickup.id === item.id}>
                  {pickup?.id && pickup.id === item.id && (
                    <PickupDetails id={item.id} />
                  )}
                </PickupContent>
              </div>
            )
          })}

          {data?.pickups?.data?.length ? (
            <Pagination
              currentPage={((filters?.offset ?? 0) / 20) < 1 ? 1 : ((filters?.offset ?? 0) / 20) + 1}
              onPageChange={(page) => {
                const aux = {
                  ...filters,
                  offset: (page - 1) * 20
                }

                refetch(aux)

                setFilters(aux)
              }}
              totalPages={Math.round((data?.pickups?.total / 20))}
            />
          ) : <></>}
        </Pickups>
      </Col>
    </>
  )
}
