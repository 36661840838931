import { CiFaceFrown } from 'react-icons/ci'

import { Modal } from '../Modal'
import { Grid } from '../UI/Grid'
import { Text } from '../UI/Text'

export function ModalError ({ message, title, open, onClose }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      height={150}
      hiddenFooter={true}
    >
      <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
        <Grid width='150px'>
          <CiFaceFrown color='rgb(54, 52, 135)' size={80} />
        </Grid>
        <Grid style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          <Text as='h2' fontSize='1.5rem' fontWeight='bold'>{title || 'Opa! Aconteceu um erro'}</Text>
          <Text as='p' fontWeight='500'>
            {message || 'Aconteceu uma falha interna, por favor tente novamente em instantes, caso persista, nos comunique'}
          </Text>
        </Grid>
      </Grid>
    </Modal>
  )
}