import { Col } from '../../../../../../styled-components/style'
import { capitalizeFirstLetter } from '../../../../../../Utils'

const EntityItem = ({ entity }) => {
  const entityName = entity?.displayName || entity?.name

  const locationName = entity?.address
    ? `${capitalizeFirstLetter(
      entity?.address?.location
    )}, ${entity?.address?.state.toUpperCase()}`
    : '---'

  return (
    <Col
      $gap="0.3rem"
      style={{
        width: '180px',
        flexBasis: '180px',
        flexShrink: '0',
      }}
    >
      <p
        style={{
          margin: 0,
          fontSize: '0.875rem',
          color: '#606771',
          fontWeight: '600',
          textTransform: 'uppercase',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {entityName}
      </p>
      <p style={{ margin: 0, fontSize: '0.75rem' }}>{locationName}</p>
    </Col>
  )
}

export default EntityItem
