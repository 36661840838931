import { useLazyQuery, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useContext } from 'react'

import {
  GET_DOCUMENTS_SHIPMENT,
  GET_SHIPMENT,
  LIST_SHIPMENTS,
} from '../graphql/shipments'
import { FilterContext } from '../providers/Filter'

export function useShipments () {
  const { filters } = useContext(FilterContext)

  const queryListShipments = useQuery(LIST_SHIPMENTS, {
    variables: buildFilter(filters),
    notifyOnNetworkStatusChange: true,
    pollInterval: 20000,
  })

  const queryShipment = useLazyQuery(GET_SHIPMENT)

  const queryDocumentsShipment = useLazyQuery(GET_DOCUMENTS_SHIPMENT)

  const openNewShipment = () => { }

  return {
    openNewShipment,
    queryListShipments,
    queryShipment,
    queryDocumentsShipment
  }
}

const buildFilter = (filter) => {
  return {
    filter: clearObjetctFilter({
      createdAtEnd: '',
      createdAtStart: '',
      destinationEntityLocation: '',
      documentIdentifier: '',
      originEntityLocation: '',
      recipientIds: [],
      senderIds: [],
      shipmentCode: '',
      delayed: null,
      synced: null,
      statuses: [
        'finished',
        'inTransit',
        'ready',
        'toWithdrawal'
      ],
      ...(filter?.filter ?? {})
    }),
    first: filter?.first ?? 20,
    offset: filter?.offset ?? 0,
    order: filter?.order ?? 'DESC'
  }
}

const clearObjetctFilter = (filter) => {
  return _.omitBy(filter, value =>
    value === null ||
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && !Array.isArray(value) && _.isEmpty(value))
  )
}