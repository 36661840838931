import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ContainerLogin, FormLogin, HeaderLogin } from './style'
import { ModalSuccess } from '../../components/ModalSuccess'
import { BtnCustom } from '../../components/UI/Btn'
import { InputText } from '../../components/UI/Inputs/InputText'
import { Text } from '../../components/UI/Text'
import { forgotPassword } from '../../services/gandalfService'

export function ForgotPasswordPage () {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const [state, setState] = useState({
    email: '',
  })

  async function resetPassword () {
    setLoading(true)
    await forgotPassword(state.email)
  }

  return (
    <>
      <ModalSuccess
        open={loading}
        message={'Enviamos um e-mail para você trocar sua senha'}
        title={'E-mail enviado'}
        onClose={() => {
          setLoading(false)
          setTimeout(() => {
            navigate('/')
          }, 1000)
        }}
      />
      <ContainerLogin>
        <HeaderLogin>
          <div style={{ position: 'fixed', top: 10, left: 10 }}>
            <img src="/logo.svg" alt="" />
          </div>
          <FormLogin $height={'250px'}>
            <div style={{ padding: 20, heigth: '100%', width: 350, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: 20 }}>
              <Text as='h1' fontSize='1.5rem'>Esqueci minha senha</Text>
              <InputText
                type='text'
                label={'E-mail'}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                value={state.email}
              />

              <BtnCustom
                heigth={'70px'}
                text={'Enviar'}
                backgroundColor={'#363487'}
                hoverColor={'#3b3a7a'}
                loading={loading}
                click={() => resetPassword()}
              />

              <BtnCustom
                heigth={'70px'}
                text={'Voltar'}
                click={() => navigate('/')}
                backgroundColor={'transparent'}
                color={'#3936c7'}
                hoverColor={'transparent'}
              />

            </div>
          </FormLogin>
        </HeaderLogin>
      </ContainerLogin>
    </>
  )
}