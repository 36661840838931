
import { InputMask } from '@react-input/mask'
import { useContext, useEffect, useState } from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { IoPersonSharp } from 'react-icons/io5'
import styled from 'styled-components'

import { zordonAPI } from '../../api/Axios'
import { OffCanvasEntityContext } from '../../providers/OffCanvasProvider'
import { Box } from '../UI/Box'
import { Grid } from '../UI/Grid'
import { InputText } from '../UI/Inputs/InputText'
import { Text } from '../UI/Text'

export const FormContainer = styled.div`
  margin: auto;
  border-radius: 8px;
  color: #666;
`

export const Section = styled.div`
  margin-bottom: 20px;
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
`

export const Input = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`

export const RadioGroup = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
`

export const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  ${({ primary }) => primary ? `
    background-color: #1976d2;
    color: #fff;
  ` : `
    background-color: #ccc;
    color: #333;
  `}
`

export const FormEntity = () => {
  const { formData, setFormData, erros } = useContext(OffCanvasEntityContext)

  const [cepIsInvalid, setCepIsInvalid] = useState(false)

  const handleInputChange = (key) => {
    return (e) => {
      const { value, type, checked } = e.target
      setFormData({
        ...formData,
        [key]: type === 'checkbox' ? checked : value
      })
    }
  }

  /**
   * @param {string} cep 
   */
  async function searchCep (cep) {
    const _cep = cep.replace('-', '')

    setFormData({
      ...formData,
      cep: cep,
    })

    if (_cep.length < 8) {
      return
    }


    try {
      const { data } = await zordonAPI.get(`/addresses/${cep.replace('-', '')}`)

      setFormData({
        ...formData,
        cep: cep,
        city: data.location.name,
        district: data?.street?.name,
        stateAcronym: data.stateAcronym,
        street: data?.neighborhood?.name,
      })

      setCepIsInvalid(false)
    } catch {
      setCepIsInvalid(true)
    }
  }

  useEffect(() => {
    if (formData.isento)
      setFormData({
        ...formData,
        stateRegistration: ''
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.isento])

  return (
    <FormContainer>
      <Box shadow={true} padding='25px' style={{ borderRadius: 5 }}>
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', marginBottom: 20 }}>
          <IoPersonSharp size={25} color='rgba(0, 0, 0, 0.54)' />
          <Text as='h1' fontSize='1.5rem'>
            Identificação
          </Text>
        </Grid>

        <InputGroup>
          <Label>Apelido *</Label>
          <InputText type="text" value={formData.displayName} isValid={!erros?.displayName} onChange={handleInputChange('displayName')} />
          {erros?.displayName && (<Text as='span' color='red' fontSize='0.7rem'>{erros?.displayName}</Text>)}
        </InputGroup>

        <InputGroup>
          <Label>Nome *</Label>
          <InputText type="text" value={formData.name} isValid={!erros?.name} onChange={handleInputChange('name')} />
          {erros?.name && (<Text as='span' color='red' fontSize='0.7rem'>{erros?.name}</Text>)}
        </InputGroup>

        <RadioGroup>
          <Label>Pessoa:</Label>
          <label>
            <input type="radio" value="juridica" checked={formData.corporation === 'juridica'} onChange={handleInputChange('corporation')} /> Jurídica
          </label>
          <label>
            <input type="radio" value="fisica" checked={formData.corporation === 'fisica'} onChange={handleInputChange('corporation')} /> Física
          </label>
        </RadioGroup>

        <Grid style={{ display: 'flex', gap: 20 }}>
          <Grid>
            <Label>{formData.corporation === 'juridica' ? 'CNPJ *' : 'CPF *'}</Label>
            <Grid style={{ display: 'flex', flexDirection: 'column', height: '100%', paddingTop: 10 }}>
              <InputMask
                type="text"
                value={formData.documentNumber || ''}
                onChange={handleInputChange('documentNumber')}
                mask={formData.corporation === 'juridica' ? '__.___.___/____-__' : '___.___.___-__'}
                replacement={{ _: /\d/ }}
                style={{
                  width: '100%',
                  height: 40,
                  border: 'none',
                  padding: '0 0.2rem 0 0',
                  color: '#606771',
                  fontSize: '16px',
                  outline: 'none',
                  borderBottom: erros?.documentNumber ? '2px solid red' : '2px solid #868b92',
                }}
              />
              {erros?.documentNumber && (<Text as='span' color='red' fontSize='0.7rem'>{erros?.documentNumber}</Text>)}
            </Grid>

          </Grid>

          {formData.corporation === 'juridica' && (
            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid>
                <Label>Inscrição Estadual *</Label>
                <InputText disabled={formData.isento} type="text" value={formData.stateRegistration} onChange={handleInputChange('stateRegistration')} />
              </Grid>
              <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }} width='100px'>
                <input type="checkbox" id='isento' value={formData.isento} checked={formData.isento} onChange={handleInputChange('isento')} />
                <Label htmlFor='isento'>Isento</Label>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid style={{ display: 'flex', justifyContent: 'space-between', gap: 10, marginTop: 15 }}>
          <Grid>
            <Label>Email</Label>
            <InputText type="email" name="email" value={formData.email} onChange={handleInputChange('email')} />
          </Grid>

          <Grid>
            <Label>Telefone</Label>
            <Grid style={{ display: 'flex', flexDirection: 'column', height: '100%', paddingTop: 10 }}>
              <InputMask
                type="text"
                value={formData.phone}
                mask={'(__) _ ____-____'}
                replacement={{ _: /\d/ }}
                style={{
                  width: '100%',
                  height: 40,
                  border: 'none',
                  padding: '0 0.2rem 0 0',
                  color: '#606771',
                  fontSize: '16px',
                  outline: 'none',
                  borderBottom: '2px solid #868b92',
                }}
                onChange={handleInputChange('phone')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: 10 }}>
          <Label>Código do Cliente</Label>
          <InputText
            type="text"
            name="codigoCliente"
            value={formData.codeClient}
            onChange={handleInputChange('codeClient')}
          />
          <Text as='span' fontSize='0.7rem'>Identificação desse participante no seu sistema</Text>
        </Grid>
      </Box>
      <br />
      <Box shadow={true} padding='25px' style={{ borderRadius: 5 }}>
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', marginBottom: 20 }}>
          <FaLocationDot size={25} color='rgba(0, 0, 0, 0.54)' />
          <Text as='h1' fontSize='1.5rem'>
            Endereço
          </Text>
        </Grid>
        <InputGroup>
          <Label>CEP *</Label>
          <InputMask
            type="text"
            mask="_____-___"
            replacement={{ _: /\d/ }}
            style={{
              width: '100%',
              height: 40,
              border: 'none',
              padding: '0 0.2rem 0 0',
              color: '#606771',
              fontSize: '16px',
              borderBottom: erros?.cep ? '1px solid red' : '2px solid #606771',
              outline: 'none'
            }}
            value={formData.cep}
            onChange={(e) => searchCep(e.target.value)}
          />
          {cepIsInvalid && <Text as='span' color='red' fontSize='0.7rem'>CEP Inválido</Text>}
          {erros?.cep && (<Text as='span' color='red' fontSize='0.7rem'>{erros?.cep}</Text>)}
        </InputGroup>

        {(formData.street && formData.cep) && (
          <>
            <Grid style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}>
              <Grid>
                <InputGroup>
                  <Label>Logradouro *</Label>
                  <InputText type="text" value={formData.street} onChange={handleInputChange('street')} />
                </InputGroup>
              </Grid>

              <Grid>
                <InputGroup>
                  <Label>Número *</Label>
                  <InputText type="text" value={formData.number} onChange={handleInputChange('number')} />
                  {erros?.number && (<Text as='span' color='red' fontSize='0.7rem'>{erros?.number}</Text>)}
                </InputGroup>
              </Grid>
            </Grid>

            <Grid style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}>
              <Grid>
                <InputGroup>
                  <Label>Complemento</Label>
                  <InputText type="text" name="complemento" value={formData.complement} onChange={handleInputChange('complement')} />
                </InputGroup>

              </Grid>
              <Grid>
                <InputGroup>
                  <Label>Bairro *</Label>
                  <InputText type="text" name="bairro" value={formData.district} onChange={handleInputChange('district')} />
                </InputGroup>
              </Grid>

            </Grid>
            <Grid style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}>
              <Grid>
                <InputGroup>
                  <Label>Cidade *</Label>
                  <InputText type="text" name="cidade" value={formData.city} onChange={handleInputChange('city')} />
                </InputGroup>
              </Grid>

              <Grid>
                <InputGroup>
                  <Label>UF *</Label>
                  <InputText type="text" value={formData.stateAcronym} onChange={handleInputChange('stateAcronym')} />
                </InputGroup>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </FormContainer>
  )
}

