import { useState } from 'react'
import { IoMdPrint } from 'react-icons/io'
import { LuFileSpreadsheet } from 'react-icons/lu'

import { ButtonPrint, ContainerEtiqueta } from './style'
import { Modal } from '../../../../../../components/Modal'
import { Spinner } from '../../../../../../components/UI/Spiner'
import { Text } from '../../../../../../components/UI/Text'
import { printEtiqueta } from '../../../../../../services/shipmentService'

export function PrintEtiqueta ({
  open,
  onClose,
  id,
}) {
  const [isSending, setIsSending] = useState(false)

  async function handlePrint (e) {
    try {
      e.stopPropagation()
      setIsSending(true)
      await printEtiqueta(id, 'a4')
      setIsSending(false)
    } catch {
      setIsSending(false)
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={'Qual será o formato das etiquetas?'}
      textClose='CANCELAR'
      textConfirm='DOWNLOAD'
      height={150}
      hiddenFooter={true}
      onConfirm={(e) => e.stopPropagation()}
      loading={isSending}
    >
      {isSending && (
        <>
          <Text>Aguarde um momento...</Text>
          <Spinner width={'100%'} height={'100%'} loading={isSending} />
        </>
      )}

      {!isSending && (
        <div style={{ marginLeft: 10, color: '#666' }}>
          <ContainerEtiqueta>
            <div>
              <LuFileSpreadsheet size={20} />
              <p> Papel A4</p>
            </div>
            <ButtonPrint onClick={async (e) => handlePrint(e)}>
              Imprimir
            </ButtonPrint>
          </ContainerEtiqueta>
          <br />
          <ContainerEtiqueta >
            <div>
              <IoMdPrint size={20} />
              <p> Impressora térmica</p>
            </div>
            <ButtonPrint onClick={async (e) => {
              e.stopPropagation()
              setIsSending(true)
              await printEtiqueta(id, 'raw')
              setIsSending(false)
            }}>
              Imprimir
            </ButtonPrint>
          </ContainerEtiqueta>
        </div>
      )}
    </Modal>
  )
}