
import { useEffect, useState } from 'react'
import { CiMenuKebab } from 'react-icons/ci'
import { PiPackageFill } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

import { Options } from './components/Options'
import { ContainerListPackages, ContentPackage, ListPackages, NotFoundPackages } from './style'
import { Box } from '../../components/UI/Box'
import { Text } from '../../components/UI/Text'
import { usePackage } from '../../hooks/Packages'
import { ContainerPage } from '../../styled-components/style'

export function Packages () {
  const navigate = useNavigate()
  const {
    listPackages: { data, loading }
  } = usePackage()

  const [id, setId] = useState(null)

  useEffect(() => {
    document.title = 'Dashboard | Embalagens'
  }, [])

  return (
    <>
      <ContainerPage>
        <Box backgroundColor='transparent' alignItems='flex-start' width='100%' flexDirection='column' height='70px' shadow={false}>
          <Text color='#363487' fontSize='2rem' as='h3' fontWeight='bold'>Embalagens</Text>
          <Text color='#666'>0 resultados</Text>
        </Box>

        <ContentPackage>
          <Box containerWidth={'300px'} shadow={false} width='300px' backgroundColor={'transparent'}>
            <button className='btn-new' onClick={() => navigate('/packages/new')}>+ NOVA</button>
          </Box>

          {(!loading && data?.packages?.length > 0) && (
            <ListPackages>
              {data?.packages?.map((item) => {
                return (
                  <ContainerListPackages key={item.id}>
                    <Box
                      backgroundColor='#fafafa'
                      justifyContent='flex-start'
                      alignItems='center'
                      shadow={false}
                      height='70px'
                      flexDirection='row'
                      style={{
                        border: '1px solid #C2C3C9',
                        borderBottom: 'none',
                      }}
                    >
                      <Box flexDirection='column' width='350px' padding='0px 0px 10px 0px' shadow={false} backgroundColor='transparent'>
                        <Text fontSize='0.8rem' as='p'>Descrição</Text>
                        <Text as='span' fontWeight='700' fontSize='0.8rem'>{item.name}</Text>
                      </Box>
                      <Box flexDirection='column' padding='0px 0px 10px 0px' shadow={false} backgroundColor='transparent'>
                        <Text as='p' fontSize='0.8rem'>Comprimento</Text>
                        <Text as='span' fontWeight='700' fontSize='0.8rem'>{item.length}cm</Text>
                      </Box>
                      <Box flexDirection='column' padding='0px 0px 10px 0px' shadow={false} backgroundColor='transparent'>
                        <Text as='p' fontSize='0.8rem'>Largura</Text>
                        <Text as='span' fontWeight='700' fontSize='0.8rem'>{item.width} cm</Text>
                      </Box>
                      <Box flexDirection='column' padding='0px 0px 10px 0px' shadow={false} backgroundColor='transparent'>
                        <Text as='p' fontSize='0.8rem'>Altura</Text>
                        <Text as='span' fontWeight='700' fontSize='0.8rem'>{item.height} cm</Text>
                      </Box>
                      <Box
                        flexDirection='column'
                        gap='10px'
                        padding='0px 0px 10px 0px'
                        shadow={false}
                        cursor='pointer'
                        alignItems='center'
                        justifyContent='center'
                        backgroundColor='transparent'
                        click={() => setId(item.id)}
                        hove
                      >
                        <CiMenuKebab size={20} />
                      </Box>

                    </Box>

                    {id === item.id && (
                      <Options
                        id={item.id}
                        open={true}
                        clear={() => setId(null)}
                      />
                    )}
                  </ContainerListPackages>
                )
              })}
            </ListPackages>
          )}

          {(loading || data?.packages?.length === 0) && (
            <NotFoundPackages>
              <div>
                <PiPackageFill color='#363487' size={100} />
                <h1>Nenhuma embalagem cadastrada</h1>
                <button onClick={() => navigate('/packages/new')}>Clique aqui para cadastrar</button>
              </div>
            </NotFoundPackages>
          )}


        </ContentPackage>
      </ContainerPage>
    </>
  )
}