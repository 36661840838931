import dayjs from 'dayjs'

import { TabRow, TextRow, TextValueRow, TitleRow } from '../../../../../UI/Box/styles'

export function ShipmentCTE ({ cte }) {
  return (
    <>
      <TabRow $columns={'1fr'} $padding={'20px 0px 0px 20px'}>
        <TitleRow>CT-e (Conhecimento de Transporte Eletrônico)</TitleRow>
      </TabRow>

      <TabRow $columns={'2fr 1fr 1fr 1fr'}>
        <div>
          <TextRow>Chave</TextRow>
          <TextValueRow>{cte?.key}</TextValueRow>
        </div>
        <div>
          <TextRow>Número</TextRow>
          <TextValueRow>{cte?.number || '---'}</TextValueRow>
        </div>
        <div>
          <TextRow>Série</TextRow>
          <TextValueRow>{cte?.serie != null ? cte.serie : '---'}</TextValueRow>
        </div>
        <div>
          <TextRow>Data Emissão</TextRow>
          <TextValueRow>{dayjs(cte?.issueDate).format('DD/MM/YYYY HH:mm')}</TextValueRow>
        </div>

        {cte?.auxiliaryDocument && (
          <>
            <div>
              <a href={cte?.auxiliaryDocument.url}>Ver DACTe</a>
            </div>
          </>
        )}
      </TabRow>
    </>
  )
}