import { clientApollo } from '../api/Apolo'
import { LIST_ENTITES_AND_ASSOCIATIONS } from '../graphql/entities'

export async function listEntities (organizationId) {
  const { data } = await clientApollo.query({
    query: LIST_ENTITES_AND_ASSOCIATIONS,
    variables: {
      filter: {
        disposable: false,
        organizationIds: [organizationId],
        parentCompany: true,
      }
    }
  })

  return data
}