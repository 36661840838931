import { createContext, useContext, useState } from 'react'

import { AppContext } from './AppProvider'
import { zordonAPI } from '../api/Axios'


export const OffCanvasEntityContext = createContext({})

export const OffCanvasEntityProvider = ({ children }) => {
  const { organizationId } = useContext(AppContext)
  const [erros, setErros] = useState({})

  const [formData, setFormData] = useState({
    cep: '',
    city: '',
    codeClient: '',
    complement: '',
    corporation: 'juridica',
    displayName: '',
    district: '',
    documentNumber: '',
    email: '',
    isento: false,
    name: '',
    number: '',
    phone: '',
    stateRegistration: '',
    street: '',
    stateAcronym: '',
  })

  async function createEntity (body) {
    await validate()

    const entity = {
      address: {
        cep: body.cep,
        complement: body.complement,
        location: body.city,
        neighborhood: body.district,
        number: body.number,
        state: body.stateAcronym,
        street: body.street
      },
      corporation: body.corporation === 'juridica',
      displayName: body.displayName,
      documentNumber: body.documentNumber.replace('/', '').replace(/\./ig, '').replace('-', ''),
      email: body.email,
      code: body.codeClient,
      name: body.name,
      organizationId,
      phone: body.phone.replace('(', '').replace(')', '').replace(/ /ig, '').replace('-', ''),
      shippingSite: false
    }

    await zordonAPI.post('/entities', entity)
  }

  async function validate () {
    const fields = ['displayName', 'name', 'documentNumber', 'cep', 'number']
    const auxErros = new Map(Object.entries(erros))

    if (fields.length === 0) return

    for (const field of fields) {
      const value = formData[field]

      if (!value) {
        auxErros.set(field, 'Campo deve ser preenchido')
      } else {
        auxErros.delete(field)
      }
    }

    if (auxErros.size > 0) {
      setErros(Object.fromEntries(auxErros))
      throw new Error('Campos obrigatórios não preenchidos')
    }

    setErros({})
  }

  return (
    <OffCanvasEntityContext.Provider
      value={{
        formData,
        setFormData,
        createEntity,
        erros,
        setErros
      }}
    >
      {children}
    </OffCanvasEntityContext.Provider>
  )
}
