import { useState, useEffect } from 'react'

export function useSelectedValue (
  value,
  items,
  selectItem,
  principalKey,
  textOption,
  textOptionSubItemKey
) {
  const [valueSelected, setValueSelected] = useState('')
  const [filtredItems, setFiltredItems] = useState([])

  const handleSelectItem = (item) => {
    setValueSelected(item[textOption])

    if (selectItem) {
      selectItem(item[principalKey])
    }
  }

  const removeItemSelected = () => {
    setValueSelected('')
  }

  const filterByWriting = (e) => {
    const text = e.target.value.toLowerCase()

    setValueSelected(e.target.value)

    const filtredItem = items.filter((item) =>
      item[textOption].toLowerCase().includes(text) || 
      item[textOptionSubItemKey].toLowerCase().includes(text) 
    )

    setFiltredItems(filtredItem)
  }

  useEffect(() => {
    const result = items.find((item) => item[principalKey] === value)
    if (result) setValueSelected(result[textOption])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (items && items?.length) setFiltredItems(items)
  }, [items])

  return {
    valueSelected,
    selectItem,
    handleSelectItem,
    removeItemSelected,
    filterByWriting,
    filtredItems,
  }
}
