export const capitalizeFirstLetter = (text = '') => {
  const words = text.split(' ') || []
  return words.length > 0
    ? words
      .map(
        (word) =>
          `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`
      )
      .join(' ')
    : ''
}

export function maskCurrency(valor, locale = 'pt-BR', currency = 'BRL') {
  if (isNaN(valor)) {
    valor = 0
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).format(valor)
}

export function mascaraMoeda(value) {
  if (!value) {
    return 0
  }

  const onlyDigits = value
    .split('')
    .filter(s => /\d/.test(s))
    .join('')
    .padStart(3, '0')

  const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2)

  return parseFloat(digitsFloat)
}

export const convertGramsToKilograms = (weight) => {
  const kilograms = weight / 1000

  return Number(kilograms.toFixed(2))
}

export const convertMetersToCentimeters = (measure) => {
  return measure * 100
}

export function deleteLocalStorage () {
  localStorage.removeItem('EBB_DASHBOARD_SESSION_TOKEN_SAURON')
  localStorage.removeItem('EBB_DASHBOARD_ACTIVE_ORGANIZATION_SAURON')
  localStorage.removeItem('EBB_DASHBOARD_USER_SAURON')
  localStorage.removeItem('apollo-cache-persist')
  localStorage.removeItem('printedTags')
}