import styled from 'styled-components'

export const ButtonPrint = styled.button`
  width: 150px;
  height: 30px;
  border-radius: 5px;
  background-color: #2f159e;
  border: none;
  color: #fafafa;
  cursor: pointer;
  box-shadow: none;
  outline: none;

  &:hover{
    transition: 0.6s;
    background-color: #2f159ee3;
  }
  `

  export const ContainerEtiqueta = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      p {
        font-weight: 500;
        font-size: 1rem;
      }
    }
  `