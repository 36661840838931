import styled from 'styled-components'

export const ShipmentContent = styled.div`
  height: ${props => props.$isOpen ? '400px' : '0px'};
  transition: ease-in all 0.3s;
`

export const PickupContent = styled(ShipmentContent)`
  height: ${props => props.$isOpen ? '400px' : '0px'};
  transition: ease-in all 0.3s;
`

export const DropContent = styled.div`
  height: 100%; 
  width: 100%;
  border: 1px solid #dcdcdc;
`

export const TabShipment = styled.div`
  
`
export const TabHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f1f1f1;
`

export const TabText = styled.span`
  border-bottom: ${props => props.$isSelect ? '3px solid #363487' : 'none'} ;
  padding-bottom: 8px;
  font-size: 0.9rem;
  color: #666;
  cursor: pointer;
  font-weight: 400;
`

export const TabContent = styled.div`
  width: 100%;
  height: calc(400px - 50px);
  background-color: #fafafa;
  display: ${props => props.$isVisible ? 'flex' : 'none'};
  flex-direction: column;
  overflow: auto;
`

export const TabRow = styled.div`
  width: calc(100% - 40px);
  display: grid;
  grid-template-columns: ${props => props.$columns || '1fr 1fr 1fr 1fr'};
  padding: ${props => props.$padding ? props.$padding : '20px'};
`

export const TitleRow = styled.h3`
  font-size: 16px;
  font-weight: normal;
  color: #363487;
`

export const TextRow = styled.p`
  font-size: 0.75rem;
  color: #606771;
`

export const TextValueRow = styled.p`
  font-size: 0.75rem;
  color: #606771;
  font-weight: 700;
`

export const ContentBox = styled.div`
   display: flex;
   flex-direction: ${props => props.$direction || 'row'};
   gap: 22px;

   @media screen and (max-width: 800px){
    flex-direction: column;
   }
`