import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'

import { URL_ZORDON } from '../env'
import { deleteLocalStorage } from '../Utils'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    deleteLocalStorage()

    if (window.location.pathname !== '/') {
      window.location.href = '/'
    }
    return
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }
})

const cache = new InMemoryCache()

persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
})

const authLink = setContext((_, { headers }) => {
  const tokens = JSON.parse(localStorage.getItem('EBB_DASHBOARD_SESSION_TOKEN_SAURON'))
  const ebbDashboardActiveOrganization = JSON.parse(localStorage.getItem('EBB_DASHBOARD_ACTIVE_ORGANIZATION_SAURON'))

  if (tokens === null || ebbDashboardActiveOrganization === null) {
    deleteLocalStorage()
    if (window.location.pathname !== '/') {
      window.location.href = '/'
    }
    return
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${tokens[ebbDashboardActiveOrganization]}`
    }
  }
})

export const clientApollo = new ApolloClient({
  link: from([
    errorLink,
    authLink,
    createHttpLink({ uri: `${URL_ZORDON}/graphql` })
  ]),
  cache,
})

