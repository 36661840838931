import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { FiltersPickup } from './components/FiltersPickup'
import { ListPickup } from './components/SearchPickup'
import { usePickup } from '../../hooks/Pickup'
import { ContainerPage } from '../../styled-components/style'

export function ListPickupPage () {
  const navigate = useNavigate()

  const { listPickup: { data } } = usePickup()
  
  useEffect(() => {
    document.title = 'Dashboard | Envios'
  }, [])

  return (
    <>
      <ContainerPage>
        <div
          className="container"
          style={{
            margin: 'auto',
          }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <h4 className="color-primary" style={{ fontSize: 34 }}>
                Coletas
              </h4>
              <p className="quantity-registers">
                {data?.pickups?.total || 0} Resultados
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <div>
                <button
                  className="btn-new"
                  onClick={() => navigate('/pickup/new')}
                >
                  + NOVA COLETA
                </button>
              </div>
            </div>
          </div>

          <section style={{ width: '100%', display: 'flex', gap: '32px', maxWidth: 1200 }}>
            <FiltersPickup />

            <div style={{ maxWidth: 900, width: 900 }}>
              <ListPickup />
            </div>
          </section>
        </div>
      </ContainerPage>
    </>
  )
}
