import { useMemo } from 'react'
import { GoPackage } from 'react-icons/go'

import { SHIPMENT_STATUS_LABEL_MAP } from '../../../../../enums/shipment-status.enum'
import { decimalNumber } from '../../../../../helpers/mask.helper'
import { convertGramsToKilograms } from '../../../../../Utils'
import { getColorByStatus } from '../../../../../Utils/status'
import { TabContent, TabRow, TextRow, TextValueRow, TitleRow } from '../../../../UI/Box/styles'

export function TabVolumes ({ shipment, isVisible }) {
  const volumes = useMemo(() => {
    if (
      shipment?.volumesInformations &&
      shipment?.volumesInformations.length > 0
    ) {
      return shipment?.volumesInformations.flatMap(
        (item) => {
          return item.codes.reduce((acc, code) => {
            const volumeByCode =
              shipment?.volumes &&
              shipment?.volumes.length > 0 &&
              shipment?.volumes.find((volume) => volume.code === code)

            if (!volumeByCode) {
              return acc
            }
            return [
              ...acc,
              {
                ...volumeByCode,
                cubageWeight: item.cubageWeight,
                height: item.height,
                length: item.length,
                weight: item.weight,
                width: item.width,
              },
            ]
          }, [])
        }
      )
    }
    return []
  }, [shipment?.volumes, shipment?.volumesInformations])


  return (
    <TabContent $isVisible={isVisible}>
      <TabRow>
        <div>
          <TextRow>Quantidade</TextRow>
          <TextValueRow>{shipment?.volumesQuantity ? (
            `
              ${shipment?.volumesQuantity} volume${shipment?.volumesQuantity > 1 ? '(s)' : ''
            }`
          ) : (
            '---'
          )}</TextValueRow>
        </div>
        <div>
          <TextRow>Peso</TextRow>
          <TextValueRow>{shipment?.totalWeight ? (
            `${decimalNumber(convertGramsToKilograms(shipment.totalWeight))} Kg`
          ) : (
            '---'
          )}</TextValueRow>
        </div>
        <div>
          <TextRow>Peso cubado</TextRow>
          <TextValueRow>
            {shipment?.cubageWeight ? (
              `${decimalNumber(convertGramsToKilograms(shipment.cubageWeight))} Kg`
            ) : (
              '---'
            )}
          </TextValueRow>
        </div>
        <div>
          <TextRow>Valor declarado</TextRow>
          <TextValueRow>
            {shipment?.declaredValue ? (
              `R$ ${decimalNumber(shipment.declaredValue)}`
            ) : (
              '---'
            )}
          </TextValueRow>
        </div>
      </TabRow>

      <TabRow $columns={'1fr'} $padding={'20px 0px 0px 20px'}>
        <TitleRow>Volumes</TitleRow>
      </TabRow>

      {volumes?.map((volume, index) => {
        const status = SHIPMENT_STATUS_LABEL_MAP.get(volume?.status)
        const statusColor = getColorByStatus(volume.status)

        return (
          <TabRow key={volume.id} style={{ fontSize: 12 }} $columns={'50px 1fr 1fr 1fr 1fr 1fr'}>
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <GoPackage size={30} color='rgba(47, 21, 158, 0.54)' />
              <TextRow>{`${volume.sequentialNumber || index + 1}`}</TextRow>
            </div>
            <div>
              <TextRow>Código do rastreio</TextRow>
              <TextValueRow>{volume.carrierCode || volume.trackingCode || '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Situação</TextRow>
              <TextValueRow style={{ color: statusColor }}>{status || '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Peso (Kg)</TextRow>
              <TextValueRow>{volume.weight ? decimalNumber(volume.weight) : '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Peso cubado (Kg)</TextRow>
              <TextValueRow>{volume.cubageWeight ? decimalNumber(volume.cubageWeight) : '---'}</TextValueRow>
            </div>
            <div>
              <TextRow>Dimensões (cm)</TextRow>
              <TextValueRow>{!volume.length && !volume.width && !volume.height
                ? '---'
                : `${volume.length || '---'} x ${volume.width || '---'} x ${volume.height || '---'
                }`}</TextValueRow>
            </div>
          </TabRow>
        )
      })}
    </TabContent>
  )
}
