import { useContext, useState } from 'react'

import { Modal } from '../../../../../../components/Modal'
import { AppContext } from '../../../../../../providers/AppProvider'
import { forceResendShipment } from '../../../../../../services/shipmentService'

export function RecendShipment ({
  open,
  onClose,
  id,
}) {
  const [isSending, setIsSending] = useState(false)

  const { organizationId } = useContext(AppContext)

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={'Deseja forçar reenvio da Minuta?'}
      textClose='CANCELAR'
      textConfirm='FORÇAR REENVIO'
      height={150}
      loading={isSending}
      onConfirm={async (e) => {
        e.stopPropagation()
        setIsSending(true)
        await forceResendShipment(id, organizationId)
        document.body.style.overflow = 'auto'
        setIsSending(false)
        onClose(e)
      }}
    >

      <p style={{ color: '#666', fontSize: '1rem' }}>
        {!isSending && `Após clicar no botão, será feito o reenvio da minuta "${id}"?`}
        {isSending && 'Fazendo reenvio!'}
      </p>
    </Modal>
  )
}