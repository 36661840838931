import { CustomInput, Label } from './styles'

export function InputCheck ({
  text,
  name,
  htmlFor = 'input-check',
  value,
  onChange,
  checked
}) {
  return (
    <Label htmlFor={htmlFor}>
      <CustomInput checked={checked} value={value} onChange={onChange} type="checkbox" name={name} id={htmlFor} />
      {String(text).substring(0, 25) + (String(text).length > 25 ? '...' : '')}
    </Label>
  )
}
