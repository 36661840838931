import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import './style.css'
import { clientApollo } from './api/Apolo.js'
import App from './App.jsx'
import { ShipmentProvider } from './providers/ShipmentProvider.jsx'

Sentry.init({
  dsn: 'https://3c33d2a1ca89e561a10226784d8b10e7@o4508047416688640.ingest.us.sentry.io/4509032420212736',
})

createRoot(document.getElementById('root')).render(
  <ApolloProvider client={clientApollo}>
    <ShipmentProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ShipmentProvider>
  </ApolloProvider>
)
