import axios from 'axios'

import { URL_EBB_BRUDAM_SYNCER, URL_GANDALF, URL_ZORDON } from '../env'
import { deleteLocalStorage } from '../Utils'

const brudamSyncerAPI = axios.create({
  baseURL: URL_EBB_BRUDAM_SYNCER
})

const zordonAPI = axios.create({
  baseURL: URL_ZORDON
})

const gandalfAPI = axios.create({
  baseURL: URL_GANDALF
})

/**
 * @param {[import('axios').AxiosInstance]} api 
 */
function addToken (api) {
  const path = window.location.pathname

  if (['/', ''].includes(path)) {
    return
  }

  for (const instance of api) {
    instance.interceptors.request.use((value) => {
      const tokens = JSON.parse(localStorage.getItem('EBB_DASHBOARD_SESSION_TOKEN_SAURON'))
      const ebbDashboardActiveOrganization = JSON.parse(localStorage.getItem('EBB_DASHBOARD_ACTIVE_ORGANIZATION_SAURON'))

      if (tokens === null || ebbDashboardActiveOrganization === null) {
        deleteLocalStorage()
        if (window.location.pathname !== '/') {
          window.location.href = '/'
        }
        return
      }

      value.headers = {
        ...value.headers,
        Authorization: value.headers.Authorization || `Bearer ${tokens[ebbDashboardActiveOrganization]}`
      }
      return value
    })

    instance.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          deleteLocalStorage()
          if (window.location.pathname !== '/') {
            window.location.href = '/'
          }
        }

        return Promise.reject(error)
      }
    )
  }
}

addToken([
  zordonAPI,
  brudamSyncerAPI,
  gandalfAPI
])


export {
  brudamSyncerAPI,
  zordonAPI,
  gandalfAPI
}