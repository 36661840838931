import { useEffect } from 'react'
import { RiCloseLargeFill } from 'react-icons/ri'

import { ModalBackground, ModalBody, ModalFooter, ModalFooterButton, ModalHeader, ModalView } from './style'
import { Spinner } from '../UI/Spiner'

export function Modal ({
  open,
  onClose,
  title,
  titleIcon,
  children,
  onConfirm,
  textClose = 'CANCELAR',
  textConfirm = 'CONFIRMAR',
  height = '360px',
  hiddenFooter,
  loading
}) {

  document.body.style.overflow = open ? 'hidden' : 'auto'

  function closeModal (e) {
    document.body.style.overflow = 'auto'
    onClose(e)
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && open) {
        closeModal(event)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ModalBackground $display={open ? 'flex' : 'none'} onClick={(e) => e.stopPropagation()}>
      <ModalView $width={'600px'} $height={height} style={{ backgroundColor: '#fafafa  ', zIndex: '9999' }}>
        <ModalHeader>
          <div>
            {titleIcon}
            <p>{title}</p>
          </div>
          <RiCloseLargeFill onClick={(e) => closeModal(e)} />
        </ModalHeader>

        <ModalBody>
          {children}
        </ModalBody>
        {!hiddenFooter && (
          <ModalFooter>
            <ModalFooterButton onClick={closeModal}>
              {textClose}
            </ModalFooterButton>
            <div >
              {loading && (
                <div style={{ width: 30, height: 30 }}>
                  <Spinner
                    loading={loading}
                    size={20}
                    typeLoader='ClipLoader'
                  />
                </div>
              )}
              {!loading && (
                <ModalFooterButton onClick={onConfirm}>
                  {textConfirm}
                </ModalFooterButton>
              )}
            </div>
          </ModalFooter>
        )}

      </ModalView>
    </ModalBackground>
  )
}