import { brudamSyncerAPI } from '../api/Axios'

export async function downloadMinuta (id) {
  const response = await brudamSyncerAPI.get(`/minutas/print/${id}`, {
    responseType: 'blob'
  })

  const fileURL = window.URL.createObjectURL(new Blob([response.data], {
    type: 'application/pdf',
  }))

  const win = window.open(fileURL, '_blank')
  win.print()
}