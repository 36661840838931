import dayjs from 'dayjs'

import { toRelativeDate } from '../../../../../../Utils/date'

const EstimatedTimeItem = ({
  color = '#606771',
  date,
  icon,
  label,
}) => {

  return (
    <div style={{
      display: 'flex',
      gap: '0.875rem',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>
      <p style={{
        margin: 0,
        fontSize: '0.75rem',
        color: '#606771',
      }} >
        {label}
      </p>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 10 }}>
        <p style={{ margin: 0, fontSize: '0.75rem', fontWeight: '500', color }}>{date
          ? `${toRelativeDate(date, 'DD/MM')} ${dayjs(date).format(
            'HH:mm'
          )}`
          : '---'}</p>

        {icon}
      </div>
    </div>
  )
}

export default EstimatedTimeItem
