
import { EntityDetailsPickup } from './EntityDetailsPickup'
import { decimalNumber } from '../../../../../helpers/mask.helper'
import { TabContent, TabRow, TextRow, TextValueRow } from '../../../../UI/Box/styles'

export function TabDetailsPickup ({ pickup, isVisible }) {
  const pickupIdentifier = pickup?.shipperCode || pickup?.carrierCode || pickup?.trackingCode
  
  return (
    <TabContent $isVisible={isVisible} >
      <TabRow $columns={'200px 1fr 1fr 1fr'}>
        <div>
          <TextRow>Código de Rastreio</TextRow>
          <TextValueRow>{pickupIdentifier}</TextValueRow>
        </div>
      </TabRow>
      <TabRow>
        <div>
          <TextRow>Peso</TextRow>
          <TextValueRow>
            {pickup?.totalWeight
              ? `${decimalNumber((pickup?.totalWeight / 1000))} Kg`
              : '---'}
          </TextValueRow>
        </div>
        <div>
          <TextRow>Valor declarado</TextRow>
          <TextValueRow>
            {pickup?.declaredValue
              ? `R$ ${decimalNumber(pickup?.declaredValue)}`
              : '---'}
          </TextValueRow>
        </div>
      </TabRow>
      <TabRow>
        <div>
          <TextRow>Horário de coleta</TextRow>
          <TextValueRow>
            {pickup?.readyTime} ás {pickup?.closeTime}
          </TextValueRow>
        </div>
      </TabRow>
      {(pickup?.originShipperEntity) && (
        <EntityDetailsPickup
          entity={pickup?.originShipperEntity}
          title="Origem"
        />
      )}
    </TabContent>
  )
}
