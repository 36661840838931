import { AiOutlineClose } from 'react-icons/ai'

import { useSelectedValue } from './hooks/useSelectedValue'
import * as S from './styles'

export function SelectBox ({
  isDisabled = false,
  items = [],
  dropDownColor,
  dropDownTextColor,
  textColorHover,
  textLabel,
  icon,
  selectItem,
  clickButton,
  textButton,
  colorButton,
  colorTextButton,
  enableButton = false,
  value = null,
  innerRef,
  colorLabel,
  placeholder,
  principalKey,
  textOption,
  showSubItem = false,
  textOptionSubItemKey,
  writing = true,
  textNotFound = 'Nenhum registro encontrado...',
  backgroundColor = 'white',
  fontSize,
  textAlign,
  width = '100%',
}) {
  const {
    valueSelected,
    heightElement,
    removeItemSelected,
    handleSelectItem,
    filterByWriting,
    filtredItems,
  } = useSelectedValue(value, items, selectItem, principalKey, textOption, textOptionSubItemKey)

  const clearInputRequired = () => {
    if (innerRef) innerRef.current.required = false
  }

  const disableWriting = (event) => {
    if (!writing) return event.preventDefault()
  }

  return (
    <S.Content $width={width}>
      <S.SelectBox
        type="text"
        $textAlign={textAlign}
        $fontSize={fontSize}
        $backgroundColor={backgroundColor}
        $isDisabled={isDisabled}
        value={valueSelected}
        onKeyDown={disableWriting}
        placeholder={placeholder}
        ref={innerRef}
        onBlur={clearInputRequired}
        onChange={filterByWriting}
      />

      <S.Label $colorLabel={colorLabel}>
        {textLabel}
        <S.RequiredField className="requiredField">
          Campo Obrigatório
        </S.RequiredField>
      </S.Label>

      <S.ArrowDown />

      {valueSelected && (
        <S.ContentCloseIcon onClick={() => removeItemSelected()}>
          <AiOutlineClose color="rgb(136, 136, 136)" />
        </S.ContentCloseIcon>
      )}

      <S.Container className="containerSelect" $dropDownColor={dropDownColor}>
        <S.Ul ref={heightElement}>
          {filtredItems.length ? (
            filtredItems.map((item, i) => (
              <S.Li
                key={item[principalKey] || i}
                $dropDownTextColor={dropDownTextColor}
                $textColorHover={textColorHover}
                onClick={() => handleSelectItem(item)}
              >
                <S.Option>{item[textOption] || `default ${i}`}</S.Option>

                {showSubItem && <S.SubOption>Documento: {item[textOptionSubItemKey]}</S.SubOption>}
              </S.Li>
            ))
          ) : (
            <S.Li
              $dropDownTextColor={dropDownTextColor}
              $textColorHover={textColorHover}
            >
              <S.Option>{textNotFound}</S.Option>
            </S.Li>
          )}
        </S.Ul>

        {enableButton && (
          <S.ContentButtonCreate>
            <S.ButtonCreate
              $dropDownTextColor={dropDownTextColor}
              onClick={clickButton}
              $colorButton={colorButton}
              $colorTextButton={colorTextButton}
            >
              {icon}
              {textButton}
            </S.ButtonCreate>
          </S.ContentButtonCreate>
        )}
      </S.Container>
    </S.Content>
  )
}
