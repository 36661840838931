import { useContext, useState } from 'react'
import { BiRadioCircleMarked } from 'react-icons/bi'
import { GoOrganization } from 'react-icons/go'
import { IoMdArrowDropdown } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router-dom'

import { OptionsNavBar } from './components/Options'
import { SelectOrganization } from './components/SelectOrganization'
import {
  NavBar as NavBarComponent,
  NavBarContent
} from './style'
import { AppContext } from '../../providers/AppProvider'
import { Grid } from '../UI/Grid'

export function NavBar () {
  const navigate = useNavigate()
  const { user } = useContext(AppContext)
  const { pathname } = useLocation()

  const [open, setOpen] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)

  const { organization } = useContext(AppContext)

  return (
    <>
      <SelectOrganization
        onClose={(e) => {
          e.stopPropagation()
          setOpen(false)
        }}
        open={open}
      />

      {JSON.stringify()}

      <NavBarComponent className="navbar">
        <NavBarContent className="nav-content">
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', gap: 20 }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                width={168}
                height={24}
                style={{ cursor: 'pointer' }}
                src="/ebb-logo-primary.svg"
                onClick={() => navigate('/shipments')}
              />
              <div style={{ display: 'flex', marginLeft: 20, cursor: 'pointer', gap: 8 }} onClick={() => setOpen(true)}>
                <GoOrganization size={20} color='#4e4c4c' />
                <h3 style={{ color: '#4e4c4c' }}>{organization?.name}</h3>
                <IoMdArrowDropdown size={20} />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'start', height: 82, gap: 10 }}>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                borderBottom: pathname.includes('shipments') ? '2px solid #2f159e' : '',
                color: '#666',
                fontWeight: '600'
              }}
                onClick={() => navigate('/shipments')}
              >
                {pathname.includes('shipments') ? <BiRadioCircleMarked size={20} /> : ''} Rastreio
              </div>

              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#666',
                borderBottom: pathname.includes('pickup') ? '2px solid #2f159e' : '',
                fontWeight: '600'
              }}
                onClick={() => navigate('/pickup')}
              >
                {pathname.includes('pickup') ? <BiRadioCircleMarked size={20} /> : ''} Coletas
              </div>
            </div>
          </div>

          <div style={{ position: 'relative', cursor: 'pointer' }}>
            <div onClick={() => setOpenMenu(true)}>
              <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {user.name} <IoMdArrowDropdown size={20} />
              </Grid>
            </div>

            {openMenu && (
              <OptionsNavBar
                open={openMenu}
                clear={() => setOpenMenu(false)}
              />
            )}

          </div>
        </NavBarContent>
      </NavBarComponent>
    </>
  )
}
