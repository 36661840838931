import { useContext } from 'react'

import { InputCheck } from '../../../../../components/UI/Inputs/InputCheck'
import { StatusShipment } from '../../../../../enums/shipment-status.enum'
import { FilterContext } from '../../../../../providers/Filter'

export function StatusFilter () {
  const { filters, setFilters } = useContext(FilterContext)

  function onChangeStatuses (event, status) {
    const statusShipment = StatusShipment.find(e => e.text === status.text)

    if (typeof statusShipment.value === 'string') {
      setFilters(state => {
        let aux = { ...state }

        if (!aux.filter?.statuses) {
          aux = {
            ...aux,
            filter: {
              ...aux.filter,
              statuses: []
            }
          }
        }

        const exists = aux.filter?.statuses.includes(event.target.value)

        if (!exists)
          aux.filter.statuses.push(event.target.value)
        else
          aux.filter.statuses = aux.filter.statuses.filter(e => e !== event.target.value)


        return aux
      })
    } else {
      const aux = {
        ...filters,
        filter: {
          ...filters.filter,
          [status.value.key]: status.value.value
        }
      }

      if (event.target.checked === false) {
        Reflect.deleteProperty(aux.filter, status.value.key)
      }

      setFilters(aux)
    }
  }

  return (
    <>
      {StatusShipment.map((status) => {
        let value = status?.value?.key ?? status.value

        let check = false

        if (typeof status.value === 'string') {
          check = filters?.filter?.statuses?.includes(value)
        } else {
          check = filters?.filter ?
            (filters?.filter[status?.value?.key] !== undefined ? true : false)
            :
            false
        }

        if (check === undefined) {
          check = false
        }

        if (value === undefined) {
          value = ''
        }

        return (
          <InputCheck
            text={status.text}
            htmlFor={`${value}-input-check-statuses`}
            key={value}
            value={value}
            checked={check}
            onChange={(e) => onChangeStatuses(e, status)}
          />
        )
      })}
    </>
  )
}