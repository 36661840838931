import { gql } from '@apollo/client'

export const LIST_SHIPMENTS = gql`
  query ListShipments(
    $first: Int!
    $offset: Int!
    $order: ListOrder
    $filter: ShipmentsFilter!
  ) {
    shipments(first: $first, offset: $offset, order: $order, filter: $filter) {
      data {
        becameReadyAt
        totalWeight
        cubageWeight
        declaredValue
        carrierCode
        createdAt
        cte {
          id
        }
        dispatcher {
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        consignee{
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        authorizedReceiver{
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        eta
        etd
        finishedAt
        id
        pickup {
          pickupDate
        }
        receiptModality
        receiver {
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        recipient {
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        sender {
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        shipperCode
        status
        syncedAt
        trackingCode
        volumesQuantity
      }
      
      total
    }
  }

`

export const GET_SHIPMENT = gql`
  query Shipment($id: Int, $trackingCode: String) {
    shipment(id: $id, trackingCode: $trackingCode) {
        becameReadyAt
        totalWeight
        cubageWeight
        declaredValue
        carrierCode
        createdAt
        cte {
          id
        }
        dispatcher {
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        consignee{
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        authorizedReceiver{
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        eta
        etd
        finishedAt
        id
        pickup {
          pickupDate
        }
        receiptModality
        receiver {
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        recipient {
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        sender {
          displayName
          name
          address {
            location
            state
            street
            number
            complement
            neighborhood
            cep
          }
        }
        shipperCode
        status
        syncedAt
        trackingCode
        volumesQuantity
        volumes {
          carrierCode
          code
          id
          sequentialNumber
          status
          trackingCode
        }
        volumesInformations {
          codes
          cubageWeight
          height
          length
          quantity
          weight
          width
        }
    }
  }

`

export const GET_DOCUMENTS_SHIPMENT = gql`
  query Shipment($id: Int, $trackingCode: String) {
    shipment(id: $id, trackingCode: $trackingCode) {
      id
      contentStatement {
        value
        documentNumber
        file {
          url
        }
      }
      cte {
        auxiliaryDocument {
          url
        }
        issueDate
        key
        number
        serie
      }
      fiscalDocuments {
        id
        issueDate
        key
        number
        order
        serie
        value
        weight
      }
      previousDocuments {
        id
        issueDate
        number
        serie
        value
      }
    }
  }
`