import dayjs from 'dayjs'

import { ShipmentStatus } from '../enums/shipment-status.enum'


export const getColorByStatus = (status) => {
  switch (status) {
    case ShipmentStatus.Finished: {
      return '#28A745'
    }
    case ShipmentStatus.InTransit: {
      return '#2F159E'
    }
    case ShipmentStatus.Ready: {
      return '#606771'
    }
    case ShipmentStatus.ToWithdrawal: {
      return '#2196F3'
    }
    default: {
      return '#606771'
    }
  }
}

export const getStylesByStatus = (
  status,
  eta
) => {
  const defaultStyles = {
    backgroundColor: '#FFFFFF',
    etaColor: 'rgb(96, 103, 113)',
    etdColor: 'rgb(96, 103, 113)',
  }

  switch (status) {
    case ShipmentStatus.Finished:
      return {
        backgroundColor: '#EDF7ED',
        etaColor: '#28A745',
        etdColor: '#28A745',
      }
    case ShipmentStatus.Ready:
    case ShipmentStatus.InTransit:
      if (eta && dayjs(eta).diff(dayjs(), 'hour') < 0) {
        return {
          ...defaultStyles,
          backgroundColor: '#FFF4E5',
          etaColor: '#FF9800',
        }
      }
      return defaultStyles
    case ShipmentStatus.ToWithdrawal:
      return {
        ...defaultStyles,
        backgroundColor: '#E8F4FD',
      }
    default:
      return defaultStyles
  }
}
