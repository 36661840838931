import styled from 'styled-components'

export const Label = styled.label`
  font-size: 0.75rem;
  pointer-events: none;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: ${props => props.$colorLabel || '#a1a1a1'};
  padding: 0 0.2em;
  font-weight: 500;

  @media screen and (min-width: 1980px){
    &{
      font-size: 13px;
    }
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: ${props => props.$width || '100%'};

  &::before {
    content: "";
    width: 100%;
    border-bottom: solid 2px rgb(171 171 171);
    position: absolute;
    bottom: 0;
    transition: ease-in 0.1s;
    cursor: text;
  }

  &:hover::before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.54)!important;
    transition: ease-in 0.1s;
    cursor: text;
  }

  &:focus-within > ${Label} {
    color: #363487!important;
    transition: ease-in 0.1s;
  }

  &:focus-within::before {
    border-bottom: 2px solid #363487!important;
    transition: ease-in 0.1s;
    cursor: text;
  }
`


export const Container = styled.div`
  opacity: 0;
  transition: ease-in 0.1s;
  visibility: hidden;
  position: absolute;
  background-color: ${(props) => props.$dropDownColor || 'white'};
  top: 45px;
  width: 100%;
  z-index: 9999;
  box-shadow: 0px 0px 5px #8b87878f;
`

export const Ul = styled.ul`
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  
  &::-webkit-input-placeholder { 
    color: rgb(216 216 216);
  }

  &:-moz-placeholder { 
    color: rgb(216 216 216);
    opacity:  1;
  }

  &::-moz-placeholder { 
     color: rgb(216 216 216);
     opacity:  1;
  }

  &:-ms-input-placeholder { 
     color: rgb(216 216 216);
  }

  &::-ms-input-placeholder {
     color: rgb(216 216 216);
  }

  &::placeholder {
     color: rgb(216 216 216);
  }
`

export const Li = styled.li`
  width: 100%;
  max-width: 100%;
  min-height: 30px;
  display: flex;
  flex-direction: column;
  padding: 0.5em 0.5em;
  cursor: pointer;
  transition: ease-in 0.1s all;
    
  &:hover {
    background-color: ${(props) => props.$textColorHover || '#F2F2F4'};
  }
`

export const Option = styled.p`
  flex: 1;
  min-width: 0; 
  color: ${(props) => props.$dropDownTextColor || 'rgb(107 107 107)'};
  font-size: 0.875rem!important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden!important;
  padding-right: 0.5rem;
`

export const SubOption = styled.p`
  color: rgb(0 0 0 / 70%);
  font-size: 12px;
`

export const ButtonCreate = styled.button`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: none!important;
  border-top: solid 1px #00000026!important;
  background-color: ${props => props.$colorButton || 'white'};
  border-radius: 0 0 5px 5px!important;
  color: ${props => props.$colorTextButton || 'black'}!important;
  font-size: 0.875rem !important;
  font-weight: normal;
  padding: 0.3rem 0.2rem;
  cursor: pointer !important;
  transition: ease-in 0.1s;
  box-shadow: 0px -1px 2px 0 #00000026;
  padding-left: 0.875rem;

  &:hover {
    background-color: #f2f2f485;
  }
`

export const ContentButtonCreate = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const ArrowDown = styled.div`
  position: absolute;
  bottom: 15px;
  right: 8px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgb(136, 136, 136);
`

export const ContentCloseIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 24px;
  top: 5%;
  bottom: 0;
  cursor: pointer!important;
  border-radius: 50%;
  padding: 0.2rem;
  transition: ease-in all 0.1s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    transition: ease-in all 0.1s;
  }
`

export const RequiredField = styled.span`
  display: none;
  width: auto;
  position: absolute;
  background-color: red;
  z-index: 200;
  left: 0;
  top: -0.2rem;
  border-radius: 5px;
  padding: 0.2rem 0.3rem;
  color: white;
  font-size: 10px;

  &::after {
    content: '';
    position: absolute;
    left: 20%;
    bottom: -0.3rem;
    transform: translateX(-50%);
    border-width: 0.4rem 0.4rem 0;
    border-style: solid;
    border-color: red transparent transparent;
    display: block;
    width: 0;
  }
`

export const SelectBox = styled.input`
  &:focus ~ .containerSelect {
    opacity: 1;
    visibility: visible;
  }

  font-size: ${props => props.$fontSize || '0.875rem'};
  height: 32px;
  border: none;
  outline: none;
  padding-right: 57px;
  color: #5f5c5b;
  background-color: ${props => props.$backgroundColor};
  text-align: ${props => props.$textAlign || 'left'};

  &:required {
    border: 1px solid red!important;
    box-shadow: 0 0 2px 1px #00000052!important;
  }

  &::-webkit-input-placeholder { 
    color: rgb(216 216 216);
  }

  &:required ~ ${Label} {
    color: transparent!important;
    user-select: none!important;

    .requiredField {
      display: block!important;
    }
  }

  &:not(:focus) {
    transition: ease-in 0.1s all;
  }

  &:focus {
    transition: ease-in 0.1s all;
  }

  @media screen and (min-width: 1980px){
    & {
      font-size: 15px;
    }
  }

  ${(props) => props.$isDisabled && `
    background-color: #e9e9e9;
    pointer-events: none;
    color: #949290!important;
    user-select: none;
  `}
`