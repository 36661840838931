import styled from 'styled-components'

export const Option = styled.div`
  width: 125px;
  height: 0px;
  border-radius: 5px;
  position: absolute;
  background-color: white;
  display: ${props => props.$display};
  top: 10px;
  z-index: 999999;
  right: 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


  @keyframes example {
    from {
      width: 125px;
      height: 0px;
      transition: ease-in all 0.1s;
    }

    to {
      width: 125px;
      height: 80px;
      transition: ease-in all 0.1s; 
    }
  }

  animation-name: example;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;

  ul {
    list-style: none;
    width: 120px;
  }

  ul li {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
    padding-right: 0px;
    font-size: 1rem;
    font-weight: 400;
    color: #666;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #e1e1e5;
    }

    svg {
      margin-right: 10px;
    }
  }
`