import styled from 'styled-components'

export const ContainerLogin = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
`

export const HeaderLogin = styled.div`
  width: 100vw;
  height: 250px;
  background-color: rgb(54, 52, 135);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FormLogin = styled.div`
  width: 400px;
  height: 350px;
  background-color: #FFFFFF;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: fixed;
  top: 150px;
  border-radius: 3px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`