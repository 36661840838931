export const ShipmentStatus = {
  Cancelled: 'cancelled',
  Draft: 'draft',
  Finished: 'finished',
  InTransit: 'inTransit',
  Ready: 'ready',
  ToWithdrawal: 'toWithdrawal',
  Unknown: 'unknown',
}

export const SHIPMENT_STATUS_LABEL_MAP = new Map([
  [ShipmentStatus.Finished, 'Entregue'],
  [ShipmentStatus.InTransit, 'Em Trânsito'],
  [ShipmentStatus.Ready, 'Não Iniciado'],
  [ShipmentStatus.ToWithdrawal, 'Aguardando Retirada'],
])


export const GET_STATUS_LABEL_SHIPMENT_MAP = new Map([
  ['Entregue', ShipmentStatus.Finished],
  ['Em Trânsito', ShipmentStatus.InTransit],
  ['Não Iniciado', ShipmentStatus.Ready],
  ['Aguardando Retirada', ShipmentStatus.ToWithdrawal],
])

export const StatusShipment = [
  { text: 'Entregue', value: ShipmentStatus.Finished },
  { text: 'Em Trânsito', value: ShipmentStatus.InTransit },
  { text: 'Não Iniciado', value: ShipmentStatus.Ready },
  { text: 'Aguardando Retirada', value: ShipmentStatus.ToWithdrawal },
  { text: 'Atrasado', value: { key: 'delayed', value: true } },
  { text: 'Não Sincronizados', value: { key: 'synced', value: false } },
]