import { FaCheckCircle } from 'react-icons/fa'

import { Modal } from '../Modal'
import { Grid } from '../UI/Grid'
import { Text } from '../UI/Text'

export function ModalSuccess ({ message, title, open, onClose }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      height={'150px'}
      hiddenFooter={true}
    >
      <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
        <Grid width='150px'>
          <FaCheckCircle color='rgb(54, 52, 135)' size={80} />
        </Grid>
        <Grid style={{ display: 'flex', flexDirection: 'column', gap: 20, paddingBottom: 40 }}>
          <Text as='h2' fontSize='1.5rem' fontWeight='bold'>{title}</Text>
          <Text as='p' fontWeight='500'>
            {message}
          </Text>
        </Grid>
      </Grid>
    </Modal>
  )
}