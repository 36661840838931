import Joi from 'joi'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'

import { zordonAPI } from '../../../api/Axios'
import { AppContext } from '../../../providers/AppProvider'
import { NewPickupContext } from '../../../providers/NewPickupProvider'
import { listEntities } from '../../../services/entities'

export function useNewPickup () {
  const { organizationId, requiredShipperCode } = useContext(AppContext)
  const { pickup, setPickup } = useContext(NewPickupContext)
  const navigate = useNavigate()

  const addMoreVolumes = () => {
    setPickup((state) => ({
      ...state,
      volumes: [
        ...state.volumes,
        {
          id: v4(),
          weight: '',
          serialNumber: '',
          value: '',
          productTypeId: '',
        }
      ],
    }))
  }

  const updateVolume = (volume) => {
    setPickup((state) => {
      return ({
        ...state,
        volumes: [
          ...(state.volumes.map(e => {
            if (e.id != volume.id) {
              return e
            }
            return volume
          })),
        ],
      })
    })
  }

  const removeVolume = (id) => {
    setPickup((state) => ({
      ...state,
      volumes:
        state.volumes.length > 1
          ? state.volumes.filter((vol) => vol.id !== id)
          : state.volumes,
    }))
  }

  function validationPickup (body) {
    const schema = Joi.object({
      consigneeId: Joi.number(),

      receiverId: Joi.number().messages({
        '*': 'Destinatário obrigatório.'
      }),
      recipientId: Joi.number().messages({
        '*': 'Destinatário obrigatório.'
      }),
      destinationShipperEntityId: Joi.number().messages({
        '*': 'Destinatário obrigatório.'
      }),
      dispatcherId: Joi.number().messages({
        '*': 'Remetente obrigatório'
      }),
      originShipperEntityId: Joi.number().messages({
        '*': 'Remetente obrigatório'
      }),
      senderId: Joi.number().messages({
        '*': 'Remetente obrigatório'
      }),

      shipperCode: requiredShipperCode ?
        Joi.string().messages({
          '*': 'O campo de referência deve ser preenchido.'
        })
        :
        Joi.string().allow('', null),
      volumes: Joi.array().items(Joi.object({
        organizationId: Joi.number().messages({
          '*': 'Deve ser preenchido todas as informações do item a ser coletado'
        }),
        productTypeId: Joi.number().messages({
          '*': 'Deve ser preenchido todas as informações do item a ser coletado'
        }),
        serialNumber: Joi.number().messages({
          '*': 'Deve ser preenchido todas as informações do item a ser coletado'
        }),
        value: Joi.number().messages({
          '*': 'Deve ser preenchido todas as informações do item a ser coletado'
        }),
        weight: Joi.number().messages({
          '*': 'Deve ser preenchido todas as informações do item a ser coletado'
        }),
      })),
    })

    const { error } = schema.validate(body, {
      allowUnknown: true
    })

    if (error?.details?.length > 0) {
      throw new Error(error?.details[0]?.message)
    }
  }

  async function saveNewPickup () {
    const entityConsignee = await listEntities(organizationId)
    const consignee = entityConsignee?.entities?.data[0]

    if (!consignee) {
      throw new Error('Organização não encontrada.')
    }

    const body = {
      ...pickup,
      consigneeId: consignee.id,
      totalWeight: pickup.volumes.map(e => +e.weight).reduce((a, b) => a + b, 0),
      declaredValue: pickup.volumes.map(e => +e.value).reduce((a, b) => a + b, 0).toString(),
      volumes: pickup.volumes.map(e => {
        return {
          organizationId,
          productTypeId: e.productTypeId,
          serialNumber: e.serialNumber,
          value: +e.value,
          weight: +e.weight,
        }
      })
    }

    validationPickup(body)

    await zordonAPI.post('/pickups', body)

    navigate('/pickup')
  }

  return {
    pickup,
    setPickup,
    addMoreVolumes,
    removeVolume,
    updateVolume,
    saveNewPickup
  }
}
