import styled from 'styled-components'

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

export const PageNumber = styled.button`
  background-color: ${(props) => (props.$active ? '#007bff' : 'transparent')};
  color: ${(props) => (props.$active ? 'white' : '#007bff')};
  border: none;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
  
  &:hover {
    background-color: ${(props) => (props.$active ? '#007bff' : '#e0e0e0')};
  }
`

export const Dots = styled.span`
  padding: 10px;
`

export const ArrowButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
  padding: 10px;
  margin: 0 5px;
  border-radius: 5px;

  &:hover {
    background-color: #e0e0e0;
  }

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`
