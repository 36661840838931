import _ from 'lodash'
import { useContext, useState, useRef } from 'react'
import { FaFile } from 'react-icons/fa6'
import { IoMdClose, IoMdCloseCircle } from 'react-icons/io'
import styled from 'styled-components'

import { Box } from '../../../components/UI/Box'
import { BtnCustom } from '../../../components/UI/Btn'
import { Grid } from '../../../components/UI/Grid'
import { InputText } from '../../../components/UI/Inputs/InputText'
import { Spinner } from '../../../components/UI/Spiner'
import { Text } from '../../../components/UI/Text'
import { NewShipmentContext } from '../../../providers/NewShipment'
import { fiscalDocuments } from '../../../services/shipmentService'
import { mascaraMoeda, maskCurrency } from '../../../Utils'


const DropArea = styled.div`
  border: 1px dashed #ccc;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

export const UploadFileNotaAndDeclaration = () => {
  const { shipment, setShipment } = useContext(NewShipmentContext)

  const [fileError, setFileError] = useState(false)
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const [declaration, setDeclaration] = useState(false)

  const fileInputRef = useRef(null)

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handleFiles = async (newFiles) => {
    const filesArray = Array.from(newFiles)
    const filesInBase64 = await Promise.all(
      filesArray.map(async (file) => {
        const base64 = await fileToBase64(file)
        return {
          name: file.name,
          xmlBase64: base64,
        }
      })
    )

    try {

      if (declaration) {
        const file = filesInBase64[0]
        setShipment({
          ...shipment,
          contentStatement: {
            documentNumber: '',
            fileBase64: file.xmlBase64,
            fileName: file.name,
            value: 0
          }
        })

      } else {
        const result = await fiscalDocuments(filesInBase64.map(e => ({ xmlBase64: e.xmlBase64.replace('data:text/xml;base64,', '') })), false)

        const listFiscalDocuments = [...result, ...(shipment?.fiscalDocuments || [])]

        const listDocuments = _.uniqBy(listFiscalDocuments, 'key').map((document)=>{
          return {
              issueDate: document.issueDate,
              issuerDocumentNumber: document.senderDocumentNumber,
              key: document.key,
              value: document.value,
              volumesQuantity: document.volumesQuantity,
              weight: document.weight,
          }
        })

        setShipment({
          ...shipment,
          fiscalDocuments: listDocuments,
          declaredValue: listDocuments?.reduce((prev, current) => prev + current?.value, 0)
        })
      }

      setFiles((prevFiles) => [...prevFiles, ...filesInBase64])
    } catch {
      setFileError(true)
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  const handleDrop = (event) => {
    event.preventDefault()
    setLoading(true)
    const droppedFiles = event.dataTransfer.files
    handleFiles(droppedFiles)
      .finally(() => {
        setLoading(false)
      })
  }

  let title = 'Insira as notas fiscais'

  if (declaration) {
    title = 'Adicionar Declaração de Conteúdo'
  }

  const inputName = 'fileInput-nfe'
  return (
    <Box style={{ marginTop: 10, paddingTop: 10 }}>
      <Grid style={{ width: '98%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 10px 0px 10px' }}>
        <Text as='h2' fontSize='1.2rem' padding={'0px 0px 15px 0px'} fontWeight='500'>
          {title}
        </Text>
        <Text click={() => {
          setDeclaration(s => !s)
          setFiles([])
        }} as='p' fontSize='0.9rem' padding={'0px 0px 15px 0px'}
          fontWeight='500' style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
          {declaration ? 'Possuo NFe desse envio' : 'Esse envio não possui NFe'}
        </Text>
      </Grid>
      {(files.length === 0 || declaration === false) && (
        <DropArea
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          onClick={() => {
            setFileError(false)
            document.getElementById(inputName).click()
          }}
        >

          {fileError ?
            <>
              <IoMdCloseCircle size={40} color='red' />
              <Text as='p' color='red' fontWeight='500' style={{ width: '90%', textAlign: 'center' }}>
                Arquivo inválido
              </Text>
            </>
            : <>
              {loading ? (
                <Spinner loading={loading} width={'100%'} height={'100%'} />
              ) : (
                <>
                  <FaFile size={40} color='#66666687' />
                  <Text as='p' color='#666' style={{ width: '90%', textAlign: 'center' }}>
                    {declaration ?
                      'Arraste o arquivo da declaração aqui. Aceitamos apenas arquivos com as seguintes extensões: .jpg, .jpeg, .png, .gif, .tiff, .bmp e .pdf.' :
                      'Arraste os arquivos XML aqui'
                    }
                  </Text>
                </>
              )}
            </>
          }

          {declaration && (
            <input
              type="file"
              accept={'.jpg,.jpeg,.png,.gif,.tiff,.bmp,.pdf'}
              multiple
              onChange={(e) => handleFiles(e.target.files)}
              style={{ display: 'none' }}
              id={inputName}
              ref={fileInputRef}
            />
          )}

          {!declaration && (
            <input
              type="file"
              accept={'.xml'}
              multiple
              onChange={(e) => handleFiles(e.target.files)}
              style={{ display: 'none' }}
              id={inputName}
              ref={fileInputRef}
            />
          )}

          <div>
            <BtnCustom
              heigth={'50px!important'}
              text={' OU ESCOLHA DE SEU COMPUTADOR'}
              hoverColor={'#1e6ab6'}
            />
          </div>
        </DropArea>
      )}

      {
        (files.length > 0 && declaration === false) && (
          <Grid style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
            <Text as='p'>{shipment?.fiscalDocuments?.length ?? 0} Notas</Text>
            <Text as='p'>Valor total: {maskCurrency(shipment?.['fiscalDocuments']?.reduce((prev, current) => prev + current?.value, 0))}</Text>
          </Grid>
        )
      }

      {(files.length > 0 && declaration === true) && (
        <Grid>
          <Grid style={{ backgroundColor: '#ebfaf2', padding: 20, width: '95%', borderRadius: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
            <Text>
              Declaração de conteúdo já adicionada
            </Text>
            <IoMdClose
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setDeclaration(false)
                setFiles([])
                setShipment({
                  ...shipment,
                  contentStatement: {
                    documentNumber: '',
                    fileBase64: '',
                    fileName: '',
                    value: 0
                  }
                })
              }}
            />
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'space-between', gap: 20, alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
            <InputText
              type="text"
              height="33px"
              label="Valor da declaração"
              onChange={(e) => setShipment({
                ...shipment,
                contentStatement: {
                  ...shipment.contentStatement,
                  value: mascaraMoeda(e.target.value)
                }
              })}
              value={maskCurrency(shipment?.contentStatement?.value)}
            />
            <InputText
              type="text"
              height="33px"
              label="Número do documento"
              rightItem={''}
              onChange={(e) => setShipment({
                ...shipment,
                contentStatement: {
                  ...shipment.contentStatement,
                  documentNumber: e.target.value
                }
              })}
              value={shipment?.contentStatement?.documentNumber}
            />
          </Grid>
        </Grid>
      )}
    </Box >
  )
}

