import styled from 'styled-components'

export const Typography = styled.p`
`

/**
 * 
 * @param {{
 * as: ElementType = 'p',
 * children:string,
 * color :string,
 * fontSize:string,
 * padding :string,
 * margin :string,
 * fontWeight :string,
 * cursor :string,
 * textDecoration :string,
 * click: any,
 * style: React.CSSProperties
 * }} param0 
 * @returns 
 */
export function Text ({
  as: ElementType = 'p',
  children,
  color = '#666',
  fontSize = '1rem',
  padding = 0,
  margin = 0,
  fontWeight = 'normal',
  cursor = 'normal',
  textDecoration = 'normal',
  click = () => { },
  style = {}
}) {
  const valids = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span']

  if (!valids.includes(ElementType)) {
    throw new Error('Type error')
  }

  return (
    <Typography onClick={click} as={ElementType} style={{ color, fontSize, padding, margin, fontWeight, cursor, textDecoration, ...style }}>
      {children}
    </Typography>
  )
}

