import dayjs from 'dayjs'

export const isToday = (date) => {
  return dayjs().isSame(date, 'day')
}

export const isTomorrow = (date) => {
  const now = dayjs().startOf('day')
  const then = dayjs(date).startOf('day')
  const diff = then.diff(now, 'hour')

  return diff >= 24 && diff < 48
}

export const isYesterday = (date) => {
  const now = dayjs().startOf('day')
  const then = dayjs(date).startOf('day')
  const diff = then.diff(now, 'hour')

  return diff < 0 && diff >= -24
}

export const toRelativeDate = (date, format) => {
  switch (true) {
    case isToday(date):
      return 'Hoje'
    case isTomorrow(date):
      return 'Amanhã'
    case isYesterday(date):
      return 'Ontem'
    default:
      return dayjs(date).format(format || 'DD/MM HH:mm')
  }
}

