import styled from 'styled-components'

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 2px solid #ccc;
`

export const TimelineItem = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  position: relative;


  &:before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #c5cacf;
    position: absolute;
    left: -29px;
    top: 0;
  }
`

export const Time = styled.p`
  font-size: 0.75rem;
  color: gray;
  margin-bottom: 5px;
  width: 30px;
`

export const TimeContent = styled.div`
  font-size: 0.875rem;
  color: #606771;
`

export const TimeComments = styled.div`
  font-size: 0.75rem;
  color: #606771;
  padding: ${props => props.$padding ? props.$padding : '0px'};
`


export const TimeContentResponsible = styled.div`
  display: flex;
  gap: 10px;
  font-size: 0.875rem;
  color: #606771;
  padding-top: 0.75rem;

  div {
    display: flex;
    gap: 5px;
  }
`