import { useEffect, useState } from 'react'
import { FaBox } from 'react-icons/fa'
import { LuFileSpreadsheet } from 'react-icons/lu'
import { PiCheckFatFill } from 'react-icons/pi'
import { TbListDetails } from 'react-icons/tb'

import { TabDetails } from './components/Tabs/TabDetails'
import { useShipments } from '../../hooks/Shipment'
import { convertGramsToKilograms, convertMetersToCentimeters } from '../../Utils'
import { DropContent, TabHeader, TabShipment, TabText } from '../UI/Box/styles'
import { Spinner } from '../UI/Spiner'
import { TabDocuments } from './components/Tabs/TabDocuments'
import { TabTimeline } from './components/Tabs/TabOccurrences'
import { TabVolumes } from './components/Tabs/TabVolumes'

export function ShipmentDetails ({ id }) {
  const { queryShipment: [getShipment, { data, loading }] } = useShipments()

  const [tabSelect, setTabSelect] = useState('DETALHES')

  useEffect(() => {
    if (id) {
      getShipment({ variables: { id } })
    }
  }, [getShipment, id])

  const shipment = {
    ...data?.shipment,
    volumes: data?.shipment?.volumes?.map(volume => {
      const { cubageWeight, height, length, weight, width } = volume

      return {
        ...volume,
        cubageWeight: cubageWeight && convertGramsToKilograms(cubageWeight),
        height: height && convertMetersToCentimeters(height),
        length: length && convertMetersToCentimeters(length),
        weight: weight && convertGramsToKilograms(weight),
        width: width && convertMetersToCentimeters(width),
      }
    }),
    volumesInformations: data?.shipment?.volumesInformations?.map(volume => {
      return {
        ...volume,
        cubageWeight: volume.cubageWeight && convertGramsToKilograms(volume.cubageWeight),
        height: volume.height && Number((volume.height * 100).toFixed(2)),
        length: volume.length && Number((volume.length * 100).toFixed(2)),
        weight: volume.weight && convertGramsToKilograms(volume.weight),
        width: volume.width && Number((volume.width * 100).toFixed(2)),
      }
    })
  }

  return (
    <DropContent $id={id} >
      <TabShipment>
        <TabHeader>
          <TabText
            $isSelect={tabSelect === 'DETALHES'}
            onClick={() => setTabSelect('DETALHES')}
          >
            <TbListDetails /> DETALHES
          </TabText>
          <TabText
            $isSelect={tabSelect === 'VOLUMES'}
            onClick={() => setTabSelect('VOLUMES')}
          >
            <FaBox /> VOLUMES
          </TabText>
          <TabText
            $isSelect={tabSelect === 'DOCUMENTOS'}
            onClick={() => setTabSelect('DOCUMENTOS')}
          >
            <LuFileSpreadsheet /> DOCUMENTOS
          </TabText>
          <TabText
            $isSelect={tabSelect === 'OCORRÊNCIAS'}
            onClick={() => setTabSelect('OCORRÊNCIAS')}
          >
            <PiCheckFatFill /> OCORRÊNCIAS
          </TabText>
        </TabHeader>

        <Spinner loading={loading} width={'100%'} height={'calc(400px - 50px)'} />

        {!loading && (
          <>
            <TabDetails shipment={shipment} isVisible={tabSelect === 'DETALHES'} />
            <TabVolumes shipment={shipment} isVisible={tabSelect === 'VOLUMES'} />
            <TabDocuments id={shipment?.id} isVisible={tabSelect === 'DOCUMENTOS'} />
            <TabTimeline isVisible={tabSelect === 'OCORRÊNCIAS'} id={shipment?.id} />
          </>
        )}
      </TabShipment>
    </DropContent>
  )
}
