import { useState } from 'react'
import { MdModeEditOutline } from 'react-icons/md'
import { RiDeleteBin7Fill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { NavBarOption } from './style'
import { deleteLocalStorage } from '../../../../Utils'

export function OptionsNavBar ({ open, clear }) {
  const navigate = useNavigate()
  const [optionsOpen, setOptionsOpen] = useState(open ? 'flex' : 'none')

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation()
          if (optionsOpen !== 'none') {
            setOptionsOpen('none')
            clear()
          }
        }}
        style={{
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 999,
          position: 'fixed',
          cursor: 'default',
          backgroundColor: 'transparent'
        }}>
      </div>

      <NavBarOption $display={optionsOpen} onClick={(e) => e.stopPropagation()}>
        <ul>
          <li onClick={() => navigate('/packages')}>
            <MdModeEditOutline /> Embalagens
          </li>
          <li onClick={() => {
            deleteLocalStorage()
            navigate('/')
          }}>
            <RiDeleteBin7Fill /> Sair
          </li>
        </ul>
      </NavBarOption>
    </>
  )
}